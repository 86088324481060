import React, { useEffect, useState } from 'react';

const Version = () => {
  const [version, setVersion] = useState('');

  useEffect(() => {
    const fetchVersion = async () => {
      try {
        const response = await fetch('/version.txt');
        logger.log('Version:', response);

        if (response.ok) {
          const text = await response.text();
          setVersion(text);
        } else {
          console.error('Error fetching version:', response.statusText);
        }
      } catch (error) {
        console.error('Error fetching version:', error);
      }
    };

    fetchVersion();
  }, []);

  return (
    <>
     V {version}
    </>
  );
};

export default Version;
