import React, { useEffect, useState, useContext } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import { BsFillBasketFill } from "react-icons/bs";

import "../css/Global.css";
import { Tooltip } from "react-tooltip";


import { AuthContext } from "../../contexts/AuthProvider";
import customAxios from "../Utilities/getAxiosToken";
import { fetchEnsembleAgences } from "../Utilities/utilities";
import { useProjet } from '../../contexts/ProjetContext';


// Si AllAgences est à true alors retourne les synthèses globales
export function BandeauPreviEcheancier({ typeSynthese, AllAgences = false }) {
  const { userId, agenceId } = useContext(AuthContext);
  const [syntheseState, setSyntheses] = useState([]); 
  const { projetDataId } = useProjet();

  logger.log("BandeauPreviEcheancier typeSynthese", typeSynthese, userId);
  useEffect(() => {
    const fetchSyntheses = async () => {
      try {
        if (userId) {
          let typeSyntheseSelecteur;
          if (typeSynthese === "PRÉVISIONNELS") {
            typeSyntheseSelecteur = "Previsionnel";
          }
          if (typeSynthese === "ÉCHÉANCIERS") {
            typeSyntheseSelecteur = "Echeancier";
          }

          logger.log(
            "BandeauPreviEcheancier fetchSyntheses",
            AllAgences,
            typeSynthese
          );

          var response;
          if (AllAgences) {
            // on récupère toutes les informations de l'ensemble des Agences
            const data = await fetchEnsembleAgences(
              
              agenceId
            );
            const utilisateursData = data.TousLesUtilisateurs;

            // Créer un objet pour stocker les données agrégées
            const aggregatedData = {
              grandTotal: 0,
              nbProjets: 0,
              nbProjetsNonRenseignés: 0,
              panierMoyen: 0,
              variance: null,
              projets: [],
              projetsNonRenseignés: [],
            };

            // Parcourir les utilisateurs et récupérer les données pour chaque utilisateur
            for (const utilisateur of utilisateursData) {
              const url = `/api/projets/${typeSyntheseSelecteur}/byUser/${utilisateur.utilisateurId}`;
              logger.log("BandeauPreviEcheancier fetchSyntheses url", url);
              try {
                const response = await customAxios.get(url);
                const projets = response.data.projets;
                const projetsNonRenseignés = response.data.projetsNonRenseignés;
                // logger.log(
                //   "BandeauPreviEcheancier fetchSyntheses NB Projets pour ",
                //   utilisateur.nomPrenom,
                //   typeSyntheseSelecteur,
                //   projets.length
                // );
                logger.log(
                  "BandeauPreviEcheancier fetchSyntheses Projets ",
                  projets
                );
                logger.log(
                  "BandeauPreviEcheancier fetchSyntheses projetsNonRenseignés ",
                  projetsNonRenseignés
                );

                // Mettre à jour les données agrégées
                aggregatedData.nbProjets += projets.length || 0; 
                if (projetsNonRenseignés)
                aggregatedData.nbProjetsNonRenseignés += projetsNonRenseignés.length; // Handle undefined case

                for (const projet of projets) {
                  // const total = parseInt(projet.total.replace(/\s+/g, ""), 10);
                  const total = projet.total;
                  aggregatedData.grandTotal += total;
                  aggregatedData.projets.push({
                    _id: projet._id,
                    nom: projet.nom,
                    nomCreateurProjet: projet.nomCreateurProjet, // Nom du gestionnaire
                    prenomCreateurProjet: projet.prenomCreateurProjet, // Prénom du gestionnaire
                    total: projet.total,
                    date: projet.date,
                  });
                }

                if (projetsNonRenseignés)
                for (const projet of projetsNonRenseignés) {
                  // const total = parseInt(projet.total.replace(/\s+/g, ""), 10);

                  let total;
                  if (typeof projet.total === "string") {
                      total = parseInt(projet.total.replace(/\s+/g, ""), 10);
                  } else if (typeof projet.total === "number") {
                      total = projet.total;
                  } else {
                      // Gérer le cas où projet.total n'est ni une chaîne ni un nombre
                      console.error("projet.total est d'un type inattendu:", typeof projet.total);
                  }


                  aggregatedData.grandTotal += total;
   
                  aggregatedData.projetsNonRenseignés.push({
                    _id: projet._id,
                    nom: projet.nom,
                    nomCreateurProjet: projet.nomCreateurProjet, // Nom du gestionnaire
                    prenomCreateurProjet: projet.prenomCreateurProjet, // Prénom du gestionnaire
                    total: projet.total,
                    date: projet.date,
                  });

                }

              } catch (error) {
                console.error(
                  "fetchSyntheses Une erreur s'est produite lors de la récupération des données :",
                  error
                );
              }
            }

            // Calculer le panier moyen
            if (aggregatedData.nbProjets > 0) {
              //  aggregatedData.panierMoyen = (aggregatedData.grandTotal / aggregatedData.nbProjets).toFixed(2);
              aggregatedData.panierMoyen = (
                aggregatedData.grandTotal / aggregatedData.nbProjets
              ).toFixed(0);
            } else {
              aggregatedData.panierMoyen = "N/A";
            }
            logger.log(
              "BandeauPreviEcheancier fetchSyntheses Données agrégées :",
              aggregatedData
            );
            setSyntheses(aggregatedData);
          } else {
            // logger.log(
            //   "BandeauPreviEcheancier fetchSyntheses PAR utilisateur",
            //   userId,
            //   typeSyntheseSelecteur
            // );

            response = await customAxios.get(
              `/api/projets/${typeSyntheseSelecteur}/byUser/${userId}`
            );
            logger.log(
              "BandeauPreviEcheancier fetchSyntheses PAR utilisateur",
              typeSyntheseSelecteur,
              response.data
            );

            setSyntheses(response.data);
          }
        }
      } catch (error) {
        console.error(error);
      }
    };
    fetchSyntheses();

}, [projetDataId, typeSynthese, userId]);

  const nbProjets = syntheseState.nbProjets;
  const nbProjetsNonRenseignés = syntheseState.nbProjetsNonRenseignés;
  // logger.log("BandeauPreviEcheancier nbrecords ", typeSynthese, nbProjets, syntheseState);

  const projetsParMoisRenseignés = syntheseState.projets
    ? syntheseState.projets.reduce((acc, projet) => {
        const date = new Date(projet.date);
        const mois = date.toLocaleString("default", { month: "long" });
        const moisCapitalized = mois.charAt(0).toUpperCase() + mois.slice(1); // Transforme la première lettre en majuscule
        const annee = date.getFullYear();
        const key = `${moisCapitalized} ${annee}`;

        if (acc[key]) {
          acc[key].push(projet);
        } else {
          acc[key] = [projet];
        }
        // logger.log("projetsParMoisRenseignés", acc);
        return acc;
      }, {})
    : {};
 

  const projetsParMoisNonRenseignés = syntheseState.projetsNonRenseignés
  ? syntheseState.projetsNonRenseignés.reduce((acc, projet) => {
      const date = new Date(projet.date);
      const mois = date.toLocaleString("default", { month: "long" });
      const moisCapitalized = mois.charAt(0).toUpperCase() + mois.slice(1); // Transforme la première lettre en majuscule
      const annee = date.getFullYear();
      const key = `${moisCapitalized} ${annee}`;

      if (acc[key]) {
        acc[key].push(projet);
      } else {
        acc[key] = [projet];
      }
      // logger.log("projetsParMoisNonRenseignés", acc);
      return acc;
    }, {})
  : {};
  logger.log("BandeauPreviEcheancier projetsParMoisNonRenseignés",typeSynthese,  projetsParMoisNonRenseignés);
    
  const moisAnneesRenseignés = Object.keys(projetsParMoisRenseignés).sort((a, b) => {
    const dateA = new Date(projetsParMoisRenseignés[a][0].date);
    const dateB = new Date(projetsParMoisRenseignés[b][0].date);
    // logger.log("moisAnneesRenseignés",dateA,dateB);
    return dateA - dateB;
  });

  const moisAnneesNonRenseignés = Object.keys(projetsParMoisNonRenseignés).sort((a, b) => {
    const dateA = new Date(projetsParMoisNonRenseignés[a][0].date);
    const dateB = new Date(projetsParMoisNonRenseignés[b][0].date);
    // logger.log("moisAnneesRenseignés",dateA,dateB);
    return dateA - dateB;
  });

  return (
    <>

                {syntheseState.grandTotal !== undefined && (
                  <div>
                    {syntheseState.grandTotal.toLocaleString("fr-FR")} €
                  </div>
                )}
                {/* {syntheseState.panierMoyen !== undefined && (
                  <div>
                    <BsFillBasketFill className="couleur-icon small" />{" "}
                    {syntheseState.panierMoyen.toLocaleString("fr-FR")}€ <br />
                  </div>
                )} */}
    </>
  );
}
