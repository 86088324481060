import React, { useContext, useState, useEffect } from "react";
import "./../css/Global.css";

import { AuthContext } from "../../contexts/AuthProvider";
import customAxios from "../Utilities/getAxiosToken";
import { useProjet } from '../../contexts/ProjetContext';

//
// utilisateur attaché à un Projet 
//
export function UtilisateursByProjetId() {
  const { handleLogout } =
    useContext(AuthContext);

 
  const [gestionnaire, setGestionnaire] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const { projetData } = useProjet();



const fetchUtilisateursByProjetId = async () => {
  try {
    if (projetData) {
      const accesProjet = projetData.accesProjet;

      if (accesProjet && accesProjet.length > 0) {
        logger.log(
          "UtilisateursByProjetId fetchUtilisateursByProjetId accesProjet",
          accesProjet
        );

        const gestionnaires = [];

        for (const acces of accesProjet) {
          const gestionnaireId = acces.utilisateur;
          const response = await customAxios.get(
            `/api/utilisateurs/${gestionnaireId}?isActif=true`
          );
          logger.log(
            "UtilisateursByProjetId UtilisateurById fetchUtilisateurByid utilisateurId",
            gestionnaireId,
            "response.data",
            response.data
          );

          gestionnaires.push(response.data); // On récupère le nom de l'utilisateur et l'ajoute au tableau
        }

        setGestionnaire(gestionnaires); // On définit le tableau des créateurs
        setIsLoading(false);
      } else {
        setIsLoading(false);
      }
    }
  } catch (error) {
    if (error.response && error.response.status === 401) {
      logger.log(
        `UtilisateursByProjetId: erreur 401 pas d'accès Token expiré`
      );
      // Déconnexion de l'utilisateur et redirection vers la page de login
      handleLogout();
    } else {
      console.error(error);
    }
  }
};

  useEffect(() => {
    setIsLoading(true);
    // logger.log("UtilisateursByProjetId useEffect projetId", projetId);
    fetchUtilisateursByProjetId();
  }, [projetData]);

  return (
       <div className="cadre-zone4 contenu-zone4 center-content">


      {!isLoading &&
        gestionnaire.map((g, index) => (
          <div key={index} className="mr-2  ">
            {g.prenom} {g.nom}
          </div>
        ))}
    </div>
  );
}
