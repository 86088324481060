
import  customAxios  from './getAxiosToken';

export const useFetchAgences = () => {
  const fetchAgences = async () => {
    try {
      const typeTiersAgences = 'AGENCE';
      const typeTiersAdmins = 'ADMINISTRATEUR';

      const agencesResponse = await customAxios.get(`/api/tiers/allTypeTiers/${typeTiersAgences}`);
      const agences = agencesResponse.data.tiers;

      const adminsResponse = await customAxios.get(`/api/tiers/allTypeTiers/${typeTiersAdmins}`);
      const admins = adminsResponse.data.tiers;

      const combinedList = [...agences, ...admins];
      const uniqueTiers = Array.from(new Set(combinedList.map(tier => tier.nom)))
        .map(nom => combinedList.find(tier => nom === tier.nom));

      logger.log("fetchAgences uniqueTiers", uniqueTiers);
      return uniqueTiers;
    } catch (error) {
      logger.error("Erreur lors de la récupération des agences et administrateurs :", error);
      throw error;
    }
  };

  return {
    fetchAgences
  };
};
