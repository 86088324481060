
import customAxios from "../Utilities/getAxiosToken";

const useHandleFileClick = () => {
   

    const handleFileClick = async (fileId) => {
        const fileUrl = `/api/files/fichier/${fileId}`;

        try {
            const response = await customAxios.get(fileUrl, {
                responseType: 'blob'
            });

            const url = window.URL.createObjectURL(response.data);
            const a = document.createElement('a');
            a.href = url;
            a.download = "DownloadedFile";
            document.body.appendChild(a);
            a.click();

            window.URL.revokeObjectURL(url);
            document.body.removeChild(a);
        } catch (error) {
            console.error('Erreur lors de la récupération du fichier:', error);
            alert('Erreur lors du chargement du fichier.');
        }
    };

    return handleFileClick;
};

export default useHandleFileClick;
