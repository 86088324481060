import React, { useEffect, useState, useContext } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "./../css/Global.css";


import { AuthContext } from "../../contexts/AuthProvider";
import customAxios from "../Utilities/getAxiosToken";
import { Tooltip } from "react-tooltip";

// Liste toutes les Agences du site

export function ListeAdminAgences(props) {
  const { typeTiers } = props;
  const [ListeAdminAgences, setListeAdminAgences] = useState([]);
  const [selectedTiers, setSelectedTiers] = useState({});
 
  const { handleLogout, handleTiersId, handleUtilisateurId } =
    useContext(AuthContext);

  const handleLabelClick = (agenceId) => {
    setSelectedTiers({ [agenceId]: true });
    handleUtilisateurId(""); // On reset l'utilisateurId en cours
    handleTiersId(agenceId);
  };

  useEffect(() => {
    const fetchListeAdminAgences = async () => {
      try {
        logger.log("ListeAdminAgences fetchListeAdminAgences DEBUT typeTiers", typeTiers );
        // const typeTiers = "AGENCE";
        const response = await customAxios.get(
          `/api/tiers/typeTiers/${typeTiers}`
        );
        const agences = response.data.tousLesTiers;
        logger.log("ListeAdminAgences fetchListeAdminAgences", typeTiers, agences);
      
        setListeAdminAgences(agences);

        // S'il y a qu'un seul élément alors forcer le tiersid
        if (agences.length === 1){
          const agenceId = agences[0]._id;
          setSelectedTiers({ [agenceId]: true });
          handleUtilisateurId(""); // On reset l'utilisateurId en cours
          handleTiersId(agenceId);
          logger.log("ListeAdminAgences fetchListeAdminAgences", typeTiers, agenceId);
        } else {
          handleTiersId(null);
        }

      } catch (error) {
        if (error.response && error.response.status === 401) {
          logger.log(
            `ListeAdminAgences: erreur 401 - pas d'accès, token expiré.`
          );
          handleLogout();
        } else {
          console.error(error);
        }
      }
    };


    logger.log("ListeAdminAgences fetchListeAdminAgences typeTiers: ", typeTiers);
    fetchListeAdminAgences();

  }, [typeTiers]);

  const ListeAdminAgencesTriees = ListeAdminAgences.slice().sort((a, b) => {
    const nomA = a.enseigne.toUpperCase(); // Convertir en majuscules pour un tri insensible à la casse
    const nomB = b.enseigne.toUpperCase();

    if (nomA < nomB) {
      return -1;
    }
    if (nomA > nomB) {
      return 1;
    }
    return 0;
  });



  return (
    <div className="fenetre-liste-tiers">
      <h5 data-tooltip-id={`TooltipVueEnseigne`} data-tooltip-content={"Liste toutes Agences et les enseignes"}>
        Liste des Agences
      </h5>
      <Tooltip id="TooltipVueEnseigne" place="top" effect="solid" className="custom-tooltip"></Tooltip>
      <div>
        {ListeAdminAgencesTriees.length > 0 ? (
          <ul className="liste-sans-puces">
            {ListeAdminAgencesTriees.map((agence) => (
              <li key={agence._id} style={{ listStyleType: "none" }}>
                <div className="form-check d-flex ms-3">
                  <input
                    className="checked-item me-2"
                    type="checkbox"
                    value=""
                    id={`flexCheckDefault${agence._id}`}
                    checked={selectedTiers[agence._id] || false}
                    onChange={() => handleLabelClick(agence._id)}
                  />
                  <label
                    className={`souris-pointeur form-check-label ${selectedTiers[agence._id] ? "label-semi-gras" : ""}`}
                    htmlFor={`flexCheckDefault${agence._id}`}
                    onClick={() => handleLabelClick(agence._id)}
                    onMouseEnter={() => {
                      setSelectedTiers((prevSelectedTiers) => ({
                        ...prevSelectedTiers,
                        [agence._id]: true,
                      }));
                    }}
                    onMouseLeave={() => {
                      setSelectedTiers((prevSelectedTiers) => ({
                        ...prevSelectedTiers,
                        [agence._id]: false,
                      }));
                    }}
            
                  >
                    {agence.nom}
                  </label>
                </div>
              </li>
            ))}
          </ul>
        ) : (
          <p>Aucune Agence déclarée.</p>
        )}
      </div>
    </div>
  );

  
}
