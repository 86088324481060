import React, { useEffect, useState, useContext } from "react";
import Table from "react-bootstrap/Table";
import { Tooltip } from "react-tooltip";
import customAxios from "../Utilities/getAxiosToken";

import { AuthContext } from "../../contexts/AuthProvider";

// Composant pour afficher le tableau comparatif
// Permet de visualiser la ventilation Prévisionnel échéancier
export const TableauPrevisionnelEtEcheancier = () => {
  const { modeleId, handleLogout } = useContext(AuthContext);

  const [etapesModele, setEtapesModele] = useState([]);
  const [etapesPrevi, setEtapesPrevi] = useState([]);
  const [etapesEcheancier, setEtapesEcheancier] = useState([]);

  useEffect(() => {
    const fetchModeleById = async () => {
      try {
        // On récupère les étapes du modèle
        if (modeleId) {
          const response = await customAxios.get(`/api/modeles/${modeleId}`);

          logger.log("TableauPrevisionnelEtEcheancier response.data", response.data);
          setEtapesModele(response.data.etapes);
          logger.log("TableauPrevisionnelEtEcheancier Etapes du modèle", response.data.etapes);
          setEtapesPrevi(response.data.etapesPrevisionnel);
          logger.log(
            "TableauPrevisionnelEtEcheancier Etapes Prévisionnelles",
            response.data.etapesPrevisionnel
          );
          setEtapesEcheancier(response.data.etapesEcheancier);
          logger.log("TableauPrevisionnelEtEcheancier Etapes Échéancier", response.data.etapesEcheancier);
        }
      } catch (error) {
        if (error.response && error.response.status === 401) {
          // Déconnexion de l'utilisateur et redirection vers la page de login
          handleLogout();
        } else {
          console.error(error);
        }
      }
    };
    fetchModeleById();
  }, [modeleId]);

  // const isEtapeInArray = (etapeId, etapesArray) => {
  //   return etapesArray.includes(etapeId);
  // };

  const isEtapeInArray = (etapeOrdre, etapesArray) => {
    logger.log("TableauPrevisionnelEtEcheancier isEtapeInArray etapeOrdre", etapeOrdre, etapesArray);
    const normalizedArray = etapesArray.map((id) => Number(id)); // Assurez-vous que tout est un nombre
    return normalizedArray.includes(Number(etapeOrdre));
  };

  const CELL_STYLE = {
    textAlign: "center",
    color: "var(--texte-zone)",
  };

  const updateDatabase = async (
    updatedEtapesPrevi,
    updatedEtapesEcheancier
  ) => {
    try {
      updatedEtapesPrevi.sort((a, b) => a - b);
      updatedEtapesEcheancier.sort((a, b) => a - b);

      logger.log("TableauPrevisionnelEtEcheancier updateDatabase", updatedEtapesPrevi, updatedEtapesEcheancier);

      const response = await customAxios.patch(`/api/modeles/${modeleId}/update-previsionnel-echeancier`, {
        etapesPrevisionnel: updatedEtapesPrevi,
        etapesEcheancier: updatedEtapesEcheancier,
      });

      logger.log("TableauPrevisionnelEtEcheancier updateDatabase Base de données mise à jour avec succès", response.data);
    } catch (error) {
      console.error(
        "TableauPrevisionnelEtEcheancier Erreur lors de la mise à jour de la base de données :",
        error
      );
    }
  };

  const handleCheckboxChange = (etapeOrdre, type) => {
    let updatedEtapesPrevi = [...etapesPrevi];
    let updatedEtapesEcheancier = [...etapesEcheancier];

    if (type === "previ") {
      if (!isEtapeInArray(etapeOrdre, updatedEtapesPrevi)) {
        updatedEtapesPrevi.push(etapeOrdre);
      } else {
        updatedEtapesPrevi = updatedEtapesPrevi.filter(
          (ordre) => ordre !== etapeOrdre
        );
      }
      setEtapesPrevi(updatedEtapesPrevi);
    } else if (type === "echeancier") {
      if (!isEtapeInArray(etapeOrdre, updatedEtapesEcheancier)) {
        updatedEtapesEcheancier.push(etapeOrdre);
      } else {
        updatedEtapesEcheancier = updatedEtapesEcheancier.filter(
          (ordre) => ordre !== etapeOrdre
        );
      }
      setEtapesEcheancier(updatedEtapesEcheancier);
    }

    // logger.log("TableauPrevisionnelEtEcheancier handleCheckboxChange", updatedEtapesPrevi, updatedEtapesEcheancier);
    updateDatabase(updatedEtapesPrevi, updatedEtapesEcheancier);
  };

  return (
    <div>
      {/* <h6 
    data-tooltip-id={`infoPreviEcheancier`}
    data-tooltip-content={"Etapes Prévisionnelles ou Échéancier. Application immédiate à tous les projets"}
    data-tooltip-place="top"
  >Répartition Prévisionnel Échéancier</h6> */}
      <Tooltip
        id="infoPreviEcheancier"
        place="top"
        effect="solid"
        className="custom-tooltip"
      ></Tooltip>

      {/* En-tête des colonnes */}
      <ul className="cadre-zone4">
        <li className="table-zone3-header" style={{ listStyle: "none" }}>
          <div
            style={{
              display: "flex",
              justifyContent: "space-around",
              listStyle: "none",
            }}
          >
            <span>Étape</span> <span>Prévisionnel</span> <span>Échéancier</span>
          </div>
        </li>

        {etapesModele.map((etape, index) => (
          <li
            key={`${etape._id || "no-id"}-${index}`} // Utilisez une combinaison d'_id (ou une autre propriété unique) et de l'index
            style={{ listStyle: "none" }}
            className={`liste-sans-puces table-body ${
              index % 2 === 0 ? "bg-custom" : ""
            }`}
          >
            <div
              style={{
                display: "flex",
                justifyContent: "space-around",
                listStyle: "none",
              }}
            >
              <span style={CELL_STYLE}>{index + 1}</span>

              {/* Prévisionnel */}
              <span style={CELL_STYLE}>
                <input
                  type="checkbox"
                  checked={isEtapeInArray(index, etapesPrevi)}
                  onChange={() => handleCheckboxChange(index, "previ")}
                />
              </span>

              {/* Échéancier */}
              <span style={CELL_STYLE}>
                <input
                  type="checkbox"
                  checked={isEtapeInArray(index, etapesEcheancier)}
                  onChange={() =>
                    handleCheckboxChange(index, "echeancier")
                  }
                />
              </span>
            </div>
          </li>
        ))}
      </ul>
    </div>
  );
};
