import React, { useState, useContext, useEffect } from "react";
import { Form, FormControl, Button, Container, Card } from "react-bootstrap";
import axios from "axios";
import Cookies from "js-cookie";
import { AuthContext } from "../../contexts/AuthProvider";
import customAxios from "../Utilities/getAxiosToken";
import { FooterAccueil } from "../Footer/FooterAccueil";
import RequestForm from "../RequestForm/RequestForm";
import { CreateNewTiers } from "../Tiers/CreateNewTiers";
import "./css/login.css";
import { jwtDecode } from "jwt-decode";
import io from "socket.io-client";

import { BsPerson } from "react-icons/bs";

export function Login() {
  const [credentials, setCredentials] = useState({
    username: "",
    password: "",
  });
  const [isLoading, setIsLoading] = useState(false);
  const [showRequestForm, setShowRequestForm] = useState(false);
  const [showDetails, setShowDetails] = useState(false);
  const [showAvis, setShowAvis] = useState(false);
  const [showForgotPassword, setShowForgotPassword] = useState(false);
  const [showLoginForm, setShowLoginForm] = useState(false);
  const [resetEmail, setResetEmail] = useState("");
  const [showCreateNewTiers, setShowCreateNewTiers] = useState(false);

  const {
    handleLogin,
    handleSelectedMenu,
    isHttps,
    handleIsHttps,
  } = useContext(AuthContext);

  // Permet de s'assurer que les robots ne peuvent pas soumettre le formulaire
  const [honeypot, setHoneypot] = useState(""); // Champ caché pour les bots

  const toggleLoginForm = () => {
    setShowLoginForm((prev) => !prev);
    setShowRequestForm(false);
    setShowCreateNewTiers(false);
    setShowDetails(false);
    setShowAvis(false);
  };
  
  const toggleAvis = () => {
    setShowAvis((prev) => !prev);
    setShowLoginForm(false);
    setShowRequestForm(false);
    setShowCreateNewTiers(false);
    setShowDetails(false);
  };
  
  const toggleDetails = () => {
    setShowDetails((prev) => !prev);
    setShowLoginForm(false);
    setShowRequestForm(false);
    setShowCreateNewTiers(false);
    setShowAvis(false);
  };
  
  const handleRequestClick = () => {
    setShowRequestForm((prev) => !prev);
    setShowLoginForm(false);
    setShowCreateNewTiers(false);
    setShowDetails(false);
    setShowAvis(false);
  };
  
  const handleInscrireClick = () => {
    setShowCreateNewTiers((prev) => !prev);
    setShowLoginForm(false);
    setShowRequestForm(false);
    setShowDetails(false);
    setShowAvis(false);
  };

  useEffect(() => {
    // Initialisation du WebSocket
    const socket = io(process.env.REACT_APP_SOCKET_URL, {
      path: '/ws',
      auth: {
        token: Cookies.get("userTokenAcces"),
      },
    });

    // Événements WebSocket
    socket.on('connect', () => {
      logger.log('WebSocket connecté avec succès');
    });

    socket.on('connect_error', (error) => {
      logger.error('Erreur de connexion WebSocket :', error);
    });

    socket.on('disconnect', (reason) => {
      logger.warn('WebSocket déconnecté :', reason);
    });

    // Nettoyage lors du démontage
    return () => {
      logger.log('Fermeture du WebSocket');
      socket.disconnect();
    };
  }, []); // Tableau de dépendances vide pour n'exécuter qu'une seule fois au montage


  useEffect(() => {
    const statutHttps = process.env.HTTPS === "true";
    handleIsHttps(statutHttps);
    logger.log("Login HTTPS Status: ", statutHttps);
    logger.log("Login WebSocket URL:", process.env.REACT_APP_SOCKET_URL);
  }, [handleIsHttps]);

  

  const handleChange = (e) => {
    const { name, value } = e.target;
    setCredentials((prev) => ({ ...prev, [name]: value }));
  };

  const handleHoneypotChange = (e) => setHoneypot(e.target.value); // Gestion du champ caché


  const handleSubmit = async (e) => {
    e.preventDefault();

    logger.log("Login handleSubmit");

    if (honeypot) return; // Stop if honeypot is filled

    setIsLoading(true);
    try {

      const response = await axios.post(`/api/login`, credentials);
      const { userTokenAcces, expirationDate } = response.data;
      logger.log("Login User Token Acces, expirationDate :", userTokenAcces, expirationDate);


      try {
        const decoded = jwtDecode(userTokenAcces);
        logger.log("JWT décodé :", decoded);
      } catch (error) {
        logger.error("Erreur lors du décodage du JWT :", error);
        return; // Arrêtez l'exécution si le token est invalide
      }

      // Décoder le JWT pour extraire les informations utilisateur
      const { userId, userNom, userPrenom, userRole, userTypeUtilisateur } = jwtDecode(userTokenAcces);

      logger.log("Login User  userId, userNom, userPrenom, userRole userTypeUtilisateur :",  userId, userNom, userPrenom, userRole, userTypeUtilisateur );

      Cookies.set("userTokenAcces", userTokenAcces, { expires: new Date(expirationDate),ssecure: isHttps, sameSite: "strict"  });
      customAxios.defaults.headers.Authorization = `Bearer ${userTokenAcces}`;

      const tiersResponse = await customAxios.get(`/api/login/agence/${userId}`);
      const { agenceId } = tiersResponse.data;

      handleLogin(userId, userNom, userPrenom, userRole , agenceId, userTypeUtilisateur);

      setIsLoading(false);
    } catch (error) {
      logger.error("Erreur de connexion :", error);
      setIsLoading(false);
    }
  };


  const handleForgotPassword = () => {
    setShowForgotPassword(true);
  };

  const handlePasswordResetRequest = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post(`/api/password-reset`, {
        email: resetEmail,
      });
      alert(
        "Un lien de réinitialisation du mot de passe a été envoyé à votre adresse e-mail."
      );
    } catch (error) {
      logger.error("Erreur lors de la demande de réinitialisation", error);
      alert(
        "Erreur lors de la demande de réinitialisation. Veuillez réessayer."
      );
    }
  };

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        minHeight: "100vh",
        color: "var(--texte-fond)",
      }}
    >
      {showLoginForm && (
        <div className="form-inline mt-5">
        <Form
            onSubmit={(e) => {
              e.preventDefault();
              logger.log("Formulaire soumis !");
              handleSubmit(e);
            }}
            className="d-flex align-items-center"
          >
            <FormControl
              type="text"
              placeholder="Votre email"
              name="username"
              value={credentials.username}
              onChange={handleChange}
              autoComplete="username"
              aria-label="Login"
              className="mr-3"
            />
            <FormControl
              type="password"
              placeholder="Mot de passe"
              name="password"
              value={credentials.password}
              onChange={handleChange}
              autoComplete="current-password"
              aria-label="Mot de passe"
              className="mr-3"
            />
            {/* Champ caché honeypot pour détection des bots */}
            <input
              type="text"
              name="company"
              value={honeypot}
              onChange={handleHoneypotChange}
              style={{ display: "none" }}
              tabIndex="-1" // évite la navigation clavier
              autoComplete="off"
            />
            <Button
              variant="outline-success"
              type="submit"
              disabled={isLoading}
              className="w-100"
            >
              {isLoading ? "Loading..." : "Se connecter"}
            </Button>
          </Form>
        </div>
      )}

      {showLoginForm && (
        <div className="forgot-password">
          <a href="/forgot-password">Mot de passe oublié ?</a>
        </div>
      )}

      {showLoginForm && (
        <div className="inscription">
          <a href="/inscription">Inscription</a>
        </div>
      )}

      {showLoginForm && (
        <div className="form-inline">
          <hr
            // className="mt-1"
            style={{
              width: "40%",
              height: "3px",
              margin: "0 auto",
              backgroundColor: "var(--texte-fond)",
              border: "none",
            }}
          />
        </div>
      )}

      <h1
        className="text-center display-4 mt-5"
        style={{ fontWeight: "600", margin: "20px" }}
      >
        Bienvenue sur iAkka
      </h1>

      {showCreateNewTiers && (
        <div className="formulaire_parent-container">
          <div className="col-xl-4 col-lg-4 col-md-4 col-sm-4 formulaire_inscription">
            <CreateNewTiers typeTiers="AGENCE" />
          </div>
        </div>
      )}

      <Container
        className="text-center"
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column",
          marginBottom: "20px",
        }}
      >
        <p style={{ fontSize: "20px", padding: "10px 20px" }}>
         Découvrez iAkka, la solution ultime pour gagner du temps en simplifiant la gestion de vos processus métier.  <br />
          Accédez à un réseau de partenaires qualifiés et évalués, spécialisés dans votre secteur. <br />
         </p>

        

         <p style={{ fontSize: "18px", padding: "10px 20px" }}>
         Rejoignez-nous et transformez la gestion de vos projets dès aujourd’hui !
        </p>
        <Button
          variant="primary"
          size="lg"
          style={{
            fontSize: "15px",
            padding: "10px 30px",
            margin: "5px 16px",
            borderRadius: "12px",
            backgroundColor: "var(--bg-gris-fonce)",
          }}
          onClick={toggleDetails}
        >
          iAkka en savoir plus
        </Button>

        {showDetails && (
          <Card className="card-style-acceuil">
            <Card.Body>
              <Card.Text>
                <div
                  style={{
                    width: "100%",
                    margin: "0 auto",
                    padding: "20px",
                    borderRadius: "18px",
                    backgroundColor: "var(--bg-gris-tres-fonce)",
                  }}
                >
                  <h4>Fonctionnalités principales :</h4>
                  <p>
                    <strong>Gestion avancée des étapes :</strong> Organisez
                    chaque projet en étapes claires, avec des descriptions
                    détaillées, des délais personnalisés et des priorités
                    ajustables pour chaque tâche.
                  </p>
                  <p>
                    <strong>
                      Notifications automatiques et personnalisables :
                    </strong>{" "}
                    Tenez informées toutes les parties prenantes grâce à des
                    notifications automatiques personnalisables, assurant une
                    communication fluide et un suivi sans effort.
                  </p>
                  <p>
                    <strong>
                      Suivi en temps réel et tableaux de bord interisActifs :
                    </strong>{" "}
                    Visualisez l’avancement des projets en temps réel avec des
                    tableaux de bord interisActifs et des rapports détaillés,
                    permettant un ajustement rapide et une prise de décision
                    informée.
                  </p>
                  <p>
                    <strong>Labellisation des partenaires :</strong>{" "}
                    Assurez-vous de collaborer avec les meilleurs grâce à notre
                    système de labellisation des partenaires. Chaque partenaire
                    est évalué et noté selon des critères de qualité, de
                    fiabilité et de satisfaction client, vous permettant de
                    sélectionner des experts reconnus dans leur domaine et
                    adaptés à vos besoins spécifiques.
                  </p>
                </div>
              </Card.Text>
            </Card.Body>
          </Card>
        )}

        <hr
          className="my-5"
          style={{
            width: "40%",
            height: "3px",
            margin: "0 auto",
            backgroundColor: "var(--texte-fond)",
            border: "none",
          }}
        />

        <div className="d-flex flex-column flex-sm-row align-items-center">
          <Button
            variant="primary"
            size="lg"
            style={{
              fontSize: "15px",
              padding: "10px 30px",
              margin: "5px 16px",
              borderRadius: "12px",
            }}
            onClick={toggleLoginForm}
          >
            iAkka se connecter
          </Button>

          <Button
            variant="primary"
            size="lg"
            style={{
              fontSize: "15px",
              padding: "10px 30px",
              margin: "5px 16px",
              borderRadius: "12px",
            }}
            onClick={handleInscrireClick}
          >
            iAkka s'inscrire
          </Button>

          <Button
            variant="primary"
            size="lg"
            style={{
              fontSize: "15px",
              padding: "10px 30px",
              margin: "5px 16px",
              borderRadius: "12px",
            }}
          >
            iAkka essayer
          </Button>

          <Button
            variant="secondary"
            size="lg"
            style={{
              fontSize: "15px",
              padding: "10px 30px",
              margin: "5px 16px",
              borderRadius: "12px",
            }}
            onClick={handleRequestClick}
          >
            iAkka demander
          </Button>
        </div>

        {showRequestForm && (
          <div style={{ margin: "30px" }}>
            <RequestForm onSubmit={() => setShowRequestForm(false)} />
          </div>
        )}

        <hr
          className="my-5"
          style={{
            width: "40%",
            height: "3px",
            margin: "0 auto",
            backgroundColor: "var(--texte-fond)",
            border: "none",
          }}
        />

        <Button
          variant="primary"
          size="lg"
          style={{
            fontSize: "15px",
            padding: "10px 30px",
            margin: "5px 16px",
            borderRadius: "12px",
            backgroundColor: "var(--bg-gris-fonce)",
          }}
          onClick={toggleAvis}
        >
          iAkka lire
        </Button>

        {showAvis && (
          <div className="text-center" style={{ marginTop: "20px" }}>
            <Card
              className="card-style-acceuil"
              style={{ borderRadius: "18px" }}
            >
              <Card.Body
                style={{
                  backgroundColor: "var(--bg-gris-tres-fonce)",
                  borderRadius: "18px",
                }}
              >
                <Card.Text style={{ borderRadius: "18px", padding: "10px" }}>
                  "Maintenant, grâce à iAkka, chaque membre de l'équipe a son
                  propre assistant personnel digital, rendant tout
                  incroyablement organisé et accessible."
                </Card.Text>
                <footer className="blockquote-footer">
                  Thomas, <cite title="Source Title">partenaire étonné</cite>
                </footer>
              </Card.Body>
            </Card>

            <Card
              className="card-style-acceuil"
              style={{ marginTop: "10px", borderRadius: "18px" }}
            >
              <Card.Body
                style={{
                  backgroundColor: "var(--bg-gris-tres-fonce)",
                  borderRadius: "18px",
                }}
              >
                <Card.Text style={{ borderRadius: "18px", padding: "10px" }}>
                  "iAkka a transformé la façon dont nous gérons nos projets."
                </Card.Text>
                <footer className="blockquote-footer">
                  Marc, <cite title="Source Title">client satisfait</cite>
                </footer>
              </Card.Body>
            </Card>

            <Card
              className="card-style-acceuil"
              style={{ marginTop: "10px", borderRadius: "18px" }}
            >
              <Card.Body
                style={{
                  backgroundColor: "var(--bg-gris-tres-fonce)",
                  borderRadius: "18px",
                }}
              >
                <Card.Text style={{ borderRadius: "18px", padding: "10px" }}>
                  "L'efficacité qu'apporte iAkka à notre travail est comparable
                  à un orchestre symphonique bien accordé."
                </Card.Text>
                <footer className="blockquote-footer">
                  Anissa,{" "}
                  <cite title="Source Title">maître d'oeuvre rigoureuse</cite>
                </footer>
              </Card.Body>
            </Card>
            <Card
              className="card-style-acceuil"
              style={{ marginTop: "10px", borderRadius: "18px" }}
            >
              <Card.Body
                style={{
                  backgroundColor: "var(--bg-gris-tres-fonce)",
                  borderRadius: "18px",
                }}
              >
                <Card.Text style={{ borderRadius: "18px", padding: "10px" }}>
                  "Grâce au système de labellisation de iAkka, nous avons pu
                  sélectionner des partenaires de confiance, notés et reconnus
                  pour leur expertise. Cela nous permet d'avancer avec assurance
                  et sérénité dans chacun de nos projets."
                </Card.Text>
                <footer className="blockquote-footer">
                  Marc,{" "}
                  <cite title="Source Title">
                    directeur de projet satisfait
                  </cite>
                </footer>
              </Card.Body>
            </Card>
          </div>
        )}
      </Container>
      <FooterAccueil />
    </div>
  );
}
