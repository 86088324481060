import customAxios from "./getAxiosToken";

// import { fetchEnsembleAgences } from "./Utilities/utilities";
// on récupère toutes les informations de l'ensemble des Agences

// [Log] fetchEnsembleAgences data (bundle.js, line 24748)

// TiersAgences: Array (3)
// 0 {accesExtranet: {login: "user3.villenave", password: "Azerty2024", vue: "306|321"}, _id: "668035877b9d824048f1cae5", nom: "AQUITAINE METASERVICES", enseigne: "illiCO travaux - Villenave d'Ornon", siren: "508713641", …}
// 1 {tiers: [], enseigne: "", competences: [], disponibilites: [], experience: "", …}
// 2 {tiers: [], enseigne: "", competences: [], disponibilites: [], experience: "", …}

// Prototype Array

// TousLesUtilisateurs: Array (4)
// 0 {nomPrenom: "Stéphane LEENART", utilisateurId: "668035877b9d824048f1cae8", role: "Manager", enseigne: ["illiCO travaux - Villenave d'Ornon"]}
// 1 {nomPrenom: "Anissa CHEMMACHE", utilisateurId: "668035887b9d824048f1d39d", role: "Utilisateur", enseigne: ["illiCO travaux - Villenave d'Ornon"]}
// 2 {nomPrenom: "Rodolphe PIDERI", utilisateurId: "668035887b9d824048f1d3a6", role: "Utilisateur", enseigne: ["illiCO travaux - Villenave d'Ornon"]}
// 3 {nomPrenom: "Frédérik CORTET", utilisateurId: "668035887b9d824048f1d3b2", role: "Utilisateur", enseigne: ["illiCO travaux - Villenave d'Ornon"]}

// Prototype Array

// ToutesLesEnseignes: Array (3)
// 0 "illiCO travaux - Villenave d'Ornon"
// 1 ""
// 2 ""


async function fetchEnsembleAgences( agenceId) {
  try {
    if (agenceId == null) {
      logger.log("fetchEnsembleAgences : agenceId est null ou undefined");
      return [];
    }

    const url = `/api/utilisateurs/AllUtilisateurs/${agenceId}`;
    const response = await customAxios.get(url);

    logger.log("fetchEnsembleAgences data", response.data);
    return response.data;
  } catch (error) {
    console.error(error);
    return [];
  }
}

// Fonction utilitaire pour obtenir la liste de tiers
async function fetchTiersList(url) {
  try {
    const response = await customAxios.get(url);
    return response.data.tiersAttaches;
  } catch (error) {
    console.error(error);
    return [];
  }
}


export { fetchTiersList, fetchEnsembleAgences };
