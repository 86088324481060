import React, { useEffect, useState, useContext, useCallback } from "react";
import "../css/Compteurs.css";

import { AuthContext } from "../../contexts/AuthProvider";
import customAxios from "../Utilities/getAxiosToken";
import io from 'socket.io-client'; 
import Cookies from "js-cookie";


// etatProjet est un paramètre pour filtrer les projets ouvert ou archivé
export function CountProjetsByUserId({ etatProjet }) {
 
  const { userId, handleLogout } = useContext(AuthContext);
  const [nbProjets, setNbProjets] = useState(0); // Correct initial state to 0 since it's a number

  const fetchProjetsUser = useCallback(async () => {
    if (!userId) return;
    try {
      const response = await customAxios.get(`/api/projets/countByUserId/${userId}/${etatProjet}`);
      setNbProjets(response.data.nbProjets || 0);
    } catch (error) {
      if (error.response && error.response.status === 401) {
        handleLogout();
      } else {
        console.error("Erreur lors de la récupération des projets :", error);
        setNbProjets(0); // Set to 0 in case of error
      }
    }
  }, [userId, etatProjet]);

  useEffect(() => {
    
    fetchProjetsUser();

    logger.log("CountProjetsByUserId const socket = io()", process.env.REACT_APP_SOCKET_URL);
    const socket = io(process.env.REACT_APP_SOCKET_URL, {
      path: '/ws',
      auth: {
        token: Cookies.get("userTokenAcces")
      }
    });
    const handleNewUpdate = data => {
      logger.log("CountProjetsByUserId WebSockets - Nouveau Projet/Update", data);
      fetchProjetsUser();
    };

    socket.on('nouveauProjet', handleNewUpdate);
    socket.on('updateProjet', handleNewUpdate);

    return () => {
      logger.log("CountProjetsByUserId: Closing WebSocket");
      socket.off('nouveauProjet',handleNewUpdate );
      socket.off('updateProjet', handleNewUpdate);
      socket.disconnect();
  };
    
  }, [fetchProjetsUser]);

  return (
    <>{userId ? <span className="badge badge-info">{nbProjets}</span> : null}</>
  );
}
