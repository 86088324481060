import React, { useContext, useState, useEffect, useCallback } from "react";
import { useForm } from "react-hook-form";
import "./../css/Global.css";

import { AuthContext } from "../../contexts/AuthProvider";
import customAxios from "../Utilities/getAxiosToken";
import { fakerFR as faker } from "@faker-js/faker";

import { useFetchAgences } from "../Utilities/fetchAgences"; // Corrected here



export function CreateNewModele() {
  const { fetchAgences } = useFetchAgences();
  const { handleLogout, handleSelectedMenu } = useContext(AuthContext);
 
  const [listeModeles, setListeModeles] = useState(null);
  const [listeDesTiers, setListeDesTiers] = useState([]);
  const [isModified, setIsModified] = useState(false);

  const { register, handleSubmit, setValue, watch, formState: { errors } } = useForm();

  const remplirChampsAvecDonneesFictives = () => {
    const donneesFictives = {
      nom: faker.company.name().toUpperCase(),
      isDefault: false,
      isCheck: true,
      afficheDestinataires: true,
      description: faker.lorem.sentence(),
      cible: "CLIENT",
      notes: faker.lorem.paragraph(),
      etapesPrevisionnel: [],
      etapesEcheancier: [],
      agenceId: ""
    };

    Object.keys(donneesFictives).forEach((key) => {
      setValue(key, donneesFictives[key]);
    });

    setIsModified(true);
  };


  const fetchAgencesCallback = useCallback(async () => {
    try {
      const uniqueTiers = await fetchAgences();
      setListeDesTiers(uniqueTiers);
    } catch (error) {
      console.error("Erreur lors de la récupération des agences et administrateurs :", error);
    }
  }, []);


  const handleInputChange = (event) => {
    const { name, value } = event.target;

    // logger.log("CreateNewModele handleInputChange name", name, "value", value);
    // logger.log("CreateNewModele handleInputChange listeDesTiers", listeDesTiers);
    if (name === "nom") {
      setValue(name, value.toUpperCase());
    } else {
      setValue(name, value);
    }
    setIsModified(true);

    if (name === "agenceId") {
      const selectedAgence = listeDesTiers.find(agence => agence._id === value);
      logger.log("CreateNewModele handleInputChange agenceId", selectedAgence, listeDesTiers);
    
      if (selectedAgence) {
        setListeModeles(selectedAgence.listeModeles);
        logger.log("listeModeles updated:", selectedAgence.listeModeles);
      }
    }
  };

  useEffect(() => {
    fetchAgencesCallback();
  }, [fetchAgencesCallback]);

  const onSubmit = async (data) => {
    if (!data.agenceId) {
      alert("Veuillez sélectionner une agence avant de créer le modèle.");
      return;
    }

    try {
      data.nom = data.nom.toUpperCase();
      data.isActif = true;
      data.dateCreation = new Date();
      data.dateModification = new Date();
      // data.listeModeles = listeModeles; // Ajout de listeModeles au data

      logger.log("CreateNewModele data", data);

      const response = await customAxios.post(
        `/api/modeles/createAndAddModel/${data.agenceId}`,
        data
      );

      logger.log("CreateNewModele: Modèle créé avec succès !", response);
      handleSelectedMenu("ADMIN MODÈLES");

    } catch (error) {
      console.error("Erreur lors de la création du modèle :", error);
      if (error.response && error.response.status === 401) {
        logger.log("CreateNewModele: erreur 401 pas d'accès Token expiré");
        handleLogout();
      } else {
        console.error(error);
      }
    }
  };

  return (
    <div className="titre-zone2">
      <h4>CRÉATION D'UN NOUVEAU MODÈLE</h4>
      <form onSubmit={handleSubmit(onSubmit)}>
        <h5>{watch("nom")?.toUpperCase()}</h5>

        <div className="form-field">
          <label htmlFor="nom">Nom :</label>
          <input
            {...register("nom", { required: "Le nom est nécessaire" })}
            type="text"
            id="nom"
            name="nom"
            placeholder="Nom"
            onChange={handleInputChange}
            value={watch("nom")?.toUpperCase() || ""}
          />
          {errors.nom && <p className="errorMessage">{errors.nom.message}</p>}
        </div>

        <div className="form-field">
          <label htmlFor="description">Description :</label>
          <input
            {...register("description", { required: "La description est nécessaire" })}
            type="text"
            id="description"
            name="description"
            placeholder="Description"
            onChange={handleInputChange}
          />
          {errors.description && <p className="errorMessage">{errors.description.message}</p>}
        </div>

        <div className="form-field">
          <label htmlFor="cible">Cible :</label>
          <select
            {...register("cible", { required: "La cible est nécessaire" })}
            id="cible"
            name="cible"
            onChange={handleInputChange}
          >
            <option value="AGENCE">AGENCE</option>
            <option value="PRESTATAIRE">PRESTATAIRE</option>
            <option value="PARTENAIRE">PARTENAIRE</option>
            <option value="CLIENT">CLIENT</option>
          </select>
          {errors.cible && <p className="errorMessage">{errors.cible.message}</p>}
        </div>

        <div className="form-field">
          <label htmlFor="agenceId">Sélectionner une agence :</label>
          <select
            {...register("agenceId", { required: "L'agence rattachée est nécessaire" })}
            id="agenceId"
            name="agenceId"
            className="form-control"
            onChange={handleInputChange}
            value={watch("agenceId") || ""}
          >
            <option value="">Sélectionner une agence</option>
            {listeDesTiers.map((agence) => (
              <option key={agence._id} value={agence._id}>
                {agence.nom}
              </option>
            ))}
          </select>
          {errors.agenceId && <p className="errorMessage">{errors.agenceId.message}</p>}
        </div>

        <div className="form-field">
          <label htmlFor="notes">Notes :</label>
          <textarea
            {...register("notes")}
            id="notes"
            name="notes"
            placeholder="Notes"
            style={{ height: "80px" }}
            onChange={handleInputChange}
          />
          {errors.notes && <p className="errorMessage">{errors.notes.message}</p>}
        </div>

        <div className="form-field">
          <label htmlFor="isDefault">Est-ce le modèle par défaut ?</label>
          <input
            {...register("isDefault")}
            type="checkbox"
            id="isDefault"
            name="isDefault"
            onChange={(e) => setValue("isDefault", e.target.checked)}
          />
        </div>

        <div className="form-field">
          <label htmlFor="isCheck">Est-ce un modèle de vérification ?</label>
          <input
            {...register("isCheck")}
            type="checkbox"
            id="isCheck"
            name="isCheck"
            onChange={(e) => setValue("isCheck", e.target.checked)}
          />
        </div>

        <div className="form-field">
          <label htmlFor="afficheDestinataires">Afficher les destinataires ?</label>
          <input
            {...register("afficheDestinataires")}
            type="checkbox"
            id="afficheDestinataires"
            name="afficheDestinataires"
            onChange={(e) => setValue("afficheDestinataires", e.target.checked)}
          />
        </div>

        <div className="form-field">
          <label htmlFor="etapesPrevisionnel">Étapes prévisionnel :</label>
          <input
            {...register("etapesPrevisionnel")}
            type="text"
            id="etapesPrevisionnel"
            name="etapesPrevisionnel"
            placeholder="Étapes prévisionnel"
            onChange={handleInputChange}
          />
        </div>

        <div className="form-field">
          <label htmlFor="etapesEcheancier">Étapes échéancier :</label>
          <input
            {...register("etapesEcheancier")}
            type="text"
            id="etapesEcheancier"
            name="etapesEcheancier"
            placeholder="Étapes échéancier"
            onChange={handleInputChange}
          />
        </div>

        {isModified && (
          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
              margin: "10px 0",
            }}
          >
            <button
              className="btn"
              onMouseOver={(e) => (e.target.style.backgroundColor = "#0056b3")}
              onMouseOut={(e) => (e.target.style.backgroundColor = "#007bff")}
              type="submit"
            >
              Créer {watch("nom")?.toUpperCase()}
            </button>
          </div>
        )}

        <div>
          <button type="button" onClick={remplirChampsAvecDonneesFictives}>
            Remplir avec des données fictives
          </button>
        </div>
      </form>
    </div>
  );
}
