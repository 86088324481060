import React, { useState, useEffect,  } from "react";
import customAxios from "../Utilities/getAxiosToken";

export function CheckProxyStatus() {
  const [proxyStatus, setProxyStatus] = useState({ state: null });


  // const fetchProxyStatus = async () => {
  //   try {
  //     logger.log(`CheckProxyStatus get/proxy/alive`) 
  //     const response = await customAxios.get(`/proxy/alive`);
  //     setProxyStatus({ state: "alive" });
  //   } catch (error) {
  //     console.error("Erreur lors de la requête au proxy :", error);
  //     setProxyStatus({ state: "offline" });
  //   }
  // };

  const fetchProxyStatus = async () => {
    try {
      logger.log(`[INFO] Checking proxy status at /proxy/alive`);
      const response = await customAxios.get(`/proxy/alive`);
      logger.log(`[INFO] Proxy response:`, response.data);
      setProxyStatus({ state: "alive" });
    } catch (error) {
      console.error("[ERROR] Erreur lors de la requête au proxy :", error);
      if (error.response) {
        console.error(`[ERROR] Response Data:`, error.response.data);
        console.error(`[ERROR] Status Code:`, error.response.status);
      }
      setProxyStatus({ state: "offline" });
    }
  };

  useEffect(() => {
    fetchProxyStatus(); // Vérifie le statut du proxy au montage du composant

    const intervalId = setInterval(() => {
      fetchProxyStatus(); // Vérifie le statut du proxy toutes les 10 secondes
    }, 10000);

    return () => {
      clearInterval(intervalId); // Nettoyage de l'intervalle lors du démontage du composant
    };
  }, []);

  const getStatusColor = () => {
    if (proxyStatus.state === "alive") {
      return "green";
    } else {
      return "red";
    }
  };

  return (
    <div>
      <div
        style={{
          width: "12px",
          height: "12px",
          marginRight: "5px",
          borderRadius: "50%",
          backgroundColor: getStatusColor(),
        }}
        title={proxyStatus.state === "alive" ? "Proxy server is alive" : "Proxy server is offline"}
      />
    </div>
  );
}