import React, { useEffect, useState, useContext, useCallback } from "react";

import "bootstrap/dist/css/bootstrap.min.css";
import "./css/ListeTiers.css";
import "./../css/Global.css";

import { AuthContext } from "../../contexts/AuthProvider";
import customAxios from "../Utilities/getAxiosToken";
import io from 'socket.io-client'; 
import Cookies from "js-cookie";

// On récupère le nombre de  d'un tiers en fonction de typeTiers


export function CountTiersByTypeTiers({ typeTiers }) {
    const [listeTiers, setListeTiers] = useState([]);
   
    const { userId, tiersId } = useContext(AuthContext);
  
    const fetchListeTiers = useCallback(async () => {
      try {
        if (typeTiers && userId && tiersId) {
          const response = await customAxios.get(
            `/api/tiers/byTiersIdAndTypeTiers/${tiersId}/${typeTiers}`
          );
          setListeTiers(Array.isArray(response.data.tiersAttaches) ? response.data.tiersAttaches : []);
        }
      } catch (error) {
        console.error(error);
        // Ici, vous pouvez gérer l'erreur plus précisément, par exemple, en définissant un état d'erreur et en l'affichant.
      }
    }, [ userId, typeTiers, tiersId]);

    useEffect(() => {
    fetchListeTiers();

    logger.log("CountTiersByTypeTiers: const socket = io()",process.env.REACT_APP_SOCKET_URL);

    const socket = io(process.env.REACT_APP_SOCKET_URL, {
      path: '/ws',
      auth: {
        token: Cookies.get("userTokenAcces")
      }
    });
      // Écoute pour un événement spécifique, exemple: 'updateTiers'
      socket.on('updateTiers', (data) => {
        logger.log( "CountListeTiers: webSockets updateTiers", data);
        fetchListeTiers(); // Rafraîchit les données chaque fois que l'événement est reçu
      });

      return () => {
        logger.log("CountListeTiers: Closing WebSocket");
        socket.off('updateTiers', fetchListeTiers);
        socket.disconnect();
      };
      
    }, [ userId, typeTiers, tiersId]);
  
    const count = listeTiers.length; // Supposons que cette ligne est quelque part dans votre composant

    return (
          <>{userId ? <span className="badge badge-info">{count}</span> : null}</> // Afficher null plutôt que une chaîne vide pour le cas sans utilisateur
      
    );
    
  }
  
