import customAxios from "./getAxiosToken";
import useGetModeles from "./GetModeles";
import { useGetTiers } from "./GetTiers";

export const useCreateNewProjet = () => {
  const { fetchEtapesFromModeleId } = useGetModeles();
  const { getNameTiersByTiersId } = useGetTiers();

  const createNewProjet = async (
    modeleId,
    typeTiers,
    tiersId,
    userId,
    projets,
    descriptionProjet,
    accesProjet
  ) => {
    try {
      const nomTemp = await getNameTiersByTiersId(tiersId);
      logger.log(
        "createNewProjet : Nom temporaire du tiers:",
        nomTemp,
        modeleId
      );

      const proprieteProjetCommune = {
        createur: userId,
        accesProjet: accesProjet,
        etat: "notUsed",
        modele: modeleId,
        isValid: true,
        tiers: [tiersId],
        description: descriptionProjet,
        echeancier: [],
        previsionnel: []
      };

      let nouveauProjet, etapesTransformees;
      const projetExistant = projets?.find((projet) =>
        projet.tiers.includes(tiersId)
      );

      if (!projetExistant) {
        const nomProjet = nomTemp.toUpperCase();
        nouveauProjet = { nom: nomProjet, ...proprieteProjetCommune };
        logger.log(
          "createNewProjet : Création du projet projetExistant ? nouveauProjet",
          nouveauProjet
        );
      } else {
        logger.log(
          "createNewProjet : Création du projet projetExistant OUI",
          projetExistant
        );
        let trancheNum = 2;
        while (true) {
          const trancheNom = `${nomTemp} Tranche ${trancheNum}`;
          const existe = projets.some((projet) => projet.nom === trancheNom);
          if (!existe) {
            nouveauProjet = { nom: trancheNom, ...proprieteProjetCommune };
            break;
          }
          trancheNum++;
        }
      }

      if (nouveauProjet) {
        logger.log(
          "createNewProjet : Création du projet avant envoi:",
          nouveauProjet
        );
        const { data: projetCree } = await customAxios.post(
          `/api/projets`,
          nouveauProjet
        );
        logger.log("createNewProjet : Projet créé avec succès:", projetCree);

        // On récupère les étapes d'un modèle spécifique
        const etapes = await fetchEtapesFromModeleId(modeleId);
        logger.log(
          "createNewProjet : fetchEtapesFromModeleId: de ",
          modeleId,
          etapes
        );

        // Vérification si etapes est bien un tableau
        if (Array.isArray(etapes)) {

          etapesTransformees = etapes.map((idEtape) => ({
            projet: projetCree && projetCree._id ? projetCree._id : null, // Vérifie que projetCree et son _id existent bien
            modeleEtape: idEtape, // Utilise directement l'ID de l'étape
            etat: "enCours",
            actionDestinataires: typeTiers && typeTiers !== "CLIENT" && tiersId
              ? [{ tiers: tiersId, etat: "enAttente" }] // Crée un objet destinataire avec état si tiersId est défini
              : [], // Sinon, tableau vide
          }));
        

          // Utilisez etapesTransformees comme nécessaire
          logger.log("createNewProjet : etapesTransformees",etapesTransformees);
        } else {
          console.error("Erreur : etapes n'est pas un tableau", etapes);
        }

        // Création des étapes pour le projet
        const { data: reponseEtapes } = await customAxios.post(
          `/api/etapes/batch`,
          etapesTransformees
        );
        logger.log(
          "createNewProjet : Étapes ajoutées au projet:",
          reponseEtapes
        );

        return projetCree;
      }

      return null;
    } catch (error) {
      console.error(
        "createNewProjet : Erreur lors de la création du projet:",
        error
      );
      return null;
    }
  };

  return { createNewProjet };
};
