import React, { useEffect, useState, useContext } from "react";

import { AuthContext } from "../../contexts/AuthProvider";
import customAxios from "../Utilities/getAxiosToken";
import { Tooltip } from "react-tooltip";
import "./css/SyntheseGlobale.css";

import useGetModeles from "../Utilities/GetModeles";

import {
  getFontColor,
  getBackgroundClass,
    calculerDifferenceJours,
  } from "../Utilities/attentesEtapes";


export const SyntheseGlobale = ({ AgenceAuComplet }) => {
  const { fetchModeles }  = useGetModeles(); 
  const [projets, setProjets] = useState([]);
  const { userId, modeleId, agenceId, handleProjetId, handleSelectedMenu } =
    useContext(AuthContext);

 
  const [projetsParEtape, setProjetsEtape] = useState({});
  const [isToggled, setIsToggled] = useState(false); // 'false' means it's in "light" mode initially
  const [uniqueEtapeNums, setUniqueEtapeNums] = useState([]); // État pour stocker les numéros d'étape uniques

  const fetchAttentes = async () => {
    try {
      logger.log(
        "SyntheseGlobale: fetchProjets DEBUT AgenceAuComplet",
        AgenceAuComplet
      );

      const tousLesModelesDetailles = await fetchModeles(
        agenceId
      );
      logger.log(
        "SyntheseGlobale: fetchAttentes tousLesModelesDetailles 1",
        tousLesModelesDetailles
      );

      if (AgenceAuComplet) {
        // Si true, récupérer tous les projets de tous les modeleId de tousLesModelesDetailles
        logger.log(
          "SyntheseGlobale: fetchProjets tousLesModelesDetailles 2",
          tousLesModelesDetailles
        );
      
        const modeleIds = [modeleId];

        logger.log("SyntheseGlobale: fetchAttentes modeleIds 3", modeleIds);
        const payload = { modeleIds: modeleIds };
        
        logger.log("SyntheseGlobale: fetchAttentes modeleIds 31", payload);
        // Là on récupére tous les projets directement sur le serveur
        if (modeleIds && modeleIds.length > 0) {
          const url = `/api/projets/attentesByModeleIds`;
          try {
            // const response2 = await customAxios.post(url, modeleIds );
            const response2 = await customAxios.post(url, payload);

            logger.log(
              "SyntheseGlobale: fetchAttentes tousLesModelesDetailles 4",
              response2.data
            );

            setProjets(response2.data || []);
          } catch (error) {
            console.error("Erreur lors de la requête POST :", error);
            // Gérer l'erreur, afficher un message d'erreur à l'utilisateur, etc.
          }
        } else {
          console.error("modeleIds est vide ou non défini.");
          // Gérer le cas où modeleIds est vide ou non défini
        }
      } else {
      
        // Récupérer tous les projets de userId avec le modèle modeleId
        let response;

        if (modeleId !== null && modeleId !== undefined) {
          try {
            response = await customAxios.get(
              `/api/projets/attentesByUserIdAndModeleId/${userId}/${modeleId}`
            );
     
            logger.log("SyntheseGlobale: fetchAttentes récupérées:", response.data);
            setProjets(response.data || []);
          } catch (error) {
            console.error("SyntheseGlobale: fetchAttentes - Erreur lors de l'appel à l'API:", error.message);
            // Gérer l'erreur ici
            setProjets([]); // Assurez-vous que les projets sont réinitialisés ou traités correctement en cas d'erreur
          }
        } else {
          logger.log("SyntheseGlobale: fetchAttentes - Le modeleId est null ou indéfini, l'appel API n'est pas effectué.");
          // Traiter le cas où modeleId est null ou indéfini
          setProjets([]); // Vous pouvez choisir de réinitialiser les projets ici si c'est logique pour votre application
        }
        
      }
    } catch (error) {
      console.error("SyntheseGlobale: fetchAttentes - Erreur lors de la récupération des projets", error);
      if (error.response && error.response.status === 404) {
        setProjets([]);
      }
    }
  };

  useEffect(() => {
    fetchAttentes();
  }, [ modeleId]);

  // Organiser les projets par numéro d'étape
  const organiserProjetsParEtape = () => {
    const groupes = {};
    projets.forEach((attente) => {
      const ordreEtape = attente.ordreEtape;
      if (!groupes[ordreEtape]) {
        groupes[ordreEtape] = [];
      }
      groupes[ordreEtape].push(attente);
    });
    setProjetsEtape(groupes);
    logger.log("SyntheseGlobale: organiserProjetsParEtape - setProjetsEtape", projetsParEtape);
  };

  // Récupération de tous les numéros d'étapes uniques à partir des projets
  const getAllUniqueEtapeNums = () => {
    // Extraire 'ordreEtape' de chaque 'attente' dans le tableau 'projets'
    const allOrdreEtapes = projets.map((attente) => attente.ordreEtape);
    // logger.log("SyntheseGlobale: getAllUniqueEtapeNums allOrdreEtapes", allOrdreEtapes);
    // Utiliser 'Set' pour éliminer les doublons et retourner un tableau
    const uniqueOrdreEtapes = [...new Set(allOrdreEtapes)];

    // Retourner le tableau des 'ordreEtape' uniques
    // logger.log("SyntheseGlobale:  getAllUniqueEtapeNums", uniqueOrdreEtapes);
    setUniqueEtapeNums(uniqueOrdreEtapes); // Mettre à jour l'état avec les numéros d'étape uniques
    return uniqueOrdreEtapes;
  };

  // Rendu du composant
  useEffect(() => {
      logger.log("SyntheseGlobale: projets:", projets.length, projets);
      const uniqueEtapes = getAllUniqueEtapeNums();
      // setUniqueEtapeNums(uniqueEtapes); // Mettre à jour l'état avec les numéros d'étape uniques
      organiserProjetsParEtape();
  }, [projets]);




  // Version Light
  const renderProjetsParEtape = () => {
    // logger.log("SyntheseGlobale: renderProjetsParEtape La taille de etapeNumsTries est :", uniqueEtapeNums.length);
    // S'assurer que uniqueEtapeNums est défini et contient des valeurs avant de trier
    const etapeNumsTries = [...uniqueEtapeNums].sort((a, b) => a - b);

    // Créer un dictionnaire pour la première étape de chaque projet
    const premiereEtapeProjets = {};

    etapeNumsTries.forEach((ordreEtape) => {
      (projetsParEtape[ordreEtape] || []).forEach((projet) => {
        // Si c'est la première fois qu'on rencontre ce projet, ou si l'étape est inférieure à celle enregistrée
        if (
          !premiereEtapeProjets[projet.nomProjet] ||
          premiereEtapeProjets[projet.nomProjet].ordreEtape > ordreEtape
        ) {
          premiereEtapeProjets[projet.nomProjet] = {
            ...projet,
            ordreEtape: ordreEtape,
          };
        }
      });
    });

    // Transformer le dictionnaire en tableau pour le tri et le rendu
    const projetsPremiereEtape = Object.values(premiereEtapeProjets);
    projetsPremiereEtape.sort((a, b) => a.ordreEtape - b.ordreEtape);

    // Générer le JSX pour la première étape de chaque projet
    const etapesJSX = etapeNumsTries
      .map((ordreEtape, index) => {
        const etapeProjets = projetsPremiereEtape.filter(
          (projet) => projet.ordreEtape === ordreEtape
        );
        const rowClass = index % 2 === 0 ? "bg-custom" : "";

        if (etapeProjets.length > 0) {
          const projetsJSX = etapeProjets.map((projet) => {
            const joursRestants = calculerDifferenceJours(projet.echeanceEtape);
            const classeBackground = getBackgroundClass(joursRestants);
            const color = getFontColor(classeBackground);

            return (
              <span
                key={ projet.projetId}
               
                className={`badge ${classeBackground}`}
                style={{
                  color,
                  marginRight: "4px",
                  padding: "3px 8px",
                  cursor: "pointer",
                }}
                onClick={() => handleLabelClick(projet.projetId)}
              >
                {projet.nomProjet} ({joursRestants})
              </span>
            );
          });

          // Retourner la ligne de tableau pour ce groupe d'étape
          return (
            <tr key={ordreEtape} className={rowClass} 
            >
              <td
                  style={{
                    borderRadius: "10px 0 0 10px",
                    width: "10%",
                    padding: "3px 8px", // Réduire le padding ici selon votre préférence
                  }}
              >
                {ordreEtape}
              </td>
              <td 
                style={{
                  width: "30%",
                }}>
                {etapeProjets[0].descriptionEtape}
              </td>
              <td
                    style={{
                      borderRadius: "0px 10px 10px 0px",
                      width: "60%",
                      marginLeft: "4px",
                      padding: "8px", // Réduire le padding ici selon votre préférence
                    }}
              >
                {projetsJSX}
              </td>
            </tr>
          );
        }
        return null;
      })
      .filter(Boolean); // Filtrer les valeurs nulles


    return (
      <table className="table-DashBoard"      >
        <thead
          style={{
            color: "var(--texte-zone)",
            marginBottom: "10px",
          }}
        >
          <tr>
            <th
              style={{
                width: "20px",}}
            >
              ORDRE
            </th>
            <th
              style={{
                width: "250px",}}
            >
              DESCRIPTION
            </th>
            <th
              style={{
                borderTopRightRadius: "10px",
                display: "flex",
         
                justifyContent: "space-between",
                alignItems: "center", // Pour aligner verticalement les éléments si nécessaire
                padding: "0 10px", // Ajoutez un peu de padding si nécessaire
              }}
            >
              PROJETS
              <ToggleSwitch />
            </th>

          </tr>
        </thead>
        <tbody
        >
          {etapesJSX}
        </tbody>
      </table>
    );
  };



  // Version Full
  const renderProjetsToutesEtapes = () => {
    // Assurez-vous que uniqueEtapeNums est défini et contient des valeurs avant de trier
    const etapeNumsTries = [...uniqueEtapeNums].sort((a, b) => a - b);

    return (
      <table className="table-DashBoard" >
          <thead
        >
          <tr>
            <th
              style={{
                width: "20px"
              }}
            >
              ORDRE
            </th>
            <th
              style={{
                width: "250px"
              }}
            >
              DESCRIPTION
            </th>
            <th
              style={{
                borderTopRightRadius: "10px",
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center", // Pour aligner verticalement les éléments si nécessaire
                padding: "0 10px", // Ajoutez un peu de padding si nécessaire
              }}
            >
              PROJETS
              <ToggleSwitch />
            </th>

          </tr>
        </thead>
        <tbody>
          {etapeNumsTries.map((ordreEtape, index) => {
            const projetsDeCetteEtape = (
              projetsParEtape[ordreEtape] || []
            ).sort((a, b) => {
              const etapeA = a.etapes?.find(
                (etape) => etape.ordreEtape === ordreEtape
              );
              const etapeB = b.etapes?.find(
                (etape) => etape.ordreEtape === ordreEtape
              );
              return (etapeA?.ordre || 0) - (etapeB?.ordre || 0);
            });

            // Ici, vous pourriez supposer que tous les projets de cette étape ont la même description.
            // Donc, prenez la description du premier projet de la liste.
            const descriptionEtape = projetsDeCetteEtape[0]?.descriptionEtape;

            const projetsJSX = projetsDeCetteEtape.map((projet) => {
              const joursRestants = calculerDifferenceJours(
                projet.echeanceEtape
              );
              const classeBackground = getBackgroundClass(joursRestants);
              const color = getFontColor(classeBackground);

              return (
                <span
                  key={projet.projetId}
                  className={`badge ${classeBackground}`}
                  style={{
                    color,
                    marginRight: "4px",
                    padding: "3px 8px", // Réduire le padding ici selon votre préférence
                    cursor: "pointer",
                  }}
                  onClick={() => handleLabelClick(projet.projetId)}
                >
                  {projet.nomProjet} ({joursRestants})
                </span>
              );
            });

            const rowClass = index % 2 === 0 ? "bg-custom" : "";

            return (
              <tr key={ordreEtape} className={rowClass}>
                <td
                  style={{
                    borderRadius: "10px 0 0 10px",
                    width: "10%",
                    padding: "8px", // Réduire le padding ici selon votre préférence
                  }}
                >
                  {ordreEtape}
                </td>
                <td 
                    style={{
                      width: "30%",
                    }}>
                  {descriptionEtape}
                </td>
                <td
                  style={{
                    width: "60%",
                    marginLeft: "4px",
                    borderRadius: "0px 10px 10px 0px",
                    padding: "3px 8px", // Réduire le padding ici selon votre préférence
                  }}
                >
                  {projetsJSX}
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    );
  };

  const handleToggleChange = () => {
    setIsToggled(!isToggled);
  };



  const ToggleSwitch = () => {
  
    return (
      <label 
      className="toggle-switch"
          data-tooltip-id="switch-state"
          data-tooltip-content={isToggled 
            ? "Détaillée : Affiche toutes les étapes en attente" 
            : "Léger : uniquement la première étape en attente"}
      >
        <input
          type="checkbox"
          checked={isToggled}
          onChange={handleToggleChange}
        />
        <span className="switch-slider round">
          <span  className="switch-state">
            {isToggled ? "Détail" : "Résumé"}
          </span>
        </span>
        <Tooltip id="switch-state" place="top" effect="solid" className="custom-tooltip" />
      </label>
    );
  };


  const handleLabelClick = (projetId) => {
    logger.log("SyntheseGlobale: : handleLabelClick", projetId);
    handleProjetId(projetId);
    handleSelectedMenu("PROJETS CLIENTS"); // Pour simuler un reroutage
  };
  return (
    <div 
    className="cadre-zone3-dashboard"  
  >
      {/* Affichage du nom du modèle */}
      
      {isToggled ? (
        renderProjetsToutesEtapes()
      ) : uniqueEtapeNums.length > 0 ? (
        renderProjetsParEtape()
      ) : (
        <p>Aucun projet ou étape trouvé pour ce modèle.</p>
      )}
    </div>
  );
};
