
import React from "react";
import { FaCheckCircle, FaTimesCircle, FaInfoCircle } from "react-icons/fa";
import { Tooltip } from "react-tooltip";


// Composant pour l'en-tête du formulaire
export function FormHeader({ typeTiers }) {
  return (
    <h4>
      {typeTiers === "AGENCE" ? "Création d'une nouvelle Agence" : `Création nouveau ${typeTiers}`}
    </h4>
  );
}



// Composant réutilisable pour chaque champ de formulaire
export function FormField({ label, type, name, placeholder, register, errors, onChange, defaultValue = "" }) {
  return (
    <div className="form-field">
      <label htmlFor={name}>{label}</label>
      <input
        {...register(name, { required: `${label} est requis` })}
        type={type}
        id={name}
        name={name}
        placeholder={placeholder}
        defaultValue={defaultValue}
        onChange={onChange}
      />
      {errors[name] && <p className="errorMessage">{errors[name].message}</p>}
    </div>
  );
}

export function SelectField({ label, name, options, register, errors, onChange, defaultValue }) {
  return (
    <div className="form-field">
      <label htmlFor={name}>{label}</label>
      <select
        id={name}
        {...register(name, { required: true })}
        defaultValue={defaultValue} // Utilisation de la valeur par défaut
        onChange={onChange}
      >
        {options.map((option) => (
          <option key={option.value} value={option.value}>
            {option.label}
          </option>
        ))}
      </select>
      {errors[name] && <p className="errorMessage">{errors[name].message}</p>}
    </div>
  );
}



export function CpField({ register, errors, value, onChange }) {
  return (
    <div className="form-field">
      <label htmlFor="cp">CP</label>
      <div style={{ display: "flex", alignItems: "center" }}>
        <input
          {...register("cp", {
            required: "Le code postal est requis",
            pattern: {
              value: /^[0-9]+$/,
              message: "Le code postal doit être un nombre entier",
            },
          })}
          type="text"
          id="cp"
          name="cp"
          placeholder="CP"
          value={value}
          onChange={onChange}
          data-tooltip-id="tooltip-cp"
        />
        <FaInfoCircle
          style={{ color: "#007bff", marginLeft: "8px", cursor: "pointer" }}
          data-tooltip-id="tooltip-cp-info"
          data-tooltip-content="Le code postal doit comporter uniquement des chiffres."
        />
      </div>

      {/* Tooltip pour le champ CP */}
      <Tooltip
        id="tooltip-cp"
        place="top"
        effect="solid"
        className="custom-tooltip"
      >
        Le code postal doit comporter uniquement des chiffres et être valide.
      </Tooltip>

      {/* Tooltip pour l'icône d'information */}
      <Tooltip
        id="tooltip-cp-info"
        place="top"
        effect="solid"
        className="custom-tooltip"
      />

      {errors.cp && <p className="errorMessage">{errors.cp.message}</p>}
    </div>
  );
}

export function EnseigneField({ register, errors, onChange, onBlur }) {
  return (
    <div className="form-field">
      <label htmlFor="enseigne">Enseigne</label>
      {/* <div style={{ display: "flex", alignItems: "center" }}> */}
        <input
          {...register("enseigne", {
            required: "Enseigne est nécessaire",
          })}
          type="text"
          id="enseigne"
          name="enseigne"
          placeholder="Enseigne"
          onChange={onChange}
          onBlur={onBlur}
          data-tooltip-id="tooltip-enseigne"
        />
        <FaInfoCircle
          style={{ color: "#007bff", marginLeft: "8px", cursor: "pointer" }}
          data-tooltip-id="tooltip-enseigne-info"
          data-tooltip-content="Par défaut, l'enseigne est identique à la raison sociale, mais vous pouvez la modifier."
        />
      {/* </div> */}

      {/* Tooltip pour le champ Enseigne */}
      <Tooltip
        id="tooltip-enseigne"
        place="top"
        effect="solid"
        className="custom-tooltip"
      >
        L'enseigne par défaut est identique à la raison sociale mais peut être modifiée si nécessaire.
      </Tooltip>

      {/* Tooltip pour l'icône d'information */}
      <Tooltip
        id="tooltip-enseigne-info"
        place="top"
        effect="solid"
        className="custom-tooltip"
      />

      {errors.enseigne && <p className="errorMessage">{errors.enseigne.message}</p>}
    </div>
  );
}


export function IbanField({ register, errors, onChange }) {
  return (
    <div className="form-field">
      <label htmlFor="iban">IBAN</label>
      <div style={{ display: "flex", alignItems: "center" }}>
        <input
          {...register("iban", {
            required: false,
            pattern: {
              value: /^[0-9]+$/,
              message: "L'IBAN doit comporter uniquement des nombres.",
            },
          })}
          type="text"
          id="iban"
          name="iban"
          placeholder="IBAN"
          onChange={onChange}
          data-tooltip-id="tooltip-iban"
        />
        <FaInfoCircle
          style={{ color: "#007bff", marginLeft: "8px", cursor: "pointer" }}
          data-tooltip-id="tooltip-iban-info"
          data-tooltip-content="L'IBAN doit contenir uniquement des chiffres."
        />
      </div>

      {/* Tooltip pour le champ IBAN */}
      <Tooltip
        id="tooltip-iban"
        place="top"
        effect="solid"
        className="custom-tooltip"
      >
        L'IBAN doit être composé uniquement de chiffres sans espaces ni lettres.
      </Tooltip>

      {/* Tooltip pour l'icône d'information */}
      <Tooltip
        id="tooltip-iban-info"
        place="top"
        effect="solid"
        className="custom-tooltip"
      />

      {errors.iban && <p className="errorMessage">{errors.iban.message}</p>}
    </div>
  );
}


export function PasswordField({ register, errors, watch }) {
  const passwordsMatch = watch("password") === watch("confirmPassword");
  
  return (
    <>
      <div className="form-field">
        <label htmlFor="password">Mot de passe </label>
 
          <input
            {...register("password", {
              required: "Le mot de passe est requis",
              minLength: {
                value: 8,
                message: "Le mot de passe doit contenir au moins 8 caractères",
              },
            })}
            type="password"
            id="password"
            placeholder="Mot de passe"
            data-tooltip-id="tooltip-password"
          />
          {passwordsMatch && watch("password") && <FaCheckCircle style={{ color: "green", marginLeft: "5px" }} />}
          <FaInfoCircle
            style={{ color: "#007bff", marginLeft: "8px", cursor: "pointer" }}
            data-tooltip-id="tooltip-password-info"
            data-tooltip-content="Le mot de passe doit comporter au moins 8 caractères."
          />
    

        {/* Tooltip pour le mot de passe */}
        <Tooltip
          id="tooltip-password"
          place="top"
          effect="solid"
          className="custom-tooltip"
        >
          Choisissez un mot de passe de 8 caractères minimum.
        </Tooltip>

        {/* Tooltip pour l'icône d'information */}
        <Tooltip
          id="tooltip-password-info"
          place="top"
          effect="solid"
          className="custom-tooltip"
        />

        {errors.password && <p className="errorMessage">{errors.password.message}</p>}
      </div>

      <div className="form-field">
        <label htmlFor="confirmPassword">Confirmer le mot de passe </label>
          <input
            {...register("confirmPassword", {
              required: "Veuillez confirmer votre mot de passe",
              validate: (value) => value === watch("password") || "Les mots de passe ne correspondent pas",
            })}
            type="password"
            id="confirmPassword"
            placeholder="Confirmer le mot de passe"
            data-tooltip-id="tooltip-confirm-password"
          />
          {passwordsMatch && watch("confirmPassword") && <FaCheckCircle style={{ color: "green", marginLeft: "5px" }} />}
          {watch("confirmPassword") && !passwordsMatch && <FaTimesCircle style={{ color: "red", marginLeft: "5px" }} />}


        {/* Tooltip pour la confirmation du mot de passe */}
        <Tooltip
          id="tooltip-confirm-password"
          place="top"
          effect="solid"
          className="custom-tooltip"
        >
          Assurez-vous que les deux mots de passe correspondent.
        </Tooltip>

        {errors.confirmPassword && <p className="errorMessage">{errors.confirmPassword.message}</p>}
      </div>
    </>
  );
}

export function PhoneField({ register, errors, onChange }) {
  return (
    <div className="form-field">
      <label htmlFor="telephone">Téléphone </label>
      <div style={{ display: "flex", alignItems: "center" }}>
        <input
          {...register("telephone", {
            required: "Le téléphone est requis",
            pattern: {
              value: /(\+33\s?[1-9]{1}\d{8})|(0[1-9](?:[ _.-]?[0-9]{2}){4})/,
              message: "Format de téléphone invalide",
            },
          })}
          type="text"
          id="telephone"
          name="telephone"
          placeholder="Numéro de téléphone"
          onChange={onChange}
          data-tooltip-id="tooltip-telephone"
        />
        <FaInfoCircle
          style={{ color: "#007bff", marginLeft: "8px", cursor: "pointer" }}
          data-tooltip-id="tooltip-telephone-info"
          data-tooltip-content="Entrez un numéro de téléphone au format français (+33 ou 0 suivi de 9 chiffres)."
        />
      </div>

      {/* Tooltip pour l'input de téléphone */}
      <Tooltip
        id="tooltip-telephone"
        place="top"
        effect="solid"
        className="custom-tooltip"
      >
        Le numéro doit être au format +33 ou 0 suivi de 9 chiffres.
      </Tooltip>

      {/* Tooltip pour l'icône d'information */}
      <Tooltip
        id="tooltip-telephone-info"
        place="top"
        effect="solid"
        className="custom-tooltip"
      />

      {errors.telephone && <p className="errorMessage">{errors.telephone.message}</p>}
    </div>
  );
}



export function EmailField({ register, errors, onChange }) {
  return (
    <div className="form-field">
      <label htmlFor="email">Email </label>
        <input
          {...register("email", {
            required: "L'email est requis",
            pattern: {
              value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
              message: "Format d'email invalide",
            },
          })}
          type="email"
          id="email"
          name="email"
          placeholder="Email"
          onChange={onChange}
          data-tooltip-id="tooltip-email"
        />
        <FaInfoCircle
          style={{ color: "#007bff", marginLeft: "8px", cursor: "pointer" }}
          data-tooltip-id="tooltip-email-info"
          data-tooltip-content="Veuillez entrer une adresse email valide, par exemple : nom@exemple.com."
        />

      {/* Tooltip pour le champ email */}
      <Tooltip
        id="tooltip-email"
        place="top"
        effect="solid"
        className="custom-tooltip"
      >
        Assurez-vous d'entrer une adresse email valide.
      </Tooltip>

      {/* Tooltip pour l'icône d'information */}
      <Tooltip
        id="tooltip-email-info"
        place="top"
        effect="solid"
        className="custom-tooltip"
      />

      {errors.email && <p className="errorMessage">{errors.email.message}</p>}
    </div>
  );
}


export function SirenField({ register, errors, sirenValid, onChange, onBlur, sirenExists }) {
  return (
    <div className="form-field">
      <label htmlFor="siren">SIREN</label>
      <div style={{ display: "flex", alignItems: "center" }}>
        <input
          {...register("siren", {
            required: "Le SIREN est requis",
            pattern: {
              value: /^[0-9]{9}$/,
              message: "Le SIREN doit comporter 9 chiffres.",
            },
          })}
          type="text"
          id="siren"
          name="siren"
          placeholder="SIREN"
          onChange={onChange}
          onBlur={onBlur}
          data-tooltip-id="tooltip-siren"
        />
        {!sirenExists ? (
          sirenValid ? (
            <FaCheckCircle style={{ color: "green", marginLeft: "8px" }} />
          ) : null
        ) : (
          <FaTimesCircle style={{ color: "red", marginLeft: "8px" }} />
        )}


      </div>

      {/* Tooltip pour le champ SIREN */}
      <Tooltip
        id="tooltip-siren"
        place="top"
        effect="solid"
        className="custom-tooltip"
      >
        Entrez un SIREN de 9 chiffres.
      </Tooltip>

      {sirenExists && (
        <p className="errorMessage" style={{ color: "red" }}>
          Le SIREN existe déjà pour un autre tiers.
        </p>
      )}

      {errors.siren && <p className="errorMessage">{errors.siren.message}</p>}
    </div>
  );
}

// Composant pour le bouton de soumission
export function SubmitButton({ isModified, typeTiers, name }) {
  return (
    isModified && (
      <div style={{ display: "flex", justifyContent: "flex-end", margin: "10px 0" }}>
        <button className="btn">
          {typeTiers === "CLIENT" ? `Cliquer pour créer ${name}` : `Cliquer pour créer ${name}`}
        </button>
      </div>
    )
  );
}


