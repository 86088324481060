// ModalComponent.js
import React from 'react';
import { Modal, Button } from 'react-bootstrap';
import { FaCheckCircle } from "react-icons/fa"; // Une icône pour le titre

export function ModalCheckProjet({ show, handleClose, title, body }) {
  return (
    <Modal 
      show={show} 
      onHide={handleClose} 
      centered // Centrer le modal pour un effet plus élégant
      backdrop="static" // Empêche la fermeture en cliquant en dehors, plus moderne et utilisateur friendly
      style={{ borderRadius: "12px", overflow: "hidden" }} // Coins arrondis
    >
      <Modal.Header closeButton style={{ borderBottom: "none", padding: "1.5rem" }}>
        <Modal.Title style={{ display: "flex", alignItems: "center", fontWeight: "bold", fontSize: "1.25rem" }}>
          <FaCheckCircle color="#28a745" style={{ marginRight: "8px" }} /> {title}
        </Modal.Title>
      </Modal.Header>

      <Modal.Body style={{ padding: "1.5rem", fontSize: "1rem", lineHeight: "1.5", color: "#6c757d" }}>
        {body}
      </Modal.Body>

      <Modal.Footer style={{ borderTop: "none", padding: "1.5rem" }}>
        <Button 
          variant="outline-primary" 
          onClick={handleClose} 
          style={{ borderRadius: "20px", padding: "0.5rem 1.5rem" }}
        >
          Fermer
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
