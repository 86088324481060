import React, { useContext, useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import bcrypt from "bcryptjs";
import "./../css/Global.css";

import { AuthContext } from "../../contexts/AuthProvider";
import customAxios from "../Utilities/getAxiosToken";
import {  fakerFR as faker } from "@faker-js/faker";
import { useProjet } from "../../contexts/ProjetContext";
import { fetchEnsembleAgences } from "../Utilities/utilities";


// Si Création d'un tiers de type AGENCE alors saisir les informations du gérant pour obtenir un Token
// Si Création d'un tiers de type PARTENAIRE alors saisir le SIREN pour vérifier s'il existe déjà dans la base de données
// Si Création d'un tiers de type PRESTATAIRE alors saisir le SIREN pour vérifier s'il existe déjà dans la base de données
// Si Création d'un tiers de type CLIENT alors saisir le mail et le nom pour vérifier s'il existe déjà dans la base de données



// On récupère le tiers par tiers ID du contexte et les utilisateurs attachés
// Par défaut l'agence auquel appartient celui qui se connecte.
//
import { useCreateNewProjet } from "../Utilities/GetProjets";
import useGetModeles from "../Utilities/GetModeles";
import { fetchCompanyInfoBySiren } from "../Utilities/fetchCompanyInfo";
import { useFetchCategories } from "../Utilities/fetchCategories";
import {
  FormHeader,
  FormField,
  SelectField,
  SirenField,
  PhoneField,
  PasswordField,
  EmailField,
  IbanField,
  EnseigneField,
  CpField,
  SubmitButton,
} from "./CreateNewTiersComponents"; // Import des composants

export function CreateNewTiers(props) {
  logger.log("CreateNewTiers props", props);

  const { fetchModeles, fetchModelesAdmin , dupliquerModeles} = useGetModeles();
  const { createNewProjet } = useCreateNewProjet();
  const { typeTiers } = props;
  const {
    userId,
    handleLogout,
    handleTiersId,
    handleUtilisateurId,
    agenceId,
    handleSelectedMenu,
  } = useContext(AuthContext);

  const [isModified, setIsModified] = useState(false);
  const [toutesLesEnseignes, setToutesLesEnseignes] = useState([]);
  const [listeModelesDétaillés, setListeModelesDétaillés] = useState(null);
  const [selectedCategorie, setSelectedCategorie] = useState("");
  const [agenceHoteData, setAgenceHoteData] = useState(null);
  const [modeleParDefaut, setModeleParDefaut] = useState(null);
  const [companyInfo, setCompanyInfo] = useState(null);
  const [error, setError] = useState(null);
  const [sirenValid, setSirenValid] = useState(null);
  const [sirenExists, setSirenExists] = useState(false); // État pour gérer si le SIREN existe déjà
  const { refreshProjects } = useProjet();

  const {
    categories,
    error: fetchCategoriesError,
    refetch,
  } = useFetchCategories();

  const {
    register,
    handleSubmit,
    setValue,
    watch,
    reset, // Ajout de reset pour réinitialiser le formulaire
    formState: { errors },
  } = useForm();

  const [tiers, setTiers] = useState({});

  const remplirChampsAvecDonneesFictives = () => {
    const typesProjets = [
      "Extension",
      "Rénovation totale",
      "Rénovation partielle",
      "Surélévation",
    ];

    const indexAleatoire = Math.floor(Math.random() * typesProjets.length);

    const donneesFictives = {
      raisonSociale: faker.company.name().toUpperCase(),
      nom: faker.person.lastName().toUpperCase(),
      prenom: faker.person.firstName(),
      enseigne: "Enseigne-" + faker.company.name(),
      cp: faker.number.bigInt({ min: 10000, max: 65535 }),
      ville: faker.location.city().toUpperCase(),
      adresse: faker.location.streetAddress(),
      iban: faker.finance.accountNumber(12),
      observations: "From Faker Generator",
      email: faker.internet.email(),
      telephone: faker.phone.number(),
      descriptionProjet: typesProjets[indexAleatoire],
    };

    Object.keys(donneesFictives).forEach((key) => {
      setValue(key, donneesFictives[key]);
    });

    setIsModified(true);
  };


  // Permet de s'assurer que les robots ne peuvent pas soumettre le formulaire
  const [honeypot, setHoneypot] = useState("");
  const handleHoneypotChange = (e) => {
    setHoneypot(e.target.value);
  };



  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setValue(name, value);
    setIsModified(true);
  };

  const handleNomChange = (event) => {
    const { name, value } = event.target;
    const upperCaseValue = value.toUpperCase();
    setValue(name, upperCaseValue);
    setIsModified(true);
  };

  const handleEnseigneBlur = () => {
    const enseigneValue = watch("enseigne");
    const raisonSocialeValue = watch("raisonSociale");

    if (!enseigneValue) {
      setValue("enseigne", raisonSocialeValue);
    }
  };

  const chargerTousLesModeles = async () => {
    logger.log("CreateNewTiers: chargerTousLesModeles ", typeTiers, userId, agenceId);
    if (userId && agenceId) {
      try {

        // logger.log("CreateNewTiers: chargerTousLesModeles fetchModeles agenceId",  agenceId);

        const tousLesModeles = await fetchModeles(agenceId);
        logger.log("CreateNewTiers: chargerTousLesModeles fetchModeles ", tousLesModeles);
        const modelesFiltres = tousLesModeles.filter(
          (modele) => modele.cible === typeTiers
        );

        setListeModelesDétaillés(modelesFiltres);
        logger.log("CreateNewTiers: chargerTousLesModeles listeModelesDétaillés", modelesFiltres);

        const modeleParDefaut =
          modelesFiltres.find((modele) => modele.isDefault) ||
          modelesFiltres[0];
        setModeleParDefaut(modeleParDefaut);
      } catch (erreur) {
        console.error(
          "CreateNewTiers Erreur lors de la récupération des modèles :",
          erreur
        );
      }
    }
  };

  const fetchEnseignesAgences = async () => {
    try {
      const data = await fetchEnsembleAgences(agenceId);

      logger.log("CreateNewTiers: fetchEnseignesAgences", typeTiers, data);

      // Résultat de  fetchEnseignesAgences

      // {nomPrenom: "Stéphane LEENART", utilisateurId: "671c8412482dfe2b5db536a4", role: "Manager", enseigne: ["illiCO travaux - Villenave d'Ornon"]}
      // {nomPrenom: "Anissa CHEMMACHE", utilisateurId: "671c8412482dfe2b5db536ad", role: "Utilisateur", enseigne: ["illiCO travaux - Villenave d'Ornon"]}
      // {nomPrenom: "Mehyssa BIZIOU", utilisateurId: "671c8412482dfe2b5db536b4", role: "Utilisateur", enseigne: ["illiCO travaux - Villenave d'Ornon"]}
      // {nomPrenom: "Rodolphe PIDERI", utilisateurId: "671c8412482dfe2b5db536bd", role: "Utilisateur", enseigne: ["illiCO travaux - Villenave d'Ornon"]}
      // {nomPrenom: "Frédérik CORTET", utilisateurId: "671c8412482dfe2b5db536c9", role: "Utilisateur", enseigne: ["illiCO travaux - Villenave d'Ornon"]}
      // {nomPrenom: "Louis MANDRET", utilisateurId: "671c8412482dfe2b5db536d5", role: "Utilisateur", enseigne: ["illiCO travaux - Villenave d'Ornon"]}



      const TiersAgences = data.TiersAgences;
      const agencesAvecEnseigne = TiersAgences.filter(
        (agence) => agence.enseigne !== "" && agence.typeTiers === "AGENCE"
      );

      let agenceHoteData;

      const directAgence = data.TiersAgences.find(
        (agence) =>
          agence.utilisateurs.includes(userId) && agence.typeTiers === "AGENCE"
      );
      const prestataire = data.TiersAgences.find(
        (agence) =>
          agence.utilisateurs.includes(userId) &&
          agence.typeTiers === "PRESTATAIRE"
      );

      if (directAgence) {
        agenceHoteData = {
          Id: directAgence._id,
          nom: directAgence.nom,
          nomEnseigne: directAgence.enseigne,
        };
      } else if (prestataire) {
        const agenceRattachement = agencesAvecEnseigne.find((agence) =>
          agence.tiers.includes(prestataire._id)
        );

        if (agenceRattachement) {
          agenceHoteData = {
            Id: agenceRattachement._id,
            nom: agenceRattachement.nom,
            nomEnseigne: agenceRattachement.enseigne,
          };
        }
      }

      setAgenceHoteData({
        Id: agenceHoteData?.Id,
        nomEnseigne: agenceHoteData?.nomEnseigne,
      });

      return agencesAvecEnseigne;
    } catch (err) {
      console.error(err);
    }
  };

  const fetchData = async () => {
    try {
      const toutesLesEnseignesAgences = await fetchEnseignesAgences();
      setToutesLesEnseignes(toutesLesEnseignesAgences);

      chargerTousLesModeles();

      if (typeTiers === "AGENCE") {
        await refetch(); //fetchCategories();
      }
    } catch (error) {
      console.error(
        "Une erreur s'est produite lors de la récupération des enseignes :",
        error
      );
    }
  };

  useEffect(() => {
    if (agenceHoteData) {
      setValue("attachementAgenceId", agenceHoteData.Id);
      setValue("nomEnseigne", agenceHoteData.nomEnseigne);
    }

    if (modeleParDefaut) {
      setValue("modeleId", modeleParDefaut._id);
      // setValue("nomModele", modeleParDefaut.nom); // On ne veut pas afficher le nom du modèle
    }
  }, [agenceHoteData, modeleParDefaut, typeTiers,setValue]);

  useEffect(() => {
      // Réinitialise tous les champs du formulaire lorsqu'on change de type de tiers
    reset();
    handleTiersId("");
    handleUtilisateurId("");
    fetchData();
  }, [typeTiers]);

  const fetchCompanyInfoBySirenHandler = async (siren) => {
    logger.log("CreateNewTiers fetchCompanyInfoBySirenHandler", siren);
    await fetchCompanyInfoBySiren(
      siren,
      setSirenValid,
      setError,
      setCompanyInfo,
      setValue
    );
  };

  useEffect(() => {
    const siren = watch("siren");
    if (siren) {
      if (siren.length === 9 && !isNaN(siren)) {
        fetchCompanyInfoBySirenHandler(siren);
      } else {
        setValue("raisonSociale", "");
        setValue("adresse", "");
        setValue("cp", "");
        setValue("ville", "");
        setValue("codeNaf", "");
        setValue("libelleNaf", "");
        setValue("trancheEffectifs", "");
        setValue("categorieJuridique", "");
        setValue("formeJuridique", "");
        setCompanyInfo(null);
        if (siren.length > 0 && siren.length !== 9) {
          setError("Le numéro de SIREN doit contenir 9 chiffres.");
          setSirenValid(false);
        } else {
          setError(null);
          setSirenValid(null);
        }
      }
    }
  }, [watch("siren")]);

  useEffect(() => {
    if (sirenValid && watch("raisonSociale")) {
      setValue("enseigne", watch("raisonSociale"));
    }
  }, [sirenValid, watch("raisonSociale")]);


  useEffect(() => {
    const siren = watch("siren");
  
    // Définir une fonction asynchrone pour gérer la logique de vérification
    const validateSiren = async () => {
      if (siren && siren.length === 9 && !isNaN(siren)) {
        const exists = await checkIfSirenExists(siren);
        setSirenExists(exists);
        setSirenValid(!exists);
        setError(exists ? "Le SIREN existe déjà pour un autre tiers." : null);
      } else {
        setSirenExists(false);
        setSirenValid(false);
      }
    };
  
    // Appeler la fonction de validation
    validateSiren();
  
    // Ajouter `siren` comme dépendance pour réexécuter l'effet chaque fois qu'il change
  }, [watch("siren")]);

  const checkIfSirenExists = async (siren) => {
    try {
      logger.log("CreateNewTiers: checkIfSirenExists", siren);
      const response = await customAxios.get(`/api/tiers/siren/${siren}`);
      // Si un tiers est trouvé avec ce SIREN, cela signifie qu'il existe déjà
      logger.log("CreateNewTiers: checkIfSirenExists", siren);
      return response.data.exists;
    } catch (error) {
      console.error("Erreur lors de la vérification du SIREN", error);
      return false; // En cas d'erreur, on considère qu'il n'existe pas déjà
    }
  };

  useEffect(() => {
    const siren = watch("siren");
    if (siren && siren.length === 9 && !isNaN(siren)) {
      checkIfSirenExists(siren).then((exists) => {
        setSirenExists(exists); // Met à jour l'état si le SIREN existe déjà
        setError(exists ? "Le SIREN existe déjà pour un autre tiers." : null);
      });
    } else {
      setSirenExists(false);
    }
  }, [watch("siren")]);

  const onSubmit = async (data) => {
    try {

      // Toujours pour des raisons de sécurité, on vérifie si le champ honeypot est vide
      if (honeypot) {
        logger.log("Bot detected - form submission blocked");
        return;
      }
      logger.log("CreateNewTiers: onSubmit typeTiers", typeTiers);

    // Vérification si le type de tiers est une agence ou un partenaire
    if (typeTiers === "AGENCE" || typeTiers === "PARTENAIRE") {
      try {
        if (sirenExists) {
          setError("siren", {
            type: "manual",
            message: "Le SIREN existe déjà dans iAkka.",
          });
          return;
        }
  
        // Suite de la logique d'envoi du formulaire...
      } catch (error) {
        console.error("Erreur lors de la création du tiers", error);
      }
    }

      let idsUtilisateurs = [],
        nomDeFamille = data.nom;

      data.cp = data.cp.toString();
      data.siren = data.siren.toString();
      data.typeTiers = typeTiers;
      if (typeTiers === "CLIENT") {
        data.isActif = true;
      } else {
        data.isActif = false;
      }
      data.siteWeb = "";
      data.createur = userId;
      data.autonome = true;
      data.password = await bcrypt.hash(data.password, 10);

      if (typeTiers === "PARTENAIRE" ) {
        logger.log("CreateNewTiers: onSubmit création de data.accesTiers", typeTiers);

        const agenceId = data.attachementAgenceId;
        const dataUtilisateurs = await fetchEnsembleAgences(agenceId);
        const listeUtilisateursData = dataUtilisateurs.TousLesUtilisateurs;

        idsUtilisateurs = listeUtilisateursData.map(
          (utilisateur) => utilisateur.utilisateurId
        );

        data.accesTiers = idsUtilisateurs.map((utilisateurId) => ({
          utilisateur: utilisateurId, // Utilisateur à partir de l'id
          etat: "ouvert", // État par défaut
          dateArchivage: null, // Date d'archivage nulle par défaut
        }));


        delete data.accesExtranet;
        delete data.gestionnaire;
        delete data.modeleId;
      }
     

      if (typeTiers === "PARTENAIRE" || typeTiers === "PRESTATAIRE" || typeTiers === "FOURNISSEUR") {
        data.clientParticulier = false;
        data.nom = data.raisonSociale;
        data.descriptionProjet = "Documents Administratifs";
      }
      if (typeTiers === "AGENCE") {
        data.nom = data.raisonSociale;
        const tousLesModeles = await fetchModelesAdmin();
        logger.log("CreateNewTiers: selectedCategorie", selectedCategorie);
        logger.log("CreateNewTiers: tousLesModeles", tousLesModeles);
        const modelesAssocies = tousLesModeles.filter(
          (modele) =>
            modele.categorie && modele.categorie.includes(selectedCategorie)
        );
        logger.log("CreateNewTiers: modelesAssocies", modelesAssocies);

        // On duplique les modèles associés
        const nouveauxModeles = await dupliquerModeles(modelesAssocies);
        data.listeModeles = nouveauxModeles.map(({ modeleId, newId }) => ({
          modeleId: newId,
        }));

        logger.log("CreateNewTiers: data.listeModeles", data.listeModeles);
      }

      if (data.clientParticulier === true) {
        delete data.siren;
        delete data.raisonSociale;
        delete data.clientParticulier;
        delete data.accesExtranet;
      }

      if (typeTiers !== "PRESTATAIRE" && typeTiers !== "AGENCE") {
        delete data.enseigne;
      }
  
      delete data.attachementAgenceId;
      delete data.nomEnseigne;

      const nouveauUtilisateur = {
        civilite: data.civilite,
        nom: nomDeFamille,
        prenom: data.prenom,
        titre: null,
        adresse: data.adresse,
        login: data.email,
        email: data.email,
        // password: await bcrypt.hash("azerty12", 10),
        password: await bcrypt.hash(data.password, 10),
        telephone: data.telephone,
        cp: data.cp.toString(),
        ville: data.ville,
        createur: userId,
        role: "Manager",
        typeUtilisateur: typeTiers,
        dateNaissance: null,
        observations: null,
        isActif: true,
      };

      const responseUtilisateur = await customAxios.post(
        `/api/utilisateurs/`,
        nouveauUtilisateur
      );

      if (responseUtilisateur.data && responseUtilisateur.data._id) {
        data.utilisateurs = [responseUtilisateur.data._id];
      }

      // Inclure la catégorie sélectionnée dans les données soumises
      data.categorie = selectedCategorie;

      // Suppression de data.prenom si c'est un professionnel
      if (typeTiers !== "CLIENT" || data.clientParticulier === "false") { 
        delete data.prenom;
        delete data.clientParticulier;
        delete data.civilite;
      }
  
      // Suppressions de certains champs si ce n'est pas une agence
      if (typeTiers !== "AGENCE") { 
        delete data.enseigne;
        delete data.raisonsociale;
        delete data.listeModeles;
        delete data.tiers;
      }

      // Création du Tiers dans la base
      logger.log("CreateNewTiers: AVANT post data", data);
     
      const response = await customAxios.post(`/api/tiers/`, data);
      logger.log("CreateNewTiers: APRES post data", data);
      const nouveauTiersId = response.data._id;

      // On ajoute le tiers à la liste des tiers de l'agence
      if (typeTiers !== "AGENCE") {
        // On ajoute le Tiers ID à la liste des Tiers de l'Agence
        try {
          await customAxios.patch(`/api/tiers/${agenceId}/ajouterTiers`, {
            tiersId: nouveauTiersId,
          });
        } catch (error) {
          console.error(
            "CreateNewTiers: Erreur lors de la mise à jour de l'agence avec le nouveau tiers",
            error
          );
        }
      }
      

      // On doit créer un Projet si c'est un PARTENAIRE ou un CLIENT ou un PRESTATAIRE ou un FOURNISSEUR
      // Si c'est un client prendre le modèle par défaut
      if (typeTiers !== "CLIENT") {
        // Vérifie si la liste des modèles est remplie
        if (listeModelesDétaillés.length > 0) {
          // Parcourir chaque modèle pour créer un projet correspondant
          for (const modele of listeModelesDétaillés) {
            data.modeleId = modele._id; // Affecte l'_id du modèle actuel dans data.modeleId
      
            logger.log(
              "CreateNewTiers: onSubmit modeleId",
              data.modeleId,
              data.descriptionProjet,
              data.accesTiers
            );
      
            // Créer un projet pour le modèle actuel
            const nouveauProjet = await createNewProjet(
              data.modeleId,
              typeTiers, // Si c'est un PARTENAIRE, le projet est partagé avec tous les utilisateurs
              nouveauTiersId,
              userId,
              null,
              data.descriptionProjet,
              data.accesTiers // Partage le projet avec les utilisateurs spécifiés
            );
      
            logger.log("CreateNewTiers: nouveauProjet ", nouveauProjet);
          }
        } else {
          console.warn("Aucun modèle disponible dans listeModelesDétaillés.");
        }
      }
      refreshProjects();

      switch (typeTiers) {
        case "PARTENAIRE":
          handleSelectedMenu("PARTENAIRES");
          handleTiersId(nouveauTiersId);
          break;

        case "CLIENT":
          handleSelectedMenu("CLIENTS");
          handleTiersId(nouveauTiersId);
          break;

        case "PRESTATAIRE":
          handleSelectedMenu("ADMIN AGENCES");
          break;

        case "AGENCE":
          handleSelectedMenu("ADMIN AGENCES");
          break;

        default:
          break;
      }
    } catch (error) {
      logger.log("CreateNewTiers: error", error);
      if (error.response && error.response.status === 401) {
        handleLogout();
      } else {
        console.error(error);
      }
    }
  };

  const clientParticulierOptions = [
    { value: "true", label: "Oui" },
    { value: "false", label: "Non" },
  ];

  const civiliteOptions = [
    { value: "Mr", label: "Mr" },
    { value: "Mme", label: "Mme" },
  ];

  return (
    <div className="titre-zone2">
      <FormHeader typeTiers={typeTiers} />

      <form onSubmit={handleSubmit(onSubmit)}>
        <h5>{watch("nom")}</h5>

        {fetchCategoriesError && (
          <div className="error-message">
            <p>
              Erreur lors du chargement des catégories : {fetchCategoriesError}
            </p>
          </div>
        )}

        {/* {typeTiers !== "AGENCE" && (
          <div className="form-field">
            <label htmlFor="attachementAgenceId">Rattachée à </label>
            <select
              style={{ fontSize: "var(--fs-normale)", width: "100%" }}
              {...register("attachementAgenceId", {
                required: "Le choix de l'agence est nécessaire",
              })}
              id="attachementAgenceId"
              name="attachementAgenceId"
              onChange={(e) => {
                setValue("attachementAgenceId", e.target.value);

                const selectedEnseigne = toutesLesEnseignes.find(
                  (enseigne) => enseigne._id === e.target.value
                )?.enseigne;

                if (selectedEnseigne) {
                  setValue("nomEnseigne", selectedEnseigne);
                }
                setIsModified(true);
              }}
            >
              {toutesLesEnseignes &&
                toutesLesEnseignes.map((enseigne, index) => (
                  <option key={index} value={enseigne._id}>
                    {enseigne.enseigne}
                  </option>
                ))}
            </select>
            {errors.attachementAgenceId && (
              <p className="errorMessage">
                {errors.attachementAgenceId.message}
              </p>
            )}
          </div>
        )} 
          {<hr />}
         */}

       

        {typeTiers === "CLIENT" && (
          <SelectField
            label="Particulier"
            name="clientParticulier"
            options={clientParticulierOptions}
            register={register}
            errors={errors}
            onChange={handleInputChange}
            defaultValue="true"
          />
        )}

        {typeTiers === "AGENCE" && (
          <div style={{ textAlign: "center" }}>
            <h5>Coordonnées du gérant</h5>
          </div>
        )}

        <SelectField
          label="Civilité"
          name="civilite"
          options={civiliteOptions}
          register={register}
          errors={errors}
          onChange={handleInputChange}
          defaultValue="Mr" // Définit la valeur par défaut à "Mr"
        />

        <FormField
          label="Nom"
          type="text"
          name="nom"
          placeholder="Nom"
          register={register}
          errors={errors}
          onChange={handleNomChange}
        />

        <FormField
          label="Prénom"
          type="text"
          name="prenom"
          placeholder="Prénom"
          register={register}
          errors={errors}
          onChange={handleInputChange}
        />

        <EmailField
          register={register}
          errors={errors}
          onChange={handleInputChange}
          style={{ flexGrow: 1 }} // Force l'input à s'étendre pour remplir la largeur disponible
        />

        {(typeTiers === "PARTENAIRE" || typeTiers === "AGENCE") && (
          <EnseigneField
            register={register}
            errors={errors}
            onChange={handleInputChange}
            onBlur={handleEnseigneBlur}
          />
        )}

        <FormField
          label="Code NAF"
          type="text"
          name="codeNaf"
          placeholder="Code NAF"
          register={register}
          errors={errors}
          onChange={handleInputChange}
        />
  
        <FormField
          label="Libellé NAF"
          type="text"
          name="libelleNaf"
          placeholder="Libellé NAF"
          register={register}
          errors={errors}
          onChange={handleInputChange}
        />

        <FormField
          label="Catégorie Juridique"
          type="text"
          name="categorieJuridique"
          placeholder="Catégorie Juridique"
          register={register}
          errors={errors}
          onChange={handleInputChange}
        />

        <FormField
          label="Forme Juridique"
          type="text"
          name="formeJuridique"
          placeholder="Forme Juridique"
          register={register}
          errors={errors}
          onChange={handleInputChange}  
        />

        <FormField
          label="Tranche Effectifs"
          type="text"
          name="trancheEffectifs"
          placeholder="Tranche Effectifs"
          register={register}
          errors={errors}
          onChange={handleInputChange}
        />


        <FormField
          label="Adresse"
          type="text"
          name="adresse"
          placeholder="Adresse"
          register={register}
          errors={errors}
          onChange={handleInputChange}
        />

        <CpField
          register={register}
          errors={errors}
          value={tiers.cp}
          onChange={handleInputChange}
        />

        <FormField
          label="Ville"
          type="text"
          name="ville"
          placeholder="Ville"
          register={register}
          errors={errors}
          onChange={handleInputChange}
        />

        <IbanField
          register={register}
          errors={errors}
          onChange={handleInputChange}
        />

        <PhoneField
          register={register}
          errors={errors}
          onChange={handleInputChange}
        />

        <PasswordField register={register} errors={errors} watch={watch} />

        {<hr />}
        {typeTiers !== "CLIENT" && (
          <SirenField
            register={register}
            errors={errors}
            sirenValid={sirenValid}
            onChange={handleInputChange}
            sirenExists={sirenExists} // Passe l'état pour afficher le message d'erreur
     
          />
        )}

        {(typeTiers === "PARTENAIRE" ||
          typeTiers === "PRESTATAIRE" ||
          typeTiers === "FOURNISSEUR" ||
          typeTiers === "AGENCE" ||
          (typeTiers === "CLIENT" &&
            watch("clientParticulier") === "false")) && (
          <FormField
            label="Raison Sociale"
            type="text"
            name="raisonSociale"
            placeholder="Raison Sociale"
            register={register}
            errors={errors}
            onChange={handleNomChange}
          />
        )}

        {<hr />}
        {typeTiers === "AGENCE" && (
          <div className="form-field">
            {/* On va créér une agence avec un modele par défaut  */}
            <label htmlFor="categorie">Type de Services:</label>
            <select
              style={{ fontSize: "var(--fs-normale)", width: "70%" }}
              {...register("categorie", {
                required: "La sélection d'une catégorie est nécessaire",
              })}
              id="categorie"
              name="categorie"
              value={selectedCategorie}
              onChange={(e) => {
                setSelectedCategorie(e.target.value);
                setIsModified(true);
              }}
            >
              <option value="">Sélectionner une catégorie</option>
              {categories &&
                categories
                  .slice() // Crée une copie pour éviter de muter l'état original
                  .sort((a, b) => a.localeCompare(b)) // Trie les catégories par ordre alphabétique
                  .map((categorie, index) => (
                    <option key={index} value={categorie}>
                      {categorie}
                    </option>
                  ))}
            </select>
            {errors.categorie && (
              <p className="errorMessage">{errors.categorie.message}</p>
            )}
          </div>
        )}


        {/* Honeypot field for bot detection pour des raison de sécurité */}
        <input
          type="text"
          name="company"
          value={honeypot}
          onChange={handleHoneypotChange}
          style={{ display: "none" }}
          tabIndex="-1" // Avoids keyboard navigation
          autoComplete="off"
        />
        {typeTiers === "CLIENT" && (
          <FormField
            label="Description du projet"
            type="text"
            name="descriptionProjet"
            placeholder="Description du projet"
            register={register}
            errors={errors}
            onChange={handleNomChange}
          />
        )}

        {isModified && (
          <SubmitButton
            isModified={isModified}
            typeTiers={typeTiers}
            name={watch("nom")}
          />
        )}

        <div>
          <button type="button" onClick={remplirChampsAvecDonneesFictives}>
            Remplir avec des Données Fictives
          </button>
        </div>
      </form>
    </div>
  );
}
