import React, { useEffect, useState, useContext, useCallback } from "react";

import { AuthContext } from "../../contexts/AuthProvider";

import customAxios from "../Utilities/getAxiosToken";

import io from "socket.io-client";
import Cookies from "js-cookie";

//<CountListeTiers typeTiers="PARTENAIRE" AllAgences={false} />
// typeTiers = "AGENCE" "PARTENAIRE", "PRESTATAIRE"
// AllAgences = true/false Compteur de tous les tiers rattaché au userId ou l'agence: donc tous les utilisateurs de l'agence
export function CountListeTiers(props) {
  const [countListeTiers, setCountListeTiers] = useState([]);
   // etatDuTiers = "ouvert" ou "archivé"  pour filtrer les tiers par utilisateur et par état
  const { typeTiers, AllAgences, context, etatDuTiers = "ouvert"  } = props;

  const { userId, agenceId } = useContext(AuthContext);
  const fetchListeTiers = useCallback(async () => {

    try {
      // const etat = "ouvert";

      if (AllAgences) {
        // On récupère tous les typeTiers de l'agenceId

        try {
          const response = await customAxios.post(
            `/api/tiers/getTiersByAgenceIdAndTypeTiers`,
            {
              agenceId: agenceId,
              typeTiers: typeTiers,
            }
          );
          // logger.log("CountListeTiers: AllAgences",AllAgences,"typeTiers",typeTiers,"taille réponse", response.data.tiers.length, "response", response.data);
          setCountListeTiers(response.data.tiers.length);
        } catch (error) {
          console.error("Erreur lors de la récupération des tiers:", error);
        }
      
      } else {
        // Si AllAgences est à false alors On récupère les info sur le userId courant
        if (typeTiers && userId) {
          const response = await customAxios.get(
            `/api/tiers/count/${userId}/${typeTiers}?etatDuTiers=${etatDuTiers}`
          );

          // logger.log("CountListeTiers: typeTiers",typeTiers,"response", response.data);

          setCountListeTiers(response.data.nbTiers);
        }
      }
    } catch (error) {
      console.error(error);
    }
  }, [userId]);

  useEffect(() => {
    

    fetchListeTiers();

    // logger.log(
    //   "CountListeTiers: const socket = io()",process.env.REACT_APP_SOCKET_URL
    // );

    const socket = io(process.env.REACT_APP_SOCKET_URL, {
      path: "/ws",
      auth: {
        token: Cookies.get("userTokenAcces"),
      },
    });
    // Écoute pour un événement spécifique, exemple: 'updateTiers'
    socket.on("updateTiers", (data) => {
      // logger.log("CountListeTiers: webSockets updateTiers", data);
      fetchListeTiers(); // Rafraîchit les données chaque fois que l'événement est reçu
    });

    // Nettoyage de la connexion à la fermeture du composant
    return () => {
      logger.log("CountListeTiers: Closing WebSocket");
      socket.off('updateTiers', fetchListeTiers);
      socket.disconnect();
    };
  }, [userId]);

  return (
    <>
      {context === "bandeau" ? (
        <span>{countListeTiers}</span>
      ) : (
        <span className="badge badge-info compteur">{countListeTiers}</span>
      )}
    </>
  );
}
