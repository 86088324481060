import React, { useEffect, useState, useContext } from 'react';
import { AuthContext } from "../../contexts/AuthProvider";
import customAxios from "../Utilities/getAxiosToken";
import "./css/AdminTiersSelecteur.css";


export function AdminTiersSelecteur() {
    const [tiers, setTiers] = useState([]);
    const [selectedTiers, setSelectedTiers] = useState('');
    const [selectedType, setSelectedType] = useState(''); // Type de tiers sélectionné
    const { handleAgenceId, utilisateurAdmin } = useContext(AuthContext);

    const typesTiersOptions = ["AGENCE", "ADMINISTRATEUR", "CLIENT", "PARTENAIRE", "PRESTATAIRE", "FOURNISSEUR"];

    useEffect(() => {
        const fetchTiersByType = async () => {
            if (selectedType) {  // Ne faire la requête que si un type est sélectionné
                try {
                    const { data } = await customAxios.get(`/api/tiers/typeTiers/${selectedType}`);
                    
                    const fetchedTiers = data?.tousLesTiers || [];
                    
                    // Trier les tiers par nom pour un affichage ordonné
                    const sortedTiers = fetchedTiers.sort((a, b) => (a.nom || a.enseigne || "").localeCompare(b.nom || b.enseigne || ""));
                    
                    setTiers(sortedTiers);
                    logger.log(`Tiers récupérés pour le type ${selectedType}:`, sortedTiers);
                } catch (error) {
                    console.error('Erreur lors de la récupération des tiers', error);
                }
            } else {
                setTiers([]);  // Réinitialiser si aucun type n'est sélectionné
            }
        };

        fetchTiersByType();
    }, [selectedType]);

    const handleTiersChange = (event) => {
        const selectedId = event.target.value;
        setSelectedTiers(selectedId);
        handleAgenceId(selectedId);
    };

    const handleTypeChange = (event) => {
        setSelectedType(event.target.value);
        setSelectedTiers('');  // Réinitialiser la sélection du tiers lors du changement de type
    };

    // Affiche le sélecteur uniquement si l'utilisateur est Manager ou Admin
    if (!utilisateurAdmin) return null;


    return (
        <div>
            <select id="types-tiers-select"
                style={{ marginRight: "10px" }} // Ajout de l'espace à droite
                className="adminTiersSelecteur"  
                value={selectedType} onChange={handleTypeChange}>
                <option value="">--Choisir un type de tiers--</option>
                {typesTiersOptions.map(type => (
                    <option key={type} value={type}>
                        {type}
                    </option>
                ))}
            </select>

            <select id="tiers-select"
                style={{ marginRight: "10px" }} // Ajout de l'espace à droite
                className="adminTiersSelecteur"  
                value={selectedTiers} onChange={handleTiersChange}>
                <option value="">--Choisir un tiers--</option>
                {tiers.map(tier => (
                    <option key={tier._id} value={tier._id}>
                        {tier.nom || tier.enseigne || 'Nom indisponible'}
                    </option>
                ))}
            </select>
        </div>
    );
};