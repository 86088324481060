import customAxios from "./getAxiosToken";

/**
 * Vérifie si un projet est ouvert ou archivé pour un utilisateur donné.
 * 
 * @param {string} userId - ID de l'utilisateur.
 * @param {string} projetId - ID du projet.
 * @returns {Promise<{acces?: string, dateArchivage?: string} | null>} - L'état d'accès (ouvert/archivé), la date d'archivage ou `null` si projetId est absent.
 */
export async function checkProjetAcces(userId, projetId) {
    
  if (!projetId) {
    logger.warn("checkProjetAcces: projetId non défini.");
    return null; // Retourne null ou une valeur par défaut si projetId n'est pas fourni
  }

  const API_URL = `/api/utilisateurs/${userId}/projets/${projetId}/acces`;

  try {
    const response = await customAxios.get(API_URL);
    logger.log("Accès au projet récupéré avec succès:", response.data);

    return {
      acces: response.data.acces, // "ouvert" ou "archivé"
      dateArchivage: response.data.dateArchivage, // optionnel, si présent
    };
  } catch (error) {
    logger.error("Erreur lors de la vérification de l'accès au projet:", error);

    if (error.response) {
      // Gestion des erreurs renvoyées par l'API
      if (error.response.status === 404) {
        throw new Error("Projet introuvable.");
      }
      if (error.response.status === 403) {
        throw new Error("Utilisateur non autorisé pour ce projet.");
      }
    }

    throw new Error("Erreur serveur ou réseau.");
  }
}