import React, { useContext, useState, useEffect } from "react";
import { useForm, useFieldArray } from "react-hook-form";

import "./css/UtilisateurById.css";
import "./../css/Global.css";


import { AuthContext } from "../../contexts/AuthProvider";
import customAxios from "../Utilities/getAxiosToken";

// fetchUtilisateurByid
// utilisateur Sélectionné
// Permet de mofifier tous les champs d'un utilisateur
//

export function UtilisateurById() {
  const { utilisateurId, tiersId, handleLogout } = useContext(AuthContext);

 
  const [utilisateur, setUtilisateur] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const [isModified, setIsModified] = useState(false);

  // Utilisation de useForm
  const {
    register,
    handleSubmit,
    control,
    setValue,
    watch,
    formState: { errors },
  } = useForm();

  // Utilisation de useFieldArray avec l'objet control
  const { fields, append, remove } = useFieldArray({
    control, // Assurez-vous que control est bien passé ici
    name: "reseauxSociaux",
  });

  logger.log("UtilisateurById Main utilisateurId", utilisateurId);

  // Mise à jour de l'enregistrement
  const onSubmit = async (data) => {
    try {
      logger.log("Mise à jour de l'utilisateur avec ID:", utilisateurId, data);
      const response = await customAxios.patch(
        `/api/utilisateurs/${utilisateurId}?isActif=true`,
        data
      );
      logger.log("Utilisateur mis à jour avec succès:", response.data);
      // Mettre à jour l'état utilisateur avec les données retournées, si nécessaire
      // setUtilisateur(response.data);
    } catch (error) {
      // Gestion des erreurs
      console.error("Erreur lors de la mise à jour de l'utilisateur:", error);
      if (error.response && error.response.status === 401) {
        handleLogout();
      }
    }
  };

  // Utilisez un gestionnaire d'événements pour détecter les modifications des champs
  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setUtilisateur((prevTiers) => ({
      ...prevTiers,
      [name]: value,
    }));
    // Marquez le formulaire comme modifié
    setIsModified(true);
  };

  const handleNomChange = (event) => {
    const { name, value } = event.target;

    // Convertir le texte en majuscules
    const upperCaseValue = value.toUpperCase();

    // Mettre à jour le state avec la nouvelle valeur en majuscules
    setUtilisateur((prevTiers) => ({
      ...prevTiers,
      [name]: upperCaseValue,
    }));

    // Mettre à jour la valeur de l'input avec le texte en majuscules
    setValue(name, upperCaseValue);

    // Marquer le formulaire comme modifié
    setIsModified(true);
  };

  const setFieldArrayValues = (fieldArrayName, values) => {
    values.forEach((item, index) => {
      Object.keys(item).forEach((key) => {
        setValue(`${fieldArrayName}[${index}].${key}`, item[key]);
      });
    });
  };

  const fetchUtilisateurByid = async () => {
    try {
      logger.log(
        "UtilisateurById fetchUtilisateurByid AVANT utilisateurId",
        utilisateurId
      );
      if (utilisateurId !== null && utilisateurId !== "") {
        // Si utilisateurId est défini
        logger.log(
          "UtilisateurById fetchUtilisateurByid utilisateurId",
          utilisateurId
        );


        const params = new URLSearchParams({ isActif: true });
        const response = await customAxios.get(
          `/api/utilisateurs/${utilisateurId}?${params}`
        );

        logger.log(
          "UtilisateurById fetchUtilisateurByid utilisateurId",
          utilisateurId,
          "response.data",
          response.data,
          "Taille",
          response.data.count
        );
        const utilisateurData = response.data;
        setUtilisateur(response.data); // On récupère le nom du Projet

        // dateNaissance: "1965-08-24T00:00:00.000Z"
        // Parcourir chaque clé de l'objet utilisateur
        Object.keys(utilisateurData).forEach((key) => {
          setValue(key, utilisateurData[key]);
        });

        // 1. Convertissez la date ISO 8601 en "YYYY-MM-DD"
        const isoDate = utilisateurData.dateNaissance; // Exemple : "1965-08-24T00:00:00.000Z"
        const formattedDate = new Date(isoDate).toISOString().split("T")[0]; // "1965-08-24"

        // 2. Mettez la date dans le champ 'dateNaissance' du formulaire
        setValue("dateNaissance", formattedDate);

        // Traitement spécial pour les champs dynamiques des réseaux sociaux
        if (
          utilisateurData.reseauxSociaux &&
          utilisateurData.reseauxSociaux.length > 0
        ) {
          // Utilisez la méthode 'set' de useFieldArray pour initialiser les champs
          setFieldArrayValues("reseauxSociaux", utilisateurData.reseauxSociaux);
        }

        setIsLoading(false);
      } else {
        // Si utilisateurId n'est pas défini
        logger.log("UtilisateurById fetchUtilisateurByid utilisateurId VIDE");
        // Initialiser tous les champs du formulaire à des valeurs vides
        Object.keys(utilisateur).forEach((key) => {
          setValue(key, "");
        });
        // Vous pouvez également initialiser les champs de l'array à vide si nécessaire
        setFieldArrayValues("reseauxSociaux", []);
      }
      setIsLoading(false);
    } catch (error) {
      if (error.response && error.response.status === 401) {
        logger.log(`UtilisateurById: erreur 401 pas d'accès Token expiré`);
        // Déconnexion de l'utilisateur et redirection vers la page de login
        handleLogout();
      } else {
        console.error(error);
      }
    }
  };

  useEffect(() => {
    setIsLoading(true);
    logger.log("UtilisateurById useEffect utilisateurId", utilisateurId);
    fetchUtilisateurByid();
    setIsModified(false);
  }, [utilisateurId, tiersId]);

  return (
    <div>
      {isLoading ? null : (
        <form onSubmit={handleSubmit(onSubmit)}>
          {utilisateurId ? (
            <h6>
              {watch("prenom")} {watch("nom")}
            </h6>
          ) : (
            <h4>Correspondant principal</h4>
          )}

          <div className="form-group">
            <div className="form-field">
              <label htmlFor="civilite">Civilité :</label>
              <select
                {...register("civilite", {
                  required: "La civilité est requise",
                })}
                id="civilite"
                name="civilite"
                style={{ fontSize: "1em" }}
                onChange={handleInputChange} // Ajoutez ceci pour détecter les changements
              >
                <option value="">Sélectionnez...</option>
                <option value="Mr">Mr</option>
                <option value="Mme">Mme</option>
              </select>
              {errors.civilite && (
                <p className="errorMessage">{errors.civilite.message}</p>
              )}
            </div>

            <div className="form-field">
              <label htmlFor="prenom">Prénom :</label>
              <input
                {...register("prenom", {
                  required: "Le prenom est requis",
                  pattern: {
                    value: /^[A-Za-zÀ-ÖØ-öø-ÿ]+(?:[ -][A-Za-zÀ-ÖØ-öø-ÿ]+)*$/,
                    message: "Format de prenom invalide",
                  },
                })}
                type="text"
                id="prenom"
                name="prenom"
                onChange={handleInputChange} // Ajoutez ceci pour détecter les changements
              />
              {errors.prenom && (
                <p className="errorMessage">{errors.prenom.message}</p>
              )}
            </div>

            <div className="form-field">
              <label htmlFor="nom">Nom :</label>
              <input
                {...register("nom", {
                  required: "Le nom est requis",
                  pattern: {
                    value: /^[A-Za-zÀ-ÖØ-öø-ÿ]+(?:[ -][A-Za-zÀ-ÖØ-öø-ÿ]+)*$/,
                    message: "Format de nom invalide",
                  },
                })}
                type="text"
                id="nom"
                name="nom"
                onChange={handleNomChange} // Ajoutez ceci pour détecter les changements
              />
              {errors.nom && (
                <p className="errorMessage">{errors.nom.message}</p>
              )}
            </div>

            {utilisateur.titre && (
              <>
                <div className="form-field">
                  <label htmlFor="titre">Titre :</label>
                  <input
                    {...register("titre", { required: true })}
                    type="text"
                    id="titre"
                    name="titre"
                    onChange={handleInputChange} // Ajoutez ceci pour détecter les changements
                  />
                  {errors.titre && (
                    <p className="errorMessage">{errors.titre.message}</p>
                  )}
                </div>
              </>
            )}

            <div className="form-field">
              <label htmlFor="email">Email :</label>
              <input
                {...register("email", {
                  required: "L'email est requis",
                  pattern: {
                    value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                    message: "Format d'email invalide",
                  },
                })}
                type="text"
                id="email"
                name="email"
                placeholder="Email"
                onChange={handleInputChange} // Ajoutez ceci pour détecter les changements
              />
              {errors.email && (
                <p className="errorMessage">{errors.email.message}</p>
              )}
            </div>

            <div className="form-field">
              <label htmlFor="telephone">Téléphone :</label>
              <input
                // {...register("telephone", {
                //   required: "Le téléphone est requis",
                //   pattern: {
                //     // Exemple pour un format de numéro de téléphone français sans indicatif international
                //     value: /^0[1-9](?:[ _.-]?[0-9]{2}){4}$/,
                //     message: "Format de téléphone invalide",
                //   },
                // })}
                {
                  ...register("telephone", {
                    required: "Le téléphone est requis",
                    pattern: {
                      // Cette expression régulière accepte les formats +33 suivi de 9 chiffres (format international)
                      // ou un numéro commençant par 0 suivi de 9 chiffres supplémentaires (format national)
                      value: /(\+33\s?[1-9]{1}\d{8})|(0[1-9](?:[ _.-]?[0-9]{2}){4})/,
                      message: "Format de téléphone invalide",
                    },
                  })
                }
                
                type="text"
                id="telephone"
                name="telephone"
                onChange={handleInputChange} // Ajoutez ceci pour détecter les changements
              />
              {errors.telephone && (
                <p className="errorMessage">{errors.telephone.message}</p>
              )}
            </div>

            <div className="form-field">
              <label htmlFor="login">Login :</label>
              <input
                {...register("login", {
                  required: "Login est requis",
                  pattern: {
                    value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                    message: "Format du login invalide",
                  },
                })}
                type="text"
                id="login"
                name="login"
                onChange={handleInputChange} // Ajoutez ceci pour détecter les changements
              />
              {errors.login && (
                <p className="errorMessage">{errors.login.message}</p>
              )}
            </div>

            <div className="form-field">
              <label htmlFor="adresse">Adresse :</label>
              <input
                {...register("adresse", { required: true })}
                type="text"
                id="adresse"
                name="adresse"
                placeholder="Adresse"
                onChange={handleInputChange} // Ajoutez ceci pour détecter les changements
              />
              {errors.adresse && (
                <p className="errorMessage">{errors.adresse.message}</p>
              )}
            </div>

            {/* <div className="form-field">
              <label htmlFor="cp">CP :</label>
              <input
                {...register("cp", { required: "Le code postal est requis" })}
                type="text"
                id="cp"
                name="cp"
                placeholder="CP"
                onChange={handleInputChange} // Ajoutez ceci pour détecter les changements
              />
              {errors.cp && <p className="errorMessage">{errors.cp.message}</p>}
            </div> */}

            <div className="form-field">
              <label htmlFor="cp">CP :</label>
              <input
                {...register("cp", {
                  required: "Le code postal est requis",
                  pattern: {
                    value: /^[0-9]+$/,
                    message: "Le code postal doit être un nombre entier",
                  },
                })}
                type="text"
                id="cp"
                name="cp"
                placeholder="CP"
                onChange={handleInputChange}
              />
              {/* && <p className="errorMessage">{ */}
              {errors.cp && <p className="errorMessage">{errors.cp.message}</p>}
            </div>

            <div className="form-field">
              <label htmlFor="ville">Ville :</label>
              <input
                {...register("ville", { required: "La ville est requise" })}
                type="text"
                id="ville"
                name="ville"
                placeholder="Ville"
                onChange={handleNomChange} // Ajoutez ceci pour détecter les changements
              />
              {errors.ville && (
                <p className="errorMessage">{errors.ville.message}</p>
              )}
            </div>
            {/* 
            <div className="form-field">
              <label htmlFor="role">Rôle :</label>
              <select
                {...register("role", { required: "Le rôle est requis" })}
                id="role"
                name="role"
                onChange={handleInputChange} // Ajoutez ceci pour détecter les changements
              >
                <option value="">Sélectionner un rôle...</option>
                <option value="Manager">Manager</option>
                <option value="Utilisateur">Utilisateur</option>
              </select>
              {errors.role && <p className="errorMessage">{errors.role.message}</p>}
            </div> */}

            <div className="form-field">
              <label htmlFor="dateNaissance">Date de naissance :</label>
              <input
                {...register("dateNaissance", {
                  required: "La date de naissance est requise",
                })}
                type="date"
                id="dateNaissance"
                name="dateNaissance"
                onChange={handleInputChange} // Ajoutez ceci pour détecter les changements
              />
              {errors.dateNaissance && (
                <p className="errorMessage">{errors.dateNaissance.message}</p>
              )}
            </div>

            <div className="form-field">
              <label>Réseaux sociaux :</label>
              {fields.map((field, index) => (
                <div key={field.id}>
                  <input
                    {...register(`reseauxSociaux[${index}].type`)}
                    type="text"
                    defaultValue={field.type}
                    placeholder="Type de réseau social"
                    onChange={handleInputChange} // Ajoutez ceci pour détecter les changements
                  />
                  <input
                    {...register(`reseauxSociaux[${index}].url`)}
                    type="text"
                    defaultValue={field.url}
                    placeholder="URL du réseau social"
                    onChange={handleInputChange} // Ajoutez ceci pour détecter les changements
                  />
                  <button type="button" onClick={() => remove(index)}>
                    Supprimer
                  </button>
                </div>
              ))}
              <button
                type="button"
                onClick={() => append({ type: "", url: "" })}
              >
                Ajouter un réseau social
              </button>
            </div>

            <div className="form-field">
              <label htmlFor="favori">Favori :</label>
              <input
                {...register("favori")}
                type="checkbox"
                id="favori"
                name="favori"
                defaultChecked={utilisateur.favori}
                onChange={handleInputChange} // Ajoutez ceci pour détecter les changements
              />
            </div>

            <div className="form-field">
              <label htmlFor="isActif">Actif :</label>
              <input
                {...register("isActif")}
                type="checkbox"
                id="isActif"
                name="isActif"
                defaultChecked={utilisateur.isActif}
                onChange={handleInputChange} // Ajoutez ceci pour détecter les changements
              />
            </div>

            {isModified && (
              <div
                style={{
                  display: "flex",
                  justifyContent: "flex-end",
                  margin: "10px 0",
                }}
              >
                <button
                  className="btn"
                  onMouseOver={(e) =>
                    (e.target.style.backgroundColor = "#0056b3")
                  }
                  onMouseOut={(e) =>
                    (e.target.style.backgroundColor = "#007bff")
                  }
                >
                  {utilisateurId ? "Mettre à jour" : "Création du contact"}
                </button>
              </div>
            )}
          </div>
        </form>
      )}
    </div>
  );
}
