import React, { useContext } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "./../css/Global.css";

import { useProjet } from "../../contexts/ProjetContext";
import { AuthContext } from "../../contexts/AuthProvider";

// Appelé depuis Zone 3
export function ProjetNomByProjetId() {
  const { projetData } = useProjet();
  const { userId } = useContext(AuthContext);

  console.log("ProjetNomByProjetId userId", userId, projetData);

  // Vérifier que projetData est défini et contient les propriétés nécessaires
  if (!projetData || !projetData._id || !projetData.nom) {
    return <h5 className="titre-zone3">Nom du projet indisponible</h5>;
  }

  // Récupérer l'accès correspondant à l'utilisateur
  const accesUtilisateur = projetData.accesProjet?.find(
    (acces) => acces.utilisateur === userId
  );

  // Déterminer si l'accès est archivé
  const isArchived = accesUtilisateur?.etat === "archivé";

  // Si projetData est bien défini, afficher le nom du projet avec un sigle
  return (
    <h5 className="titre-zone3" style={{ textAlign: "center" }}>
      {projetData.nom}{" "}
      {isArchived && (
        <span
          style={{
            fontSize: "0.9em",
            color: "red",
            fontWeight: "bold",
            marginLeft: "10px",
          }}
        >
          [Archivé]
        </span>
      )}
    </h5>
  );
}