import customAxios from "./getAxiosToken";

// Fonction pour activer un projet après validation on met à jour le champ isValid
const activateProject = async (projectId) => {
  try {
    const response = await customAxios.patch(
      `/api/projets/activate/${projectId}`
    );
    if (response.status === 200) {
      logger.log("Projet activé avec succès");
    } else {
      console.error(`Erreur: statut de la réponse ${response.status}`);
    }
  } catch (error) {
    if (error.response) {
      console.error(
        `Erreur lors de l'activation du projet: ${error.response.status} - ${error.response.data.message}`
      );
    } else {
      console.error("Erreur lors de l'activation du projet:", error.message);
    }
  }
};

// Fonction pour désactiver un projet, on met à jour le champ isValid
const deactivateProject = async (projectId) => {
  try {
    const response = await customAxios.patch(
      `/api/projets/deactivate/${projectId}`
    );
    if (response.status === 200) {
      logger.log("Projet désactivé avec succès");
    } else {
      console.error(`Erreur: statut de la réponse ${response.status}`);
    }
  } catch (error) {
    if (error.response) {
      console.error(
        `Erreur lors de la désactivation du projet: ${error.response.status} - ${error.response.data.message}`
      );
    } else {
      console.error("Erreur lors de la désactivation du projet:", error.message);
    }
  }
};



export { activateProject, deactivateProject };
