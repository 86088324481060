import React, { useEffect, useState, useContext } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import customAxios from "../Utilities/getAxiosToken";
import "./../css/Global.css";

import { AuthContext } from "../../contexts/AuthProvider";
import { Tooltip } from "react-tooltip";
import useGetModeles from "../Utilities/GetModeles";

export function ListeProjetsSansAttentes({ AllAgences }) {
  const { fetchModeles } = useGetModeles();
  const { userId, agenceId } = useContext(AuthContext);
  const [projets, setProjets] = useState({});
  const [groupedProjets, setGroupedProjets] = useState({});
  const [toggle, setToggle] = useState(false);

  // Fonction pour charger tous les projets
  useEffect(() => {
    const chargerTousLesProjets = async () => {
      if (agenceId) {
        try {
          const tousLesModelesDetailles = await fetchModeles(agenceId);

          // Filtrer pour obtenir uniquement les modèles où isCheck est true
          const modelesFiltres = tousLesModelesDetailles.filter(
            (modele) => modele.isCheck
          );
          const tousLesModelesIds = modelesFiltres.map((modele) => modele._id);

          const payload = {
            modeleIds: tousLesModelesIds,
            etatProjet: "ouvert", // C'est l'état du projet pour ce userId
          };

          if (tousLesModelesIds && tousLesModelesIds.length > 0) {
            const url = `/api/projets/projetsSansAttentes`;
            const response = await customAxios.post(url, payload);

            const projetsTries = response.data.sort((a, b) => {
              const modeleCompare = a.nomModele.localeCompare(b.nomModele);
              if (modeleCompare !== 0) {
                return modeleCompare; // Trie par nomModele
              }
              return a.nomProjet.localeCompare(b.nomProjet); // Puis par nomProjet
            });

            if (AllAgences) {
              const grouped = projetsTries.reduce((acc, projet) => {
                acc[projet.nomModele] = [
                  ...(acc[projet.nomModele] || []),
                  projet,
                ];
                return acc;
              }, {});
              setProjets(projetsTries);
              setGroupedProjets(grouped);
            } else {
              const projetsFiltres = projetsTries.filter((projet) =>
                projet.utilisateurs.some(
                  (utilisateur) => utilisateur.userId === userId
                )
              );

              const grouped = projetsFiltres.reduce((acc, projet) => {
                acc[projet.nomModele] = [
                  ...(acc[projet.nomModele] || []),
                  projet,
                ];
                return acc;
              }, {});
              setProjets(projetsFiltres);
              setGroupedProjets(grouped);
            }
          }
        } catch (error) {
          console.error("Erreur lors de la récupération des projets :", error);
        }
      }
    };

    chargerTousLesProjets();
  }, [userId, agenceId, AllAgences]);

  // Fonction pour vérifier s'il y a des erreurs
  const hasErrors = () => {
    return Object.keys(groupedProjets).length > 0;
  };
  

  const totalProjets = Object.values(groupedProjets).flat().length;
  const projetPlural = totalProjets === 1 ? "projet" : "PROJETS CLIENTS";

  // Si aucun projet sans attente n'est trouvé, on ne renvoie rien
  if (Object.keys(groupedProjets).length === 0) {
    return null;
  }

  return (
    <>
      <button
        className="bouton-commun"
        data-tooltip-id="ToolTipSansAttentes"
        data-tooltip-content="Liste des projets sans attentes, en fonction des options (À vérifier) modèles."
        data-tooltip-place="top"
        onClick={() => setToggle(!toggle)}
      >
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            flexDirection: "column",
          }}
        >
          {/* Indicateur de statut */}
          <div
            style={{
              display: "flex",
              alignItems: "center",
              margin: "8px 0",
            }}
          >
            <div
              style={{
                width: "12px",
                height: "12px",
                marginRight: "8px",
                borderRadius: "50%", // Forme circulaire
                backgroundColor: hasErrors() ? "red" : "green", // Couleur selon le statut
                boxShadow: `0px 2px 4px rgba(0, 0, 0, 0.3)`, // Ombre pour le volume
                position: "relative",
              }}
              
            >
              {/* Reflet subtil */}
              {/* <div
                style={{
                  position: "absolute",
                  top: "2px",
                  left: "2px",
                  width: "4px",
                  height: "4px",
                  borderRadius: "50%",
                  backgroundColor: "white",
                  opacity: 0.8,
                }}
              /> */}
            </div>
            <h6 style={{ margin: 0 }}>STATUT PROJETS</h6>
          </div>

          {/* Détails des projets (affichés si `toggle` est activé) */}
          {toggle && (
            <div style={{ marginTop: "8px", textAlign: "center" }}>
              <h6 style={{ marginBottom: "4px" }}>À Renseigner</h6>
              <span
                style={{
                  color: "red",
                  marginBottom: "8px",
                  display: "block",
                }}
              >
                {totalProjets} {projetPlural}
              </span>
              {Object.keys(groupedProjets).length > 0 ? (
                Object.entries(groupedProjets).map(([nomModele, projets]) => (
                  <div key={nomModele} style={{ marginBottom: "8px" }}>
                    <strong>{nomModele}</strong>{" "}
                    {projets.length > 1 ? `(Projets : ${projets.length})` : ""}
                    <ul style={{ paddingLeft: "20px", margin: "4px 0" }}>
                      {projets.map((projet, index) => (
                        <li key={index}>
                          {AllAgences
                            ? `${projet.nomProjet} - ${projet.utilisateurs
                                .map((u) => u.initiales)
                                .join(", ")}`
                            : projet.nomProjet}
                        </li>
                      ))}
                    </ul>
                  </div>
                ))
              ) : (
                <p>Aucun projet trouvé.</p>
              )}
            </div>
          )}
        </div>
      </button>

      {/* Tooltip */}
      <Tooltip
        id="ToolTipSansAttentes"
        place="top"
        effect="solid"
        className="custom-tooltip"
      />
    </>
  );
}
