import React, { useEffect, useState, useContext, useCallback } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "./../css/Global.css";

import { AuthContext } from "../../contexts/AuthProvider";

import io from "socket.io-client";
import Cookies from "js-cookie";
import useGetModeles from "../Utilities/GetModeles";

export function CountModeles() {
  const { userId, agenceId } = useContext(AuthContext);
  const [nbModeles, setNbModeles] = useState(0);
  const { fetchModeles } = useGetModeles();

  const chargerModeles = useCallback(async () => {
    if (userId && agenceId) {
      try {
        const tousLesModelesDetailles = await fetchModeles(agenceId);
        setNbModeles(tousLesModelesDetailles.length);
      } catch (error) {
        console.error("Erreur lors du chargement des modèles:", error);
        setNbModeles(0); // Réinitialiser en cas d'erreur
      }
    }
  }, [userId, agenceId]);

  useEffect(() => {
    chargerModeles();
    logger.log(
      "CountModeles: const socket = io()",
      process.env.REACT_APP_SOCKET_URL
    );

    const socket = io(process.env.REACT_APP_SOCKET_URL, {
      path: "/ws",
      auth: {
        token: Cookies.get("userTokenAcces"),
      },
    });
    socket.on("nouveauModèle", chargerModeles);

    return () => {
      logger.log("CountModeles: Closing WebSocket");
      socket.disconnect();
  };
  }, [chargerModeles]);

  return userId ? <span className="badge badge-info">{nbModeles}</span> : null;
}
