import axios from "axios";

/**
 * Récupère le libellé du code NAF.
 * @param {string} codeNaf - Le code NAF.
 * @returns {Promise<string>} - Le libellé du code NAF ou "Non disponible" en cas d'erreur.
 */
const fetchNafLibelle = async (codeNaf) => {
  try {
    const responseNaf = await axios.get(`/proxy/nafr2/${codeNaf}`);
    logger.log("fetchNafLibelle", codeNaf, responseNaf.data);
    return responseNaf.data.intitule || "Non disponible";
  } catch (err) {
    console.error("Erreur lors de la récupération du libellé NAF:", err);
    return "Non disponible";
  }
};

/**
 * Récupère la forme juridique.
 * @param {string} categoryCode - Le code de la catégorie juridique.
 * @returns {Promise<string>} - La forme juridique ou "Non disponible" en cas d'erreur.
 */
const fetchLegalForm = async (categoryCode) => {
  try {
    const responseLegalForm = await axios.get(`/proxy/legalForm/${categoryCode}`);
    return responseLegalForm.data.intitule || "Non disponible";
  } catch (err) {
    console.error("Erreur lors de la récupération de la forme juridique:", err);
    return "Non disponible";
  }
};

export { fetchNafLibelle, fetchLegalForm };