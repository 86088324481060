import axios from "axios";
import Cookies from "js-cookie";

const userTokenAcces = Cookies.get("userTokenAcces");
const customAxios = axios.create({
  headers: {
    common: {
      Authorization: `Bearer ${userTokenAcces}`,
      'Content-Type': 'application/json; charset=utf-8'
    }
  }
});


export default customAxios;
