import React, { useEffect, useState } from "react";
import customAxios from "../Utilities/getAxiosToken";
import { Tooltip } from "react-tooltip";
import { BsTrash, BsPencilSquare } from "react-icons/bs";
import { Modal, Button, Form } from "react-bootstrap";

export function ListeRolesPermissions() {
  const [rolesPermissions, setRolesPermissions] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [modalData, setModalData] = useState({ role: "", typeUtilisateur: "", actions: [], menus: [] });
  const [isEdit, setIsEdit] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  // Fonction pour charger la liste des rôles et permissions
// Retour du log
// {_id: "672cffa9a29a7c1ddca8b454", role: "Utilisateur", typeUtilisateur: "CLIENT", actions: ["LECTURE"], menus: ["REQUÊTES"]}
// {_id: "672cffa9a29a7c1ddca8b457", role: "Manager", typeUtilisateur: "CLIENT", actions: ["LECTURE", "ECRITURE", "SUPPRESSION", "APPROBATION"], menus: ["REQUÊTES", "PROJETS"]}
// {_id: "672cffa9a29a7c1ddca8b45a", role: "Utilisateur", typeUtilisateur: "AGENCE", actions: ["LECTURE", "ECRITURE", "SUPPRESSION"], menus: Array}
// {_id: "672cffa9a29a7c1ddca8b45d", role: "Manager", typeUtilisateur: "AGENCE", actions: ["LECTURE", "ECRITURE", "SUPPRESSION", "APPROBATION"], menus: Array}
// {_id: "672cffa9a29a7c1ddca8b461", role: "Admin", typeUtilisateur: "AGENCE", actions: ["TOUS DROITS"], menus: Array}
// {_id: "672cffa9a29a7c1ddca8b464", role: "Utilisateur", typeUtilisateur: "PARTENAIRE", actions: ["LECTURE", "ECRITURE"], menus: ["REQUÊTES", "PROJETS"]}
// {_id: "672cffa9a29a7c1ddca8b467", role: "Manager", typeUtilisateur: "PARTENAIRE", actions: ["LECTURE", "ECRITURE", "SUPPRESSION", "APPROBATION"], menus: ["REQUÊTES", "PROJETS"]}
// {_id: "672cffa9a29a7c1ddca8b46a", role: "Utilisateur", typeUtilisateur: "FOURNISSEUR", actions: ["LECTURE", "ECRITURE"], menus: ["REQUÊTES", "PROJETS"]}
// {_id: "672cffa9a29a7c1ddca8b46d", role: "Manager", typeUtilisateur: "FOURNISSEUR", actions: ["LECTURE", "ECRITURE", "SUPPRESSION", "APPROBATION"], menus: ["REQUÊTES", "PROJETS"]}


  const fetchRolesPermissions = async () => {
    try {
      const response = await customAxios.get("/api/permissions/Allpermissions");
      logger.log("Permissions:", response.data); // Vérifie le contenu complet de la réponse
      if (response.data && response.data.data) {
        setRolesPermissions(response.data.data); // Assure que les données existent
      } else {
        console.error("Aucune donnée de permission trouvée dans la réponse.");
        setRolesPermissions([]); // Met à jour avec un tableau vide si les données ne sont pas trouvées
      }
    } catch (error) {
      console.error("Erreur lors du chargement des permissions:", error);
    }
  };

  useEffect(() => {
    fetchRolesPermissions();
  }, []);

  // Fonction pour afficher le modal de création ou d'édition
  const handleShowModal = (permission = null) => {
    setIsEdit(!!permission);
    setModalData(permission || { role: "", typeUtilisateur: "", actions: [], menus: [] });
    setShowModal(true);
  };

  // Fonction pour gérer la soumission du formulaire d'ajout ou de modification
  const handleSave = async () => {
    try {
      if (isEdit) {
        logger.log("Mise à jour de la permission:", modalData);
        await customAxios.put(`/api/permissions/${modalData.role}/${modalData.typeUtilisateur}`, modalData);
      } else {
        await customAxios.post("/api/permissions", modalData);
      }
      fetchRolesPermissions();
      setShowModal(false);
      setErrorMessage("");
    } catch (error) {
      console.error("Erreur lors de la sauvegarde:", error);
      setErrorMessage("Une erreur est survenue lors de la sauvegarde.");
    }
  };

  // Fonction pour gérer la suppression d'une permission
  const handleDelete = async (role, typeUtilisateur) => {
    try {
      await customAxios.delete(`/api/permissions/${role}/${typeUtilisateur}`);
      fetchRolesPermissions();
    } catch (error) {
      console.error("Erreur lors de la suppression:", error);
      setErrorMessage("Une erreur est survenue lors de la suppression.");
    }
  };

  // Rendu de la liste des rôles et permissions
  const renderRolesPermissions = () => {
    logger.log("Rendu des permissions:", rolesPermissions); // Vérifie le contenu avant le rendu
    return rolesPermissions.map((permission, index) => (
      <tr key={`${permission.role}-${permission.typeUtilisateur}`} className={index % 2 === 0 ? "bg-custom" : ""}>
        <td>{permission.role}</td>
        <td>{permission.typeUtilisateur}</td>
        <td>{permission.actions.join(", ")}</td>
        <td>{permission.menus.join(", ")}</td>
        <td>
          <BsPencilSquare
            style={{ cursor: "pointer", marginRight: "10px" }}
            onClick={() => handleShowModal(permission)}
            title="Modifier"
          />
          <BsTrash
            style={{ cursor: "pointer", color: "grey" }}
            onClick={() => handleDelete(permission.role, permission.typeUtilisateur)}
            title="Supprimer"
          />
        </td>
      </tr>
    ));
  };

  // Rendu du modal de création/modification
  const renderModal = () => (
    <Modal show={showModal} onHide={() => setShowModal(false)}>
      <Modal.Header closeButton>
        <Modal.Title>{isEdit ? "Modifier" : "Ajouter"} une permission</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {errorMessage && <div className="alert alert-danger">{errorMessage}</div>}
        <Form>
          <Form.Group controlId="formRole">
            <Form.Label>Rôle</Form.Label>
            <Form.Control
              type="text"
              placeholder="Nom du rôle"
              value={modalData.role}
              onChange={(e) => setModalData({ ...modalData, role: e.target.value })}
              disabled={isEdit}
            />
          </Form.Group>

          <Form.Group controlId="formTypeUtilisateur" className="mt-3">
            <Form.Label>Type d'Utilisateur</Form.Label>
            <Form.Control
              as="select"
              value={modalData.typeUtilisateur}
              onChange={(e) => setModalData({ ...modalData, typeUtilisateur: e.target.value })}
              disabled={isEdit}
            >
              <option value="">Sélectionner</option>
              <option value="CLIENT">Client</option>
              <option value="PARTENAIRE">Partenaire</option>
              <option value="FOURNISSEUR">Fournisseur</option>
              <option value="AGENCE">Agence</option>
            </Form.Control>
          </Form.Group>

          <Form.Group controlId="formActions" className="mt-3">
            <Form.Label>Actions</Form.Label>
            <Form.Control
              type="text"
              placeholder="Actions (séparées par des virgules)"
              value={modalData.actions.join(", ")}
              onChange={(e) => setModalData({ ...modalData, actions: e.target.value.split(",").map(a => a.trim()) })}
            />
          </Form.Group>

          <Form.Group controlId="formMenus" className="mt-3">
            <Form.Label>Menus</Form.Label>
            <Form.Control
              type="text"
              placeholder="Menus (séparés par des virgules)"
              value={modalData.menus.join(", ")}
              onChange={(e) => setModalData({ ...modalData, menus: e.target.value.split(",").map(m => m.trim()) })}
            />
          </Form.Group>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={() => setShowModal(false)}>
          Annuler
        </Button>
        <Button variant="primary" onClick={handleSave}>
          {isEdit ? "Enregistrer les modifications" : "Ajouter"}
        </Button>
      </Modal.Footer>
    </Modal>
  );

  return (
    <div className="container">
      <h4>Gestion des Rôles et Permissions</h4>
      <Button variant="primary" className="mb-3" onClick={() => handleShowModal()}>
        Ajouter un rôle et une permission
      </Button>
      {errorMessage && <div className="alert alert-danger">{errorMessage}</div>}
      {rolesPermissions.length === 0 ? (
        <p>Aucune permission disponible.</p>
      ) : (
        <table className="table table-bordered table-striped">
          <thead>
            <tr>
              <th>Rôle</th>
              <th>Type d'Utilisateur</th>
              <th>Actions</th>
              <th>Menus</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>{renderRolesPermissions()}</tbody>
        </table>
      )}
      {renderModal()}
    </div>
  );
}