import React, { useEffect, useState, useContext, useCallback } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "./../css/Global.css";

import { AuthContext } from "../../contexts/AuthProvider";
import customAxios from "../Utilities/getAxiosToken";
import { activateProject, deactivateProject } from "../Utilities/ActivateProjet";

//
// Si etapeId existe alors une étape est sélectionnée
//

export const ListeDestinataires = React.memo(({ typeTiers }) => {
  const [tiers, setListeTiers] = useState([]);
 
  const {
    userId,
    projetId,
    etapeId,
    isDestinatairesOpen,
    handleModificationDestinataires,
  } = useContext(AuthContext);

  const fetchAndUpdateTiersData = useCallback(async () => {
    logger.log("ListeDestinataires: fetchAndUpdateTiersData ", typeTiers);
    if (!Array.isArray(typeTiers) || !userId || !projetId || !etapeId) return;

    try {
      // Fetch data for each type in typeTiers array
      let allTiers = [];
      for (const type of typeTiers) {
        const requestBodyTiers = {
          userId: userId,
          typeTiers: type,
        };

        const listeTiersResponse = await customAxios.post(
          `/api/tiers/byUserIdAndTypeTiers`,
          requestBodyTiers
        );
        allTiers = [...allTiers, ...listeTiersResponse.data.tiers];
        console.log(
          "ListeDestinataires: fetchAndUpdateTiersData ",
          type,
          listeTiersResponse.data.tiers
        );
      }

      const requestBodyProjet = {
        etapeId: etapeId,
        projetId: projetId,
      };
      logger.log(
        "ListeDestinataires: requestBodyProjet projetId",
        projetId
      );
      const etapeData = await customAxios.post(
        `/api/projets/projetByetapeId`,
        requestBodyProjet
      );
      const currentEtape = etapeData.data;
      logger.log(
        "ListeDestinataires: projetByetapeId etapeData.data",
        etapeData.data
      );
      if (currentEtape) {
        allTiers = allTiers.map((tier) => ({
          ...tier,
          checked: currentEtape.actionDestinataires.some(
            (destinataire) => destinataire.tiers === tier._id
          ),
        }));
      }

      // console.log(
      //   "ListeDestinataires: allTiers ",
      //   allTiers
      // );
      setListeTiers(allTiers);
    } catch (error) {
      console.error(
        "Erreur lors de la récupération des données des tiers:",
        error
      );
    }
  }, [typeTiers, userId, etapeId, projetId]);

  // useEffect(() => {
  //   fetchAndUpdateTiersData();
  // }, [fetchAndUpdateTiersData]);


  useEffect(() => {
    if (typeTiers && userId && projetId && etapeId) {
      fetchAndUpdateTiersData();
    }
  }, [typeTiers, userId, projetId, etapeId]); // Vérifiez que ces dépendances changent réellement

  // A chaque fois qu'on change d'étape on réinitialise les checkbox
  useEffect(() => {
    const updatedTiers = tiers.map((monTiers) => {
      return { ...monTiers, checked: false };
    });

    // logger.log("ListeDestinataires useEffect updatedTiers", updatedTiers);
    // logger.log("ListeDestinataires useEffect isDestinatairesOpen", isDestinatairesOpen);
    // logger.log("ListeDestinataires useEffect etapeId", etapeId);
    setListeTiers(updatedTiers);
  }, [typeTiers, etapeId, isDestinatairesOpen]);

  // Gestion du changement de la checkbox
  const handleCheckboxChange = async (tiersId, checked) => {
    checked ? await addTagsEtape(tiersId) : await removeTagEtape(tiersId);
    // fetchAndUpdateTiersData(); // Refresh data to reflect changes
  };


  const upDateIsValidProject = useCallback(
    async (currentProjetId) => {
      if (!currentProjetId) return;
      try {
        const response = await customAxios.get(
          `/api/projets/isValid/${currentProjetId}`
        );
        const { isValid} = response.data;

        // console.log(
        //   "ListeDestinataires upDateIsValidProject ",
        //   nomProjet,
        //   isValid,
        //   invalidSteps
        // );
        if (!isValid) {
          deactivateProject(currentProjetId); // Désactiver le projet si non valide
          return false;
        } else {
          activateProject(currentProjetId); // Activer le projet si valide
          return true;
        }
      } catch (error) {
        console.error("Erreur lors de la validation du projet:", error);
        return false;
      }
    },
    []
  );

  const addTagsEtape = async (tiersId) => {
    try {
      await customAxios.patch(`/api/projets/addDestinataire`, {
        projetId,
        etapeId,
        tiersId,
      });
      handleModificationDestinataires((prevState) => !prevState);
      await upDateIsValidProject(projetId); // Valider le projet après la mise à jour des destinataires
    } catch (error) {
      console.error("Erreur lors de l'ajout du destinataire à l'étape:", error);
    }
  };

  const removeTagEtape = async (tiersId) => {
    try {
      await customAxios.patch(
        `/api/projets/removeDestinataire`,
        {
          projetId,
          etapeId,
          tiersId,
        }
      );
      handleModificationDestinataires((prevState) => !prevState);
      await upDateIsValidProject(projetId); // Valider le projet après la mise à jour des destinataires
    } catch (error) {
      console.error(
        "Erreur lors de la suppression du destinataire de l'étape:",
        error
      );
    }
  };

  const renderTiersList = () => {
    // Trier les tiers par nom
    const sortedTiers = [...tiers].sort((a, b) => {
      if (a.nom < b.nom) return -1;
      if (a.nom > b.nom) return 1;
      return 0;
    });

    // console.log(
    //   "ListeDestinataires renderTiersList",
    //   sortedTiers.length,
    //   sortedTiers
    // );

    return (
      <ul className="liste-sans-puces">
        {sortedTiers.map((monTiers, index) => (
          <li key={monTiers._id}>
            {/* <div className="form-check d-flex ms-1"> */}
            <div className="form-check d-flex">
              <input
                className="checked-item me-2"
                type="checkbox"
                id={`flexCheckDefault${monTiers._id}-${index}`}
                checked={monTiers.checked ?? false}
                onChange={(e) =>
                  handleCheckboxChange(monTiers._id, e.target.checked)
                }
              />
              <label
                className="form-check-label"
                htmlFor={`flexCheckDefault${monTiers._id}-${index}`}
              >
                {typeTiers === "CLIENT"
                  ? `${monTiers.nom} ${monTiers.prenom}`
                  : monTiers.nom}
              </label>
            </div>
          </li>
        ))}
      </ul>
    );
  };




  return (
    <div
      className={`fenetre-liste-tiers cadre-zone4 ${
        etapeId ? "selected-etape" : ""
      }`}
    >
  
    <div>
      {isDestinatairesOpen &&
        (tiers.length > 0 ? (
          renderTiersList()
        ) : (
          <p>Aucun destinataire trouvé.</p>
        ))}
    </div>

    </div>
  );
});
