import React, { useEffect, useState, useContext } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "./../css/Global.css";

import { AuthContext } from "../../contexts/AuthProvider";
import customAxios from "../Utilities/getAxiosToken";
import { Tooltip } from "react-tooltip";


// On affiche les compétencesGlobales du Tiers
export function ListeCompétences({ typeTiers }) {
 
  const { tiersId, handleLogout } =useContext(AuthContext);

  const [compétencesGlobales, setCompétencesGlobales] = useState([]);
  const [competencesTiers, setCompétencesTiers] = useState([]);
  
  logger.log("ListeCompétences DEBUT typeTiers:", typeTiers);

  useEffect(() => {
    const fetchCorpsDeMetier = async () => {
      try {
        // Récupération de la liste de tous les corps de métier via l'API
        const responseCorpsDeMetier = await customAxios.get(`/api/corpsdemetiers`);
        logger.log("ListeCompétences fetchCorpsDeMetier responseCorpsDeMetier", responseCorpsDeMetier.data);
         // Conserver les champs _id, famille, description, et label de chaque corps de métier
        const listeCorpsDeMetier = responseCorpsDeMetier.data.map(cdm => ({
          _id: cdm._id,
          label: cdm.label,
          famille: cdm.famille,
          description: cdm.description
        })).sort((a, b) => a.label.localeCompare(b.label)); // Trier par label
       
        setCompétencesGlobales(listeCorpsDeMetier);
        logger.log("ListeCompétences fetchCorpsDeMetier setCompétencesGlobales", listeCorpsDeMetier);
      } catch (error) {
        if (error.response && error.response.status === 401) {
          logger.log("ListeCompétences Erreur 401 - Pas d'accès, token expiré.");
          handleLogout();
        } else {
          console.error("ListeCompétences Erreur lors de la récupération des corps de métier:", error);
        }
      }
    };
  
    fetchCorpsDeMetier();
  }, []);
  

  useEffect(() => {
    const fetchTiersAndCompétences = async () => {
      try {
        const responseTiers = await customAxios.get(
          `/api/tiers/${tiersId}`
        );

        const tiersData = responseTiers.data;
        const compétencesGlobalesDuTiers = tiersData.competences || [];
        // Ici on récupère un tableau d'IDs
        setCompétencesTiers(compétencesGlobalesDuTiers);
        logger.log("ListeCompétences fetchTiersAndCompétences setCompétencesTiers", compétencesGlobalesDuTiers);
     
      } catch (error) {
        if (error.response && error.response.status === 401) {
          logger.log(`ListeCompétences Erreur 401 - Pas d'accès, token expiré.`);
          handleLogout();
        } else {
          console.error(error);
        }
      }
    };
    fetchTiersAndCompétences();
  }, [ tiersId, handleLogout]);

 
  useEffect(() => {
    logger.log("ListeCompétences competencesTiers A ETE MODIFIÉ");
    updateCompetencesOnServer(); // Mettre à jour les compétencesGlobales sur le serveur lorsque competencesTiers change
  }, [competencesTiers]); // Ajoutez competencesTiers comme dépendance de useEffect
  

  const updateCompetencesOnServer = async () => {
    try {
      logger.log(
        "ListeCompétences competencesTiers mises à jour sur le serveur :",
        competencesTiers, "pour", tiersId
      );

      // Envoyer les compétencesGlobales mises à jour au serveur
      const response = await customAxios.patch(
        `/api/tiers/${tiersId}`,
        { competences: competencesTiers }
      );

      logger.log("ListeCompétences Réponse competencesTiers mises à jour sur le serveur :", response.data);
    } catch (error) {
      console.error("ListeCompétences Erreur lors de la mise à jour des compétencesGlobales :", error);
    }
  };


  const CompétenceItem = React.memo(({ compétence, estSélectionné, onChange }) => {
    return (
  
      <li className={estSélectionné ? "bg-custom" : ""}>
        <div className="form-check d-flex ms-1">
          <input
            className="checked-item me-2"
            type="checkbox"
            value={compétence._id}
            id={`competenceCheckbox${compétence._id}`}
            checked={estSélectionné}
            onChange={onChange}
          />
          <label 
            className="form-check-label" 
            htmlFor={`competenceCheckbox${compétence._id}`}
            data-tooltip-id={`infoCompetence-${compétence._id}`}
            data-tooltip-content={`${compétence.description}`}
            data-tooltip-place="top"
          >
            {compétence.label}
          </label>
          <Tooltip
                  id={`infoCompetence-${compétence._id}`}
                  place="top"
                  effect="solid"
                  className="custom-tooltip"
                ></Tooltip>
          {/* Assurez-vous que Tooltip est correctement configuré selon la bibliothèque */}
        </div>
      </li>
    );
  });
  
  return (
    <div className="cadre-zone4 listeCompetences">
      <ul className="liste-sans-puces "
      >
        {compétencesGlobales.map((compétence) => {
          const estSélectionné = competencesTiers.includes(compétence._id);
  
          return (
            <CompétenceItem
              key={compétence._id}
              compétence={compétence}
              estSélectionné={estSélectionné}
              onChange={() => {
                const updatedCompétences = estSélectionné
                  ? competencesTiers.filter(id => id !== compétence._id)
                  : [...competencesTiers, compétence._id];
  
                setCompétencesTiers(updatedCompétences);
              }}
            />
          );
        })}
      </ul>
    </div>
  );
  
  

}
