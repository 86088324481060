import React, { useEffect, useState, useContext } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "./../css/Global.css";


import { AuthContext } from "../../contexts/AuthProvider";
import customAxios from "../Utilities/getAxiosToken";
import { Tooltip } from "react-tooltip";

// Liste toutes les listePartenaires du site

export function ListeAdminPartenaires(props) {
  const { typeTiers } = props;
  const [ListeAdminPartenaires, setListeAdminPartenaires] = useState([]);
  const [selectedTiers, setSelectedTiers] = useState({});
 
  const { handleLogout, handleTiersId, handleUtilisateurId } =
    useContext(AuthContext);

  const handleLabelClick = (agenceId) => {
    setSelectedTiers({ [agenceId]: true });
    handleUtilisateurId(""); // On reset l'utilisateurId en cours
    handleTiersId(agenceId);
  };

  useEffect(() => {
    const fetchListeAdminPartenaires = async () => {
      try {
        logger.log("ListeAdminPartenaires fetchListeAdminPartenaires DEBUT typeTiers", typeTiers );

        const response = await customAxios.get(
          `/api/tiers/typeTiers/${typeTiers}`
        );
        const listePartenaires = response.data.tousLesTiers;
        logger.log("ListeAdminPartenaires fetchListeAdminPartenaires", typeTiers, listePartenaires);
      
        setListeAdminPartenaires(listePartenaires);

        // S'il y a qu'un seul élément alors forcer le tiersid
        if (listePartenaires.length === 1){
          const agenceId = listePartenaires[0]._id;
          setSelectedTiers({ [agenceId]: true });
          handleUtilisateurId(""); // On reset l'utilisateurId en cours
          handleTiersId(agenceId);
          logger.log("ListeAdminPartenaires fetchListeAdminPartenaires", typeTiers, agenceId);
        } else {
          handleTiersId(null);
        }

      } catch (error) {
        if (error.response && error.response.status === 401) {
          logger.log(
            `ListeAdminPartenaires: erreur 401 - pas d'accès, token expiré.`
          );
          handleLogout();
        } else {
          console.error(error);
        }
      }
    };


    logger.log("ListeAdminPartenaires fetchListeAdminPartenaires typeTiers: ", typeTiers);
    fetchListeAdminPartenaires();

  }, [typeTiers]);

  const ListeAdminPartenairesTriees = ListeAdminPartenaires.slice().sort((a, b) => {
    const nomA = a.enseigne.toUpperCase(); // Convertir en majuscules pour un tri insensible à la casse
    const nomB = b.enseigne.toUpperCase();

    if (nomA < nomB) {
      return -1;
    }
    if (nomA > nomB) {
      return 1;
    }
    return 0;
  });



  return (
    <div className="fenetre-liste-tiers">
      {/* <h5 data-tooltip-id={`TooltipVueEnseigne`} data-tooltip-content={"Liste tous les Partenaires"}>
        Tous les  Partenaires
      </h5> */}
      <Tooltip id="TooltipVueEnseigne" place="top" effect="solid" className="custom-tooltip"></Tooltip>
      <div>
        {ListeAdminPartenairesTriees.length > 0 ? (
          <ul className="liste-sans-puces">
            {ListeAdminPartenairesTriees.map((agence) => (
              <li key={agence._id} style={{ listStyleType: "none" }}>
                <div className="form-check d-flex">
                  <input
                    className="checked-item me-2"
                    type="checkbox"
                    value=""
                    id={`flexCheckDefault${agence._id}`}
                    checked={selectedTiers[agence._id] || false}
                    onChange={() => handleLabelClick(agence._id)}
                  />
                  <label
                    className={`souris-pointeur form-check-label ${selectedTiers[agence._id] ? "label-semi-gras" : ""}`}
                    htmlFor={`flexCheckDefault${agence._id}`}
                    onClick={() => handleLabelClick(agence._id)}
                    onMouseEnter={() => {
                      setSelectedTiers((prevSelectedTiers) => ({
                        ...prevSelectedTiers,
                        [agence._id]: true,
                      }));
                    }}
                    onMouseLeave={() => {
                      setSelectedTiers((prevSelectedTiers) => ({
                        ...prevSelectedTiers,
                        [agence._id]: false,
                      }));
                    }}
            
                  >
                    {agence.nom}
                  </label>
                </div>
              </li>
            ))}
          </ul>
        ) : (
          <p>Aucune Agence déclarée.</p>
        )}
      </div>
    </div>
  );

  
}
