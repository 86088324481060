import React, { useEffect, useState, useContext } from "react";
import "../css/Compteurs.css";

import { AuthContext } from "../../contexts/AuthProvider";
import customAxios from "../Utilities/getAxiosToken";

export function CountActions() {
  const [nbActions, setNbActions] = useState(0); // Initialisation à 0
 
  const { userId } = useContext(AuthContext); // Utilisation de seulement userId 

  useEffect(() => {
    const fetchNbActions = async () => {
      if (!userId) return; // Ne faites la requête que si userId est présent

      try {
        const response = await customAxios.get(`/api/actions`);
        const actionsIDs = response.data;
        setNbActions(actionsIDs.length); // Mettre à jour avec le nombre d'actions
      } catch (error) {
        if (error.response && error.response.status === 401) {
          logger.log("CountActions: erreur 401 - Accès non autorisé ou token expiré");
        } else {
          console.error("Erreur lors de la récupération des actions:", error);
          setNbActions(0); // Réinitialiser en cas d'erreur
        }
      }
    };

    fetchNbActions();
  }, [userId]); 

  return (
    <>{userId ? <span className="badge badge-info">{nbActions}</span> : null}</> // Afficher null plutôt que une chaîne vide pour le cas sans utilisateur
  );
}
