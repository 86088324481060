// Logger pour afficher les messages de débogage
// uniquement en mode développement

const isProduction = process.env.NODE_ENV === "production";


const logger = {
  log: (...args) => {
    if (!isProduction) {
      console.log(...args);
    }
  },
  warn: (...args) => {
    if (!isProduction) {
      console.warn(...args);
    }
  },
  error: (...args) => {
    console.error(...args); // Toujours afficher les erreurs
  },
};

// Définir le logger comme global
window.logger = logger;

export default logger;