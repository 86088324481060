import React, { useEffect, useState, useContext } from "react";
import customAxios from "../Utilities/getAxiosToken";
import { AuthContext } from "../../contexts/AuthProvider";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import "./css/Bandeau.css";
import { BandeauPreviEcheancier } from "./BandeauPreviEcheancier";
import { CountAllProjetsClients } from "../Projets/CountAllProjetsClients";
import { CountListeTiers } from "../Tiers/CountListeTiers";
import { CountProjetsTiersByUserId } from "../Projets/CountProjetsTiersByUserId";
import { CountAttentesTiers } from "../Attentes/CountAttentesTiers";
import { CountEtapesAttentes } from "../Etapes/CountEtapesAttentes";
import { BsChevronDown, BsChevronRight } from "react-icons/bs"; // Import des icônes


function BandeauCard({ title, tooltipText, badgeContent, badgeClass, onClick }) {
  return (
    <div className="card card-margin text-white text-center bandeau cursor-pointer" onClick={onClick}>
      <div className="card-header bandeauBorder">{title}</div>
      <div className="bandeau-card-body">
        <OverlayTrigger placement="top" overlay={ <Tooltip  className="custom-tooltip" >{tooltipText}</Tooltip>}>
          <span className={` badge ${badgeClass} compteur`}>{badgeContent}</span>
        </OverlayTrigger>
      </div>
    </div>
  );
}

function bandeauUtilisateurClient(user, attentes,  handleSelectedMenu) {
  return (
    <div className="d-flex justify-content-center my-2">
      <BandeauCard
        title="CLIENTS"
        tooltipText="Mes Clients"
        badgeContent={
          user.userTypeUtilisateur === "AGENCE" && (
           <CountListeTiers typeTiers="CLIENT" AllAgences={false} context="bandeau" />
          )
        }
        badgeClass="badge-info"
        onClick={() => handleSelectedMenu("CLIENTS")}
      />
      
      
      <BandeauCard
        title="PROJETS"
        tooltipText="Mes Projets"
        badgeContent={
          user.userTypeUtilisateur === "AGENCE" && (
            <CountProjetsTiersByUserId cibleProjets="CLIENT" context="bandeau" />
          )
        }
        badgeClass="badge-info"

        onClick={() => handleSelectedMenu("PROJETS CLIENTS")}
      />
      
      <BandeauCard
        title="ATTENTES"
        tooltipText="Attentes en cours"
        badgeContent={attentes}
        badgeClass="bg-enAttente"
        onClick={() => handleSelectedMenu("REQUÊTES")}
      />
      


      <BandeauCard
        title="REQUÊTES"
        tooltipText="Tâches urgentes"
        badgeContent={  <CountEtapesAttentes AllAgences={false} />}
        // badgeClass="bg-rouge"
        onClick={() => handleSelectedMenu("REQUÊTES")}
      />


    </div>
  );
}
function bandeauUtilisateurPartenaire(user, attentes,  handleSelectedMenu) {
  return (
    <div className="d-flex justify-content-center my-2">
      <BandeauCard
        title="CLIENTS"
        tooltipText="Mes Clients"
        badgeContent={
          user.userTypeUtilisateur === "AGENCE" && (
           <CountListeTiers typeTiers="CLIENT" AllAgences={false} context="bandeau" />
          )
        }
        badgeClass="badge-info"
        onClick={() => handleSelectedMenu("CLIENTS")}
      />
      
      
      <BandeauCard
        title="ATTENTES"
        tooltipText="Attentes en cours"
        badgeContent={<CountAttentesTiers  typeTiers={"PARTENAIRE"}/>}
        // badgeClass="bg-enAttente"
        onClick={() => handleSelectedMenu("ATTENTES")}
      />
      

      <BandeauCard
        title="DÉPÔT EXPRESS"
        tooltipText="Dépôt de documents"
        badgeContent={<span>GO</span>} // Texte personnalisé "GO"
        badgeClass="badge-info" // Optionnel, ajoutez une classe CSS si besoin
        onClick={() => handleSelectedMenu("DÉPÔT EXPRESS")}
      />



    </div>
  );
}

function bandeauUtilisateurAgence(user, attentes, recus, handleSelectedMenu) {
  return (
    <div className="d-flex justify-content-center my-2">
      <BandeauCard
        title="CLIENTS"
        tooltipText="Mes Clients"
        badgeContent={
          user.userTypeUtilisateur === "AGENCE" && (
           <CountListeTiers typeTiers="CLIENT" AllAgences={false} context="bandeau" />
          )
        }
        badgeClass="badge-info"
        onClick={() => handleSelectedMenu("CLIENTS")}
      />
      
      <BandeauCard
        title="PARTENAIRES"
        tooltipText="Les Partenaires"
        badgeContent={
          user.userTypeUtilisateur === "AGENCE" && (
            <CountListeTiers typeTiers="PARTENAIRE" AllAgences={false} context="bandeau" />
          )
        }
        badgeClass="badge-info"
        onClick={() => handleSelectedMenu("PARTENAIRES")}
      />
      
      <BandeauCard
        title="PROJETS"
        tooltipText="Mes Projets"
        badgeContent={
          user.userTypeUtilisateur === "AGENCE" && (
            <CountProjetsTiersByUserId cibleProjets="CLIENT" context="bandeau" />
          )
        }
        badgeClass="badge-info"

        onClick={() => handleSelectedMenu("PROJETS CLIENTS")}
      />
      
      <BandeauCard
        title="ATTENTES"
        tooltipText="Attentes en cours"
        badgeContent={attentes}
        badgeClass="bg-enAttente"
        onClick={() => handleSelectedMenu("REQUÊTES")}
      />
      
      <BandeauCard
        title="REÇUS"
        tooltipText="À traiter"
        badgeContent={recus}
        badgeClass="bg-aRépondu"
        onClick={() => handleSelectedMenu("REQUÊTES")}
      />

      <BandeauCard
        title="REQUÊTES"
        tooltipText="Tâches urgentes"
        badgeContent={  <CountEtapesAttentes AllAgences={false} />}
        // badgeClass="bg-rouge"
        onClick={() => handleSelectedMenu("REQUÊTES")}
      />

      <BandeauCard
        title="PREVISIONNELS"
        tooltipText="Perspectives de vente"
        badgeContent={<BandeauPreviEcheancier typeSynthese="PRÉVISIONNELS" AllAgences={false} />}
        badgeClass="bg-vert"
        onClick={() => handleSelectedMenu("SYNTHÈSES")}
      />

      <BandeauCard
        title="ÉCHÉANCIER"
        tooltipText="Facturation planifiée"
        badgeContent={<BandeauPreviEcheancier typeSynthese="ÉCHÉANCIERS" AllAgences={false} />}
        badgeClass="bg-vert"
        onClick={() => handleSelectedMenu("SYNTHÈSES")}
      />
    </div>
  );
}
function bandeauManagerAgence(user, attentes, recus, handleSelectedMenu) {
  return (
    <div className="d-flex justify-content-center mb-4">
      <BandeauCard
        title="ALL CLIENTS"
        tooltipText="Tous les Clients"
        badgeContent={
          user.userTypeUtilisateur === "AGENCE" && (
            <CountListeTiers typeTiers="CLIENT" AllAgences={true} context="bandeau"/>
          )
        }
        badgeClass="badge-info"
        onClick={() => handleSelectedMenu("ALL CLIENTS")}
      />

      <BandeauCard
        title="ALL PARTENAIRES"
        tooltipText="Tous les Partenaires"
        badgeContent={
          user.userTypeUtilisateur === "AGENCE" && (
            <CountListeTiers typeTiers="PARTENAIRE" AllAgences={false} context="bandeau"/>
          )
        }
        badgeClass="badge-info"
        onClick={() => handleSelectedMenu("ALL PARTENAIRES")}
      />

      <BandeauCard
        title="ALL PROJETS"
        tooltipText="Tous les Projets"
        badgeContent={
          user.userTypeUtilisateur === "AGENCE" && (
            <CountAllProjetsClients />
          )
        }
        badgeClass="badge-info"
        onClick={() => handleSelectedMenu("ALL PROJETS")}
      />

      <BandeauCard
        title="ALL PRESTATAIRES"
        tooltipText="Tous les Prestataires"
        badgeContent={
          user.userTypeUtilisateur === "AGENCE" && (
            <CountListeTiers typeTiers="PRESTATAIRE" AllAgences={false} context="bandeau"/>
          )
        }
        badgeClass="badge-info"
        onClick={() => handleSelectedMenu("ALL PRESTATAIRES")}
      />

      <BandeauCard
        title="ALL AGENCE"
        tooltipText="Pour accéder aux projets de l'agence"
        badgeContent={
          user.userTypeUtilisateur === "AGENCE" && (
            <CountListeTiers typeTiers="AGENCE" AllAgences={false} context="bandeau"/>
          )
        }
        badgeClass="badge-info"
        onClick={() => handleSelectedMenu("PROJET AGENCE")}
      />

      <BandeauCard
        title="ALL REQUÊTES"
        tooltipText="Toutes les attentes"
        badgeContent={<CountEtapesAttentes AllAgences={true} />}
        badgeClass="badge-warning"
        onClick={() => handleSelectedMenu("ALL REQ")}
      />

      <BandeauCard
        title="PREVISIONNELS"
        tooltipText="Perspectives de vente"
        badgeContent={
          <BandeauPreviEcheancier typeSynthese="PRÉVISIONNELS" AllAgences={true} />
        }
        badgeClass="bg-vert"
        onClick={() => handleSelectedMenu("ALL SYNTHÈSES")}
      />

      <BandeauCard
        title="ÉCHÉANCIER"
        tooltipText="Facturation planifiée"
        badgeContent={
          <BandeauPreviEcheancier typeSynthese="ÉCHÉANCIERS" AllAgences={true} />
        }
        badgeClass="bg-vert"
        onClick={() => handleSelectedMenu("ALL SYNTHÈSES")}
      />
    </div>
  );
}


export function Bandeau() {
  const [attentes, setAttentes] = useState(0);
  const [recus, setRecus] = useState(0);
  const { user, handleSelectedMenu, isManager } = useContext(AuthContext);
  const [isAdminBandeauVisible, setIsAdminBandeauVisible] = useState(false); // État pour le toggle
 


  useEffect(() => {
    if (!user?.userId) return;
  
    const fetchAttentesCounts = async () => {
      try {
        let response;
        if (user.userTypeUtilisateur === "ADMINISTRATEUR") {
          response = await customAxios.get("/api/etapes/statusCountsGeneral/");
        } else {
          const cibleProjets =
            user.userTypeUtilisateur === "AGENCE" ? "CLIENT" : "PARTENAIRE";
          response = await customAxios.get(
            `/api/etapes/statusCounts/${user.userId}/${cibleProjets}`
          );
        }
        const { enAttenteCount, aReponduCount } = response.data;
        logger.log("Bandeau fetchAttentesCounts", enAttenteCount, aReponduCount);
        
        setAttentes(enAttenteCount);
        setRecus(aReponduCount);
      } catch (error) {
        console.error("Erreur lors de la récupération des documents", error);
      }
    };
  
    fetchAttentesCounts();
  }, [user?.userId, user?.userTypeUtilisateur]);


  return (
    <>
      <div className={isManager ? "mb-2" : "mb-4"}>
        {user.userTypeUtilisateur === "CLIENT"
          ? bandeauUtilisateurClient(user, attentes, handleSelectedMenu)
          : user.userTypeUtilisateur === "PARTENAIRE"
          ? bandeauUtilisateurPartenaire(user, attentes, handleSelectedMenu)
          : bandeauUtilisateurAgence(user, attentes, recus, handleSelectedMenu)}
      </div>

      {isManager && (
        <div className="d-flex justify-content-start align-items-center mb-2">
          <OverlayTrigger
            placement="right"
            overlay={
              <Tooltip>
                {isAdminBandeauVisible
                  ? "Masquer le Bandeau Manager"
                  : "Afficher le Bandeau Manager"}
              </Tooltip>
            }
          >
            <div
              className="triangle-icon cursor-pointer"
              onClick={() => setIsAdminBandeauVisible(!isAdminBandeauVisible)}
            >
              {isAdminBandeauVisible ? <BsChevronDown size={20} /> : <BsChevronRight size={20} />}
            </div>
          </OverlayTrigger>
        </div>
      )}

      {isManager &&
        isAdminBandeauVisible &&
        bandeauManagerAgence(user, attentes, recus, handleSelectedMenu)}
    </>
  );
  // return (
  //   <>
  //     {/* Ajout de marge conditionnelle */}
  //     <div className={isManager ? "mb-2" : "mb-4"}>
  //       {bandeauUtilisateurAgence(user, attentes, recus, handleSelectedMenu)}
  //     </div>

  //     {isManager && (
  //       <div className="d-flex justify-content-start align-items-center mb-2">
  //         <OverlayTrigger
  //           placement="right"
  //           overlay={
  //             <Tooltip>
  //               {isAdminBandeauVisible
  //                 ? "Masquer le Bandeau Manager"
  //                 : "Afficher le Bandeau Manager"}
  //             </Tooltip>
  //           }
  //         >
  //           <div
  //             className="triangle-icon cursor-pointer"
  //             onClick={() => setIsAdminBandeauVisible(!isAdminBandeauVisible)}
  //           >
  //             {isAdminBandeauVisible ? (
  //               <BsChevronDown size={20} />
  //             ) : (
  //               <BsChevronRight size={20} />
  //             )}
  //           </div>
  //         </OverlayTrigger>
  //       </div>
  //     )}

  //     {isManager &&
  //       isAdminBandeauVisible &&
  //       bandeauManagerAgence(user, attentes, recus, handleSelectedMenu)}
  //   </>
  // );

}