import React, { useEffect, useState, useContext } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "./css/ListeTiers.css";
import "./../css/Global.css";

import { AuthContext } from "../../contexts/AuthProvider";
import customAxios from "../Utilities/getAxiosToken";
import { OverlayTrigger, Tooltip } from "react-bootstrap";

// Composant ListeAllTiersByAgence
export function ListeAllTiersByAgence({ typeTiers }) {
  const [listeTiers, setListeTiers] = useState([]);
  const [selectedTiers, setSelectedTiers] = useState({});
  const { userId, handleTiersId, agenceId } = useContext(AuthContext);

  useEffect(() => {
    const fetchListeTiers = async () => {
      try {
        if (typeTiers && userId && agenceId) {
          const responsedutiers = await customAxios.get(
            `/api/tiers/byTiersIdAndTypeTiers/${agenceId}/${typeTiers}`
          );

          if (Array.isArray(responsedutiers.data.tiersAttaches)) {
            const listeTiersTries = responsedutiers.data.tiersAttaches.sort((a, b) =>
              a.nom.localeCompare(b.nom)
            );
            setListeTiers(listeTiersTries);
          }
        }
      } catch (error) {
        console.error(error);
      }
    };

    fetchListeTiers();
  }, [userId, typeTiers, agenceId]);

  const handleLabelClick = (tiersId) => {
    handleTiersId(tiersId);
  };

  const nbRecords = listeTiers.length;

  return (
    <div className="fenetre-liste-tiers cadre-zone2">
      {agenceId ? (
        <div>
          {nbRecords > 0 ? (
            <ul className="liste-sans-puces">
              {listeTiers.map((monTiers) => (
                <li key={monTiers._id} style={{ listStyleType: "none" }}>
                  <div className="form-check d-flex ms-1">
                    <input
                      className="checked-item me-2"
                      type="checkbox"
                      id={`flexCheckDefault${monTiers._id}`}
                      checked={selectedTiers[monTiers._id] || false}
                      onChange={() => handleLabelClick(monTiers._id)}
                    />
                    <OverlayTrigger
                      placement="top"
                      overlay={
                        <Tooltip id={`tooltip-${monTiers._id}`}>
                          Cliquez pour avoir les détails
                        </Tooltip>
                      }
                    >
                      <label
                        className={`form-check-label ${
                          monTiers.labelHovered ? "label-semi-gras" : ""
                        }`}
                        htmlFor={`flexCheckDefault${monTiers._id}`}
                        onClick={() => handleLabelClick(monTiers._id)}
                        onMouseEnter={() => {
                          setListeTiers((prevTiers) =>
                            prevTiers.map((prev) =>
                              prev._id === monTiers._id
                                ? { ...prev, labelHovered: true }
                                : prev
                            )
                          );
                        }}
                        onMouseLeave={() => {
                          setListeTiers((prevTiers) =>
                            prevTiers.map((prev) =>
                              prev._id === monTiers._id
                                ? { ...prev, labelHovered: false }
                                : prev
                            )
                          );
                        }}
                      >
                        {monTiers.nom} {monTiers.prenom}
                      </label>
                    </OverlayTrigger>
                  </div>
                </li>
              ))}
            </ul>
          ) : (
            <p>Aucun tiers à afficher.</p>
          )}
        </div>
      ) : (
        <p>Pas Connecté</p>
      )}
    </div>
  );
}