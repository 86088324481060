import React, { useEffect, useState, useContext, useCallback } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "../css/Global.css";
import customAxios from "../Utilities/getAxiosToken";

import { AuthContext } from "../../contexts/AuthProvider";

import { Tooltip } from "react-tooltip";
import io from 'socket.io-client';
import Cookies from "js-cookie";
import useGetModeles from "../Utilities/GetModeles";

export function CountSansAttentesProjetsByModeles({AllAgences, ModeleId}) {
  const { fetchSansAttentesProjetByUserIdAndModeleId }  = useGetModeles(); 
 
  const { userId, agenceId } = useContext(AuthContext);
  const [nbModeles, setNbModeles] = useState(0);

  const fetchProjetsSansAttentes = useCallback(async () => {
    if (!userId  || !agenceId) return;
    try {
      if (AllAgences && ModeleId) {
        const url = `/api/projets/projetsSansAttentes`;
        // const modeleIds = [ModeleId].filter(Boolean);

        const payload = { modeleIds: [ModeleId], userId: userId, 
          etatProjet:"ouvert" // C'est l'état du projet qui est à vérifier pour ce userId
        };
        
        const response = await customAxios.post(url, payload);
        logger.log("CountSansAttentesProjetsByModeles: response", response.data);
        const projetsUniques = new Set(response.data.map(projet => projet.projetId));
        setNbModeles(projetsUniques.size);
      } else {
        const projets = await fetchSansAttentesProjetByUserIdAndModeleId(userId, ModeleId);
        const projetsUniques = new Set(projets.map(projet => projet.projetId));
        setNbModeles(projetsUniques.size);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
      setNbModeles(0); // Réinitialiser en cas d'erreur
    }
  }, [userId,  agenceId, AllAgences, ModeleId]);

  useEffect(() => {
    
    fetchProjetsSansAttentes();

      logger.log("CountSansAttentesProjetsByModeles: const socket = io()", process.env.REACT_APP_SOCKET_URL);

    const socket = io(process.env.REACT_APP_SOCKET_URL, {
      path: '/ws',
      auth: {
        token: Cookies.get("userTokenAcces")
      }
    });
    socket.on('nouveauProjet', fetchProjetsSansAttentes);
    socket.on('updateProjet', fetchProjetsSansAttentes);

    return () => {
      logger.log("CountSansAttentesProjetsByModeles: Closing WebSocket");
      socket.off('nouveauProjet', fetchProjetsSansAttentes);
      socket.off('updateProjet', fetchProjetsSansAttentes);
      socket.disconnect();
  };

  }, [fetchProjetsSansAttentes]);

  return (
    <>
      {nbModeles > 0 && (
        <span className="badge bg-rouge" data-tooltip-id="TooltipCompteurProjetsSansAttentes"
          data-tooltip-content="Nombre de Projets sans attentes">
          {nbModeles}
        </span>
      )}
      <>
        <Tooltip id="TooltipCompteurProjetsSansAttentes" place="top" effect="solid" className="custom-tooltip" />
      </>
    </>
  );
}
