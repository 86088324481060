import React, { useEffect, useState, useContext, useCallback } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "./../css/Global.css";
import { AuthContext } from "../../contexts/AuthProvider";
import { Tooltip } from "react-tooltip";
import io from 'socket.io-client'; 
import Cookies from "js-cookie";
import useFetchHelpers from "../Utilities/GetModeles";

export function CountProjetsByModeles({ AllAgences, ModeleId }) {
 
  const { userId, agenceId } = useContext(AuthContext);
  const [nbModeles, setNbModeles] = useState(0);
  const {fetchProjetModeleId, fetchProjetByUserIdAndModeleId }  = useFetchHelpers(); 

  const fetchProjets = useCallback(async () => {
    if (!userId  || !agenceId) return;

    try {
      if (AllAgences) {
        const modeleIds = [ModeleId].filter(Boolean);
        logger.log("CountProjetsByModeles: modeleIds ",modeleIds);
        if (modeleIds.length > 0) {
          logger.log("CountProjetsByModeles: modeleIds ",modeleIds);
          // On récupère tous les projets ou tous les utilisateurs de accesProjet ont un statut "ouvert"
          const projets = await fetchProjetModeleId(modeleIds);
          logger.log("CountProjetsByModeles: fetchProjets ",projets ? projets.length : 0);
          setNbModeles(projets ? projets.length : 0);
        }
      } else {
        const projets = await fetchProjetByUserIdAndModeleId(userId, ModeleId);
        setNbModeles(projets ? projets.length : 0);
      }
    } catch (error) {
      console.error("Erreur lors de la récupération des projets:", error);
      setNbModeles(0);
    }
  }, [userId,  agenceId, AllAgences, ModeleId]);

  useEffect(() => {
    

    fetchProjets();
    
    logger.log("CountProjetsByModeles: const socket = io()", process.env.REACT_APP_SOCKET_URL);

    const socket = io(process.env.REACT_APP_SOCKET_URL, {
      path: '/ws',
      auth: {
        token: Cookies.get("userTokenAcces")
      }
    });
    const handleNewProject = data => {
      logger.log("WebSockets - Nouveau Projet:", data);
      fetchProjets();
    };
    socket.on('nouveauProjet', handleNewProject);

    return () => {
      logger.log("CountProjetsByModeles: Closing WebSocket");
      socket.off('nouveauProjet',fetchProjets );
      socket.disconnect();
  };

  }, [fetchProjets]);

  return (
    <>
      {nbModeles > 0 && (
        <span 
          data-tooltip-id="TooltipCompteurProjets"
          data-tooltip-content="Nombre de Projets attachés à ce modèle"
          className="badge bg-vert"
        >
          {nbModeles}
        </span>
      )}
      
      <>
        <Tooltip
          id="TooltipCompteurProjets"
          place="top"
          effect="solid"
          className="custom-tooltip"
        />
      </>
    </>
  );
}
