
import React, { useEffect, useState, useContext } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import { AuthContext } from "../../contexts/AuthProvider";
import customAxios from "../Utilities/getAxiosToken";

export function CountAdminPartenaires({ typeTiers }) {
  const [count, setCount] = useState(0);
  const { handleLogout } = useContext(AuthContext);

  useEffect(() => {
    const fetchCountAdminPartenaires = async () => {
      try {
        const response = await customAxios.get(`/api/tiers/typeTiers/${typeTiers}`);
        logger.log("CountAdminPartenaires fetchCountAdminPartenaires", typeTiers, response.data);
        const tousLesTiers = response.data.tousLesTiers;
        setCount(tousLesTiers.length);
      } catch (error) {
        if (error.response && error.response.status === 401) {
          logger.log(`Erreur 401 - pas d'accès, token expiré.`);
          handleLogout();
        } else {
          console.error("Erreur lors de la récupération des partenaires:", error);
        }
      }
    };

    fetchCountAdminPartenaires();
  }, [typeTiers, handleLogout]);

  return (
    <span className="badge badge-info">{count}</span>
  );
}