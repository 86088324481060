import React, { useEffect, useState, useContext, useCallback } from "react";
import customAxios from "../Utilities/getAxiosToken";

import { AuthContext } from "../../contexts/AuthProvider";
import io from 'socket.io-client'; 
import Cookies from "js-cookie";

// typeTiers = "AGENCE"
export function CountAdminAgences(props) {
  const [CountAdminAgences, setCountAdminAgences] = useState([]);
  const { typeTiers } = props;
 
  const { userId } = useContext(AuthContext);

  const fetchListeAdminAgences = useCallback(async () => {
    logger.log(`CountAdminAgences: DEBUT`);
    try {
      // logger.log(`CountAdminAgences:  fetchListeTiers`);
      if (typeTiers && userId) {

        const response = await customAxios.get(
          `/api/tiers/CountAdminAgences/${typeTiers}`
        );

        logger.log(
          `CountAdminAgences nb ${typeTiers}: ${response.data.nbAgences}`
        );
        setCountAdminAgences(response.data.nbAgences);
      }
      // logger.log(`CountAdminAgences: FIN `);
    } catch (error) {
      console.error(error);
    }
    // logger.log(`CountAdminAgences: FIN`);
  }, []);

  useEffect(() => {
    

    fetchListeAdminAgences();

    logger.log("CountAdminAgences: const socket = io()",process.env.REACT_APP_SOCKET_URL);

    const socket = io(process.env.REACT_APP_SOCKET_URL, {
      path: '/ws',
      auth: {
        token: Cookies.get("userTokenAcces")
      }
    });
    // Écoute pour un événement spécifique, exemple: 'updateTiers'
    socket.on('updateTiers', (data) => {
      logger.log( "CountAdminAgences: webSockets updateTiers", data);
      fetchListeAdminAgences(); // Rafraîchit les données chaque fois que l'événement est reçu
    });

    // Nettoyage de la connexion à la fermeture du composant
    return () => {
      logger.log("CountAdminAgences: Closing WebSocket");
      socket.off('updateTiers', fetchListeAdminAgences);
      socket.disconnect();
    };

  }, []);

  return (
    <span className="badge badge-info">{CountAdminAgences}</span>
  );
}
