
import React, { useContext, useState, useEffect } from "react";

import "./../css/Global.css";


import { AuthContext } from "../../contexts/AuthProvider";
import customAxios from "../Utilities/getAxiosToken";
import { useProjet } from '../../contexts/ProjetContext';
//
// utilisateur Sélectionné
//
export function CreateurByTiersId() {
  const { utilisateurId, handleLogout, tiersId } =
    useContext(AuthContext);

  const { projetData } = useProjet();
 
  const [createur, setCreateur] = useState({});
  const [isLoading, setIsLoading] = useState(true);

  logger.log("CreateurByTiersId Main tiersId", tiersId);

  const fetchCreateurByTiersId = async () => {
    try {
      if (tiersId) {
        logger.log("CreateurByTiersId: tiersId:", tiersId);
        const response = await customAxios.get(
          `/api/tiers/${tiersId}`
        );

        logger.log("CreateurByTiersId: response:", response.data);
        const createurId = response.data.createur;
        if (createurId) {
          logger.log(
            "CreateurByTiersId fetchCreateurByTiersId createurId",
            createurId
          );
          const response = await customAxios.get(
            `/api/utilisateurs/${createurId}?isActif=true`
          );
          logger.log(
            "UtilisateurById fetchUtilisateurByid utilisateurId",
            utilisateurId,
            "response.data",
            response.data,
            "Taille",
            response.data.count
          );
          // setUtilisateur(response.data); // On récupère le nom du Projet

          setCreateur(response.data); // On récupère le nom du Projet
          setIsLoading(false);
        }
      }
    } catch (error) {
      if (error.response && error.response.status === 401) {
        logger.log(`CreateurByTiersId: erreur 401 pas d'accès Token expiré`);
        // Déconnexion de l'utilisateur et redirection vers la page de login
        handleLogout();
      } else {
        console.error(error);
      }
    }
  };

  useEffect(() => {
    setIsLoading(true);
    logger.log("CreateurByTiersId useEffect tiersId", tiersId);
    fetchCreateurByTiersId();
  }, [projetData, tiersId]);

  return (
    <div className="cadre-zone4 center-content">
      {isLoading ? null : (
        <>
          {createur.prenom} {createur.nom}
        </>
      )}
    </div>
  );
}
