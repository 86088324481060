import React, { useState, useEffect, useContext } from "react";

import customAxios from "../Utilities/getAxiosToken";



export function CheckServeurStatus() {
  const [serverStatus, setServerStatus] = useState({ state: null, lastMaintenanceDate: null });
 

  const fetchServerStatus = async () => {
    try {
      const response = await customAxios.get(
        `/api/serveur/status`
      );
      // logger.log("Server Status: ", response.data );
      // logger.log("Server Status: ", response.data.state,response.data.lastMaintenanceDate );
      setServerStatus(response.data);
    } catch (error) {
      console.error("Erreur lors de la requête au serveur :", error);
      setServerStatus({ state: "offline", lastMaintenanceDate: null });
    }
  };

  useEffect(() => {
    fetchServerStatus(); // Fetch server status on component mount

    const intervalId = setInterval(() => {
      fetchServerStatus(); // Fetch server status every 10 seconds
    }, 10000);

    return () => {
      clearInterval(intervalId); // Clean up the interval on component unmount
    };
  }, []);

  const getStatusColor = () => {
    if (serverStatus.state === "running") {
      return "green";
    } else if (serverStatus.state === "maintenance") {
      return "orange blinking-orange"; // Add the "blinking-orange" class for animation
    } else {
      return "red";
    }
  };

  return (
    <div>
      <div
        style={{
          width: "12px",
          height: "12px",
          marginRight: "20px",
          borderRadius: "50%",
          backgroundColor: getStatusColor(),
          animation: serverStatus.state === "maintenance" ? "blink 1s infinite" : "none" // Conditional animation
        }}
        title={
          serverStatus.state === "running"
            ? `Serveur OK (Dernière maintenance : ${serverStatus.lastMaintenanceDate})`
            : serverStatus.state === "maintenance"
            ? `Serveur en maintenance (Dernière maintenance : ${serverStatus.lastMaintenanceDate})`
            : "Serveur HS"
        }
      />
      <style>
        {`
          @keyframes blink {
            0% {
              background-color: orange;
            }
            50% {
              background-color: transparent;
            }
            100% {
              background-color: orange;
            }
          }
        `}
      </style>
    </div>
  );
}

