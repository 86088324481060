import React, { useState } from "react";
import axios from "axios";
import { fetchNafLibelle, fetchLegalForm } from "../Utilities/fetchInsee";

export function Siret() {
  const [searchType, setSearchType] = useState("siret");
  const [identifier, setIdentifier] = useState("");
  const [companyInfo, setCompanyInfo] = useState(null);
  const [nafLibelle, setNafLibelle] = useState("");
  const [legalForm, setLegalForm] = useState(""); // État pour la forme juridique
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const fetchCompanyInfo = async (identifier, type) => {
    setLoading(true);
    setError(null);

    try {
      let siret = identifier;

      if (type === "siren") {
        const responseSiren = await axios.get(`/proxy/siren/${identifier}`);
        const periodes = responseSiren.data.uniteLegale?.periodesUniteLegale;
        if (periodes && periodes.length > 0) {
          const nic = periodes[0].nicSiegeUniteLegale;
          siret = identifier + nic;
        }
      }

      const responseSiret = await axios.get(`/proxy/siret/${siret}`);
      setCompanyInfo(responseSiret.data);

      // Récupérer le libellé du code NAF
      const codeNaf = responseSiret.data.etablissement?.uniteLegale?.activitePrincipaleUniteLegale;
      if (codeNaf) {
        try {
          const libelle = await fetchNafLibelle(codeNaf);
          setNafLibelle(libelle);
        } catch (err) {
          console.error("Erreur lors de la récupération du libellé NAF:", err);
        }
      }

      // Récupérer la forme juridique
      const categoryCode = responseSiret.data.etablissement?.uniteLegale?.categorieJuridiqueUniteLegale;
      if (categoryCode) {
        try {
          const form = await fetchLegalForm(categoryCode);
          setLegalForm(form);
        } catch (err) {
          console.error("Erreur lors de la récupération de la forme juridique:", err);
        }
      }
    } catch (err) {
      handleError(err);
    } finally {
      setLoading(false);
    }
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    if (
      (searchType === "siret" && (identifier.length !== 14 || isNaN(identifier))) ||
      (searchType === "siren" && (identifier.length !== 9 || isNaN(identifier)))
    ) {
      setError(`Le numéro ${searchType.toUpperCase()} doit contenir ${searchType === "siret" ? "14" : "9"} chiffres.`);
      return;
    }
    fetchCompanyInfo(identifier, searchType);
  };

  const handleError = (err) => {
    if (err.response) {
      switch (err.response.status) {
        case 401:
          setError("Jeton d'accès invalide ou expiré.");
          break;
        case 403:
          setError("Droits insuffisants pour accéder à cette ressource.");
          break;
        case 404:
          setError("Entreprise non trouvée.");
          break;
        default:
          setError("Une erreur inconnue est survenue.");
      }
    } else {
      setError("Erreur réseau ou serveur.");
    }
  };

  return (
    <div>
      <h4>Recherche d'entreprise par {searchType.toUpperCase()}</h4>
      <form onSubmit={handleSubmit}>
        <label>
          Type de recherche:
          <select
            value={searchType}
            onChange={(e) => setSearchType(e.target.value)}
          >
            <option value="siret">SIRET</option>
            <option value="siren">SIREN</option>
          </select>
        </label>
        <label>
          Numéro {searchType.toUpperCase()}:
          <input
            type="text"
            value={identifier}
            onChange={(e) => setIdentifier(e.target.value)}
            required
          />
        </label>
        <button type="submit">Rechercher</button>
      </form>
      {loading && <p>Chargement...</p>}
      {error && <p style={{ color: "red" }}>{error}</p>}
      {companyInfo && (
        <div>
          <h5>Informations de l'entreprise</h5>
          <p><strong>SIRET:</strong> {companyInfo.etablissement?.siret || "N/A"}</p>
          <p><strong>SIREN:</strong> {companyInfo.etablissement?.siren || "N/A"}</p>
          <p><strong>Dénomination:</strong> 
            {companyInfo.etablissement?.uniteLegale?.denominationUniteLegale || 
             companyInfo.etablissement?.uniteLegale?.denominationUsuelle1UniteLegale || 
             companyInfo.etablissement?.uniteLegale?.nomUniteLegale || "N/A"}
          </p>
          <p><strong>Adresse:</strong> 
            {companyInfo.etablissement?.adresseEtablissement?.numeroVoieEtablissement || ""} 
            {companyInfo.etablissement?.adresseEtablissement?.libelleVoieEtablissement || ""}, 
            {companyInfo.etablissement?.adresseEtablissement?.codePostalEtablissement || "N/A"} 
            {companyInfo.etablissement?.adresseEtablissement?.libelleCommuneEtablissement || "N/A"}
          </p>
          <p><strong>Date de Création:</strong> {companyInfo.etablissement?.dateCreationEtablissement || "N/A"}</p>
          <p><strong>Code NAF:</strong> {companyInfo.etablissement?.uniteLegale?.activitePrincipaleUniteLegale || "N/A"}</p>
          <p><strong>Libellé NAF:</strong> {nafLibelle || "N/A"}</p>
          <p><strong>Forme Juridique:</strong> {legalForm || "N/A"}</p>
          <p><strong>Capital Social:</strong> {companyInfo.etablissement?.uniteLegale?.capitalSocialUniteLegale || "Non disponible"}</p>
        </div>
      )}
    </div>
  );
}