import React, { useEffect, useState, useContext } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "./../css/Global.css";
import "./css/ProjetNote.css";

import customAxios from "../Utilities/getAxiosToken";
import { AuthContext } from "../../contexts/AuthProvider";

export function ProjetNote() {

  const [noteProjet, setNoteProjet] = useState("");
 
  const {  projetId } = useContext(AuthContext);

  const handleInputChange = (e) => {
    setNoteProjet(e.target.value);
  };

  const updateProjetNote = async (nouvelleNote) => {
    try {
      logger.log(`Mise à jour de la note du projet ${projetId} avec la nouvelle note : ${nouvelleNote}`);
      
      // Utilisez la méthode PATCH ou PUT pour mettre à jour la note du projet sur le serveur
      await customAxios.patch(
        `/api/projets/alone/${projetId}`,
        {
          note: nouvelleNote,
        }
      );

      logger.log(`Note du projet ${projetId} mise à jour avec la nouvelle note : ${nouvelleNote}`);
    } catch (error) {
      console.error("Erreur lors de la mise à jour de la note du projet :", error);
    }
  };

  useEffect(() => {
    const fetchProjetNote = async () => {
      try {
        if (projetId) {
          const response = await customAxios.get(
            `/api/projets/alone/${projetId}`
          );

          logger.log("ProjetNote: ", response.data.note);

          setNoteProjet(response.data.note); // On récupère la note du Projet
        }
      } catch (error) {
        console.error(error);
      }
    };
    setNoteProjet("");
    fetchProjetNote();
  }, [projetId]);

  return (
    <span>
      {projetId && (
        <div className="cadre-zone3-note"
        >
          <textarea
            value={noteProjet}
            className="note-input"
            placeholder="Entrez vos notes ici ..."
            onChange={handleInputChange}
            onBlur={() => updateProjetNote(noteProjet)}
        />
        </div>
      )}
    </span>
  );
}
