import axios from 'axios';

const axiosInstance = axios.create({
  timeout: 60000, // Timeout en millisecondes (10 secondes)
});

/**
 * Délai entre les appels pour respecter les limites d'API.
 * @param {number} ms - Durée en millisecondes.
 */
const delay = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

/**
 * Fonction avec retry pour gérer les erreurs 429 et autres erreurs temporaires.
 * @param {Function} fn - Fonction à exécuter avec retry.
 * @param {number} retries - Nombre maximum de tentatives.
 * @param {number} delayMs - Délai initial entre les tentatives.
 */
const withRetry = async (fn, retries = 3, delayMs = 10000) => {
  for (let i = 0; i < retries; i++) {
    try {
      return await fn();
    } catch (error) {
      if (error.response?.status === 429 && i < retries - 1) {
        console.warn(`Trop de requêtes. Attente de ${delayMs}ms avant une nouvelle tentative...`);
        await delay(delayMs);
        delayMs *= 2; // Augmente le délai de façon exponentielle
      } else {
        throw error; // Rejette l'erreur si autre ou si le nombre de tentatives est dépassé
      }
    }
  }
};


const fetchCompanyInfoBySiret = async (siret, setSirenValid, setError, setCompanyInfo, setValue) => {
  setError(null);
  setSirenValid(null);
  try {
    // Utilisation du proxy pour interroger l'API INSEE
    const responseSiret = await axios.get(`/proxy/siret/${siret}`);

    logger.log("fetchCompanyInfoBySiret", responseSiret);
    setCompanyInfo(responseSiret.data);
    const etablissement = responseSiret.data.etablissement;

    if (etablissement) {
      const uniteLegale = etablissement.uniteLegale;
      const denomination =
        uniteLegale.denominationUniteLegale ||
        etablissement.periodesEtablissement[0]?.denominationUsuelleEtablissement ||
        uniteLegale.nomUniteLegale || "N/A";

      // Mise à jour des champs avec les valeurs récupérées
      setValue('raisonSociale', denomination);

      setValue(
        'adresse',
        [
          etablissement.adresseEtablissement.numeroVoieEtablissement, 
          etablissement.adresseEtablissement.typeVoieEtablissement, 
          etablissement.adresseEtablissement.libelleVoieEtablissement
        ]
        .filter(Boolean) // Filtrer les champs non définis ou null
        .join(' ') // Joindre les champs avec un espace
      );
      setValue('cp', etablissement.adresseEtablissement.codePostalEtablissement);
      setValue('ville', etablissement.adresseEtablissement.libelleCommuneEtablissement);

      const capitalSocial = uniteLegale.capitalSocialUniteLegale || "N/A"; // Récupération du capital social
      const codeNaf = uniteLegale.activitePrincipaleUniteLegale || "N/A"; // Code NAF
      const trancheEffectifs = uniteLegale.trancheEffectifsUniteLegale || "N/A";
      const categorieJuridique = uniteLegale.categorieJuridiqueUniteLegale || "N/A";

      setValue('capitalSocial', capitalSocial); // Mise à jour du capital social
      setValue('codeNaf', codeNaf);
      setValue('trancheEffectifs', trancheEffectifs);
      setValue('categorieJuridique', categorieJuridique);

      // Récupérer le libellé du code NAF
      if (codeNaf !== "N/A") {
        await fetchNafLibelle(codeNaf, setValue, setError);
      }

      // Récupérer la forme juridique
      if (categorieJuridique !== "N/A") {
        const categoryCode = categorieJuridique; // Utiliser la catégorie juridique directement
        await fetchLegalForm(categoryCode, setValue);
      }

      setSirenValid(true);
    }
  } catch (err) {
    console.error("Erreur fetchCompanyInfoBySiret:", err);
    setError("Erreur lors de la récupération des informations de l'entreprise. Veuillez vérifier le numéro SIRET et réessayer.");
    setSirenValid(false);
  }
};

const fetchCompanyInfoBySiren = async (siren, setSirenValid, setError, setCompanyInfo, setValue) => {
  setError(null);
  setSirenValid(null);
  try {
    // Utilisation du proxy pour interroger l'API INSEE
    const responseSiren = await axios.get(`/proxy/siren/${siren}`);

    logger.log("fetchCompanyInfoBySiren", responseSiren);

    const uniteLegale = responseSiren.data.uniteLegale;
    if (uniteLegale && uniteLegale.periodesUniteLegale.length > 0) {
      const periode = uniteLegale.periodesUniteLegale[0];
      const nic = periode.nicSiegeUniteLegale;
      const siret = siren + nic;

      // Appel à fetchCompanyInfoBySiret avec le SIRET généré
      await fetchCompanyInfoBySiret(siret, setSirenValid, setError, setCompanyInfo, setValue);
    } else {
      setError("Aucune information trouvée pour ce numéro SIREN.");
      setSirenValid(false);
    }
  } catch (err) {
    console.error("Erreur fetchCompanyInfoBySiren:", err);
    setError("Erreur lors de la récupération des informations de l'entreprise. Veuillez vérifier le numéro SIREN et réessayer.");
    setSirenValid(false);
  }
};

// Fonction pour récupérer la forme juridique
const fetchLegalForm = async (categoryCode, setValue) => {
  try {
    const responseLegalForm = await axios.get(`/proxy/legalForm/${categoryCode}`);
    logger.log("fetchLegalForm", responseLegalForm);
    setValue('formeJuridique', responseLegalForm.data.intitule || "N/A");
  } catch (err) {
    console.error("Erreur lors de la récupération de la forme juridique:", err);
    setValue('formeJuridique', "Non disponible");
  }
};

// Fonction pour récupérer le libellé NAF
const fetchNafLibelle = async (codeNaf, setValue, setError) => {
  try {
    const responseNaf = await axios.get(`/proxy/nafr2/${codeNaf}`);
    logger.log("fetchNafLibelle", responseNaf);
    setValue('libelleNaf', responseNaf.data.intitule || "N/A");
  } catch (err) {
    console.error("Erreur lors de la récupération du libellé NAF:", err);
    setError("Impossible de récupérer le libellé NAF.");
    setValue('libelleNaf', "Non disponible");
  }
};

export { fetchCompanyInfoBySiret, fetchCompanyInfoBySiren };