import React, { useContext, useEffect, useState } from "react";
import { BrowserRouter as Router } from "react-router-dom";


import "font-awesome/css/font-awesome.min.css";
import "bootstrap/dist/css/bootstrap.min.css";
import { Login } from "./components/Login/Login";
import { Header } from "./components/Header/Header";
import {Bandeau} from "./components/Bandeau/Bandeau";
import { Footer } from "./components/Footer/Footer";
import { Zone1 } from "./components/Zone1";
import { Zone2 } from "./components/Zone2";
import { Zone3 } from "./components/Zone3";
import { Zone4 } from "./components/Zone4";
import logger  from "./components/Utilities/Logger";

import { AuthContext } from "./contexts/AuthProvider";
import "./css/App.css";


const App = () => {
  const {
    user,
    isLogged,
    isPartenaire,
    isAgence,
    isClient,
    selectedMenu,
    handleTiersId,
  } = useContext(AuthContext);

  const [tiersType, setTiersType] = useState(null);


  useEffect(() => {
    if (
      selectedMenu === "ALL CLIENTS" ||
      selectedMenu === "ALL PARTENAIRES" ||
      selectedMenu === "ADMIN PARTENAIRES" ||
      selectedMenu === "ALL PRESTATAIRES"
    ) {
      handleTiersId(null);
    }
  }, [selectedMenu]); // Dépend de selectedMenu et handleTiersId

  useEffect(() => {
    // user est dans le contexte, on regarde si c'est null
    if (user === null) return;
    const typeTiers = user.userTypeUtilisateur;
    setTiersType(typeTiers);
  }, [isClient, isPartenaire, isAgence, user]);



  return (
      <Router>
        {!isLogged ? (
          <>
            <Login />
          </>
        ) : (
          <>
            <Header />
            <Bandeau/>
            <AgenceContent tiersType={tiersType} />
            <Footer />
          </>
        )}
      </Router>
  );
};

// Important de laisser AgenceContent à l'extérieur de la
const AgenceContent = ({ tiersType }) => {
  const { selectedMenu, isMenuToggled, handleIsMenuToggled } = useContext(AuthContext);

  useEffect(() => {
    if (isMenuToggled && selectedMenu) {
      handleIsMenuToggled(!isMenuToggled);
    }
  }, [selectedMenu]);

  // logger.log(
  //   "App AgenceContent selectedMenu DEBUT",    isMenuToggled,
  //   selectedMenu,
  //   tiersType,
  // );

   const getZone1Class = (tiersType) => {
      let retour = "col-xl-2 d-none d-xl-block";
      // logger.log("App AgenceContent getZone1Class isMenuToggled:", isMenuToggled, tiersType, retour);
      return retour;
  };

  const getZone2Class = (selectedMenu, tiersType) => {
    let retour;
    logger.log(
      "App AgenceContent getZone2Class",
      isMenuToggled,
      selectedMenu,
      tiersType,
    );
    switch (selectedMenu) {
      case "ALL PROJETS":
      case "ALL CLIENTS":
      case "ALL PRESTATAIRES":
      case "ALL PARTENAIRES":
      case "ADMIN PARTENAIRES":
      case "ALL SYNTHÈSES":
      case "PROJETS CLIENTS":
      case "PROJETS PARTENAIRES":
      case "PROJETS PRESTATAIRES":
      case "PROJET AGENCE":
      case "PROJETS FOURNISSEURS":
      case "SYNTHÈSES":
      case "PARTENAIRES":
      case "CLIENTS":
        // BOOTSTRAP OK
        retour = "col-xl-2 col-lg-2 col-md-2 col-sm-2";
        // logger.log("App AgenceContent getZone2Class", isMenuToggled, tiersType, retour);
        return retour;

      case "NEW AGENCE":
        retour = "col-xl-4 col-lg-4 col-md-4 col-sm-4";
        // logger.log("App AgenceContent getZone2Class", isMenuToggled, tiersType, retour);
        return retour;

      case "UTILISATEURS":
      case "ADMIN AGENCES":
      case "ADMIN GÉNÉRAL":
      case "ADMIN MODÈLES":
        retour = "col-xl-3 col-lg-3 col-md-3 col-sm-3";
        // logger.log("App AgenceContent getZone2Class", isMenuToggled, tiersType, retour);
        return retour;

      case "NEW MODELL":
      case "NEW CLIENT":
      case "NEW MODÈLE":
      case "NEW PRESTATAIRE":
      case "NEW PARTENAIRE":
        retour = "col-xl-4 col-lg-4 col-md-4 col-sm-4";
        // logger.log("App AgenceContent getZone2Class", isMenuToggled, tiersType, retour);
        return retour;

      case "ALL DOCUMENTS":
        retour = "col-xl-5 col-lg-5 col-md-5 col-sm-5";
        // logger.log("App AgenceContent getZone2Class", isMenuToggled, tiersType, retour);
        return retour;

      case "REQUÊTES":
      case "ATTENTES":
      case "RÉPONSES":
      case "DÉPÔT EXPRESS":
      case "REQUÊTES EXPRESS":
        switch (tiersType) {
          case "AGENCE":
          case "PRESTATAIRE":
            retour = "col-xl-8 col-lg-10 col-md-10 col-sm-10";
            // logger.log("App AgenceContent getZone2Class", isMenuToggled, tiersType, retour);
            return retour;

          case "CLIENT":
          case "PARTENAIRE":
            retour = "col-xl-10 col-lg-12 col-md-12 col-sm-12";
            return retour;

          default:
            return null; // Ou ajouter une vue d'erreur par défaut
        }

      case "ALL REQ":
      case "ADMIN CATÉGORIES":
      case "ADMIN RÔLES":
      case "ADMIN MÉTIERS":
      case "ADMIN ACTIONS":
        retour = "col-xl-10 col-lg-12 col-md-12 col-sm-12";
        // logger.log("App AgenceContent getZone2Class", isMenuToggled, tiersType, retour);
        return retour;


      default:
        retour = "col-xl-3 col-lg-3 col-md-2 col-sm-2";

        // logger.log("App AgenceContent getZone2Class DEFAULT", isMenuToggled, tiersType, retour);
        return retour;
    }
  };

  const getZone3Class = (selectedMenu, tiersType) => {
    let retour;

      if (["REQUÊTES", "ATTENTES", "DÉPÔT EXPRESS"].includes(selectedMenu)) {
        // En fonction du tiers on ajuste l'affichage
        switch (tiersType) {
          case "AGENCE":
          case "PRESTATAIRE":
            retour = "col-xl-2 col-lg-2 col-md-2 col-sm-2";
            // logger.log("App AgenceContent getZone3Class", isMenuToggled, tiersType, retour);
            return retour;

          default:
            return null; // Ou ajouter une vue d'erreur par défaut
        }
      }

      if (
        [

          "NEW CLIENT",
          "NEW MODÈLE",
          "NEW PRESTATAIRE",
          "NEW PARTENAIRE",
          "ADMIN MODÈLES",
        ].includes(selectedMenu)
      ) {
        // BOOTSTRAP OK
        retour = "col-xl-3 col-lg-5 col-md-5 col-sm-5";
        // logger.log("App AgenceContent getZone3Class", isMenuToggled, tiersType, retour);
        return retour;
      }

      if (
        [
          "NEW AGENCE",
        ].includes(selectedMenu)
      ) {
        // BOOTSTRAP OK
        retour = "col-xl-4 col-lg-6 col-md-6 col-sm-6";
        // logger.log("App AgenceContent getZone3Class", isMenuToggled, tiersType, retour);
        return retour;
      }

      if (
        [
          "CLIENTS",
          "PARTENAIRES",
          "ADMIN AGENCES",
          "ADMIN GÉNÉRAL",
          "UTILISATEURS",
          "NEW CLIENT",
          "NEW MODÈLE",
        ].includes(selectedMenu)
      ) {
        // BOOTSTRAP OK
        retour = "col-xl-4 col-lg-6 col-md-6 col-sm-6";
        // logger.log("App AgenceContent getZone3Class", isMenuToggled, tiersType, retour);
        return retour;
      }

      if (["ALL DOCUMENTS"].includes(selectedMenu)) {
        retour = "col-xl-5 col-lg-5 col-md-5 col-sm-5";
        // logger.log("App AgenceContent getZone3Class", isMenuToggled, tiersType, retour);
        return retour;
      }
      if (
        [
          "PROJETS CLIENTS",
          "PROJETS PARTENAIRES",
          "PROJETS PRESTATAIRES",
          "PROJET AGENCE",
          "PROJETS FOURNISSEURS",
          "ALL CLIENTS",
          "ALL PRESTATAIRES",
          "ALL PARTENAIRES",
          "ADMIN PARTENAIRES",
          "ALL PROJETS",
        ].includes(selectedMenu)
      ) {
        // Changement BOOTSTRAP OK
        retour = "col-xl-6 col-lg-8 col-md-8 col-sm-8";
        // logger.log("App AgenceContent getZone3Class", isMenuToggled, tiersType, retour);
        return retour;
      }

      if (["SYNTHÈSES", "ALL SYNTHÈSES"].includes(selectedMenu)) {
        retour = "col-xl-6 col-lg-8 col-md-8 col-sm-8";
        // logger.log("App AgenceContent getZone3Class", isMenuToggled, tiersType, retour);
        return retour;
      }

      if (["ADMIN ACTIONS", "ADMIN MÉTIERS","ADMIN RÔLES"].includes(selectedMenu)) {
        // Pas de Zone 3
        return null;
      }
      return null;
    // }
  };

  const getZone4Class = (selectedMenu, tiersType) => {
    let retour; 
 
    if (
      [
        "NEW AGENCE",
      ].includes(selectedMenu)
    ) {
      retour = "col-xl-2 col-lg-2 col-md-2 col-sm-2";
      // logger.log("App AgenceContent getZone4Class", isMenuToggled, tiersType, retour);
      return retour;
    }

      if (
        [
          "UTILISATEURS",
          "ADMIN AGENCES",
          "ADMIN GÉNÉRAL",
          "NEW PRESTATAIRE",
          "NEW CLIENT",
          "NEW MODÈLE",
          "NEW PARTENAIRE",

        ].includes(selectedMenu)
      ) {
        retour = "col-xl-3 col-lg-3 col-md-3 col-sm-3";
        // logger.log("App AgenceContent getZone4Class", isMenuToggled, tiersType, retour);
        return retour;
      }

      if (
        [
          "PROJETS CLIENTS",
          "PROJETS PARTENAIRES",
          "PROJETS PRESTATAIRES",
          "PROJET AGENCE",
          "PROJETS FOURNISSEURS",
          "ALL CLIENTS",
          "ALL PRESTATAIRES",
          "ALL PARTENAIRES",
          "ADMIN PARTENAIRES",
          "ALL PROJETS",
          "ALL SYNTHÈSES",
          "SYNTHÈSES",
        ].includes(selectedMenu)
      ) {
        retour = "col-xl-2 col-lg-2 col-md-2 col-sm-2";
        // logger.log("App AgenceContent getZone4Class", isMenuToggled, tiersType, retour);
        return retour;
      }

      if (
        ["CLIENTS", "PARTENAIRES",  "ADMIN MODÈLES"].includes(
          selectedMenu
        )
      ) {
        retour = "col-xl-4 col-lg-4 col-md-4 col-sm-4";
        // logger.log("App AgenceContent getZone4Class", isMenuToggled, tiersType, retour);
        return retour;
      }

      if (
        [
          "ALL SYNTHÈSES",
          "SYNTHÈSES",
          "REQUÊTES",
          "ADMIN ACTIONS",
          "ADMIN MÉTIERS",
          "ADMIN RÔLES",
          "ALL DOCUMENTS",
        ].includes(selectedMenu)
      ) {

        retour = null;
        // logger.log("App AgenceContent getZone4Class", isMenuToggled, tiersType, retour);
        return retour;
      }
      return null;
    // }
  };

  // // logger.log("App AgenceContent selectedMenu FIN", selectedMenu);
  const zone1Class = getZone1Class(tiersType);
  const zone2Class = getZone2Class(selectedMenu, tiersType);
  const zone3Class = getZone3Class(selectedMenu, tiersType);
  const zone4Class = getZone4Class(selectedMenu, tiersType);


  return (
    <div className="row">
      <div className="d-block d-md-none text-center" style={{ padding: "20px" }}>
        <p>Veuillez retourner votre périphérique pour une meilleure expérience.</p>
      </div>
      {isMenuToggled ? (
        <>
           <div className="col-lg-4 col-md-4 col-sm-4 order-first">
            <Zone1 />
          </div>
        </>
      ) : (
        <>
          { zone1Class && (
            <div className={zone1Class}>
              <Zone1 />
            </div>
          )}

          {zone2Class && (
            <div className={zone2Class}>
              <Zone2 />
            </div>
          )}

          {zone3Class && (
            <div className={zone3Class}>
              <Zone3 />
            </div>
          )}

          {zone4Class && (
            <div className={zone4Class}>
              <Zone4 />
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default App;
