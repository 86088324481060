import React, { useEffect, useState, useContext, useCallback } from "react";
import "../css/Compteurs.css";

import { AuthContext } from "../../contexts/AuthProvider";
import customAxios from "../Utilities/getAxiosToken";
import io from 'socket.io-client';
import Cookies from "js-cookie";

export function CountProjetsTiersByUserId(props ) {
  const { cibleProjets, context } = props; // Cible CLIENT ou PARTENAIRE- Context bandeau ou autre
 
  const { userId } = useContext(AuthContext);
  const [projets, setListeProjets] = useState([]);
  const [etatProjet, setEtatProjet] = useState("ouvert"); // État local pour gérer l'état des projets

  const fetchListeProjetsEtModeles = useCallback(async () => {
    if (!userId) return;

    try {

      const response = await customAxios.get(`/api/projets/projetsEtModeles/byUser/${userId}/${etatProjet}/${cibleProjets}`);
      // logger.log("CountProjetsTiersByUserId: fetchListeProjetsEtModeles response.data",userId, cibleProjets, response.data);

      const projetsFiltrésParCible = response.data;
      // logger.log("CountProjetsTiersByUserId: fetchListeProjetsEtModeles projetsFiltrésParCible",userId, cibleProjets,  projetsFiltrésParCible);

      setListeProjets(projetsFiltrésParCible);
    } catch (error) {
      console.error("Erreur lors de la récupération des projets et des modèles :", error);
      setListeProjets([]);  // Assurer que projets est réinitialisé en cas d'erreur
    }
  }, [userId,  etatProjet]);

  useEffect(() => {
    
    fetchListeProjetsEtModeles();

    // logger.log("CountProjetsTiersByUserId: const socket = io()", process.env.REACT_APP_SOCKET_URL);

    
    const socket = io(process.env.REACT_APP_SOCKET_URL, {
      path: '/ws',
      auth: {
        token: Cookies.get("userTokenAcces")
      }
    });
    const updateProjects = () => {
      fetchListeProjetsEtModeles();
    };

    
    socket.on('nouveauProjet', updateProjects);
    socket.on('updateProjet', updateProjects);
    socket.on('updateModèle', updateProjects);
    return () => {
      logger.log("CountProjetsTiersByUserId: Closing WebSocket");
      socket.off('nouveauProjet', updateProjects);
      socket.off('updateProjet', updateProjects);
      socket.off('updateModèle', updateProjects);
      socket.disconnect();
  };


  }, [fetchListeProjetsEtModeles]);

  // return (
  //   <>{userId && (
  //     <span className="badge badge-info">
  //       {projets.length}
  //     </span>
  //   )}</>
  // );
  return (
    <>
      {context === "bandeau" ? (
        <span>{projets.length}</span>
      ) : (
        <span className="badge badge-info compteur">{projets.length}</span>
      )}
    </>
  );
}



