import React, { useEffect, useState, useContext, useCallback } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import { AuthContext } from "../../contexts/AuthProvider";
import customAxios from "../Utilities/getAxiosToken";
import Cookies from "js-cookie";
import io from 'socket.io-client'; 

export function CountListProjetsByUserId({ etatProjet, cibleProjets }) {
  const [projetCount, setProjetCount] = useState(0);
  const { userId, tiersId } = useContext(AuthContext);

  const modeleIsActif = true; // Tous les modèles isActifs

  // Fonction pour récupérer le nombre de projets
  const fetchProjetCount = useCallback(async () => {
    try {
      const response = await customAxios.get(
        `/api/projets/All/${tiersId}/${modeleIsActif}/${userId}/${etatProjet}`
      );
      const count = response.data.projects.length;
      logger.log("CountListProjetsByUserId: fetchProjetCount", tiersId, userId, count, etatProjet);
      setProjetCount(count);
    } catch (error) {
      console.error("Erreur lors de la récupération du nombre de projets :", error);
    }
  }, [userId, etatProjet, cibleProjets, tiersId]);

  useEffect(() => {
    if (cibleProjets && userId) {
      fetchProjetCount();
    }
  }, [cibleProjets, userId, etatProjet, fetchProjetCount]);

  useEffect(() => {
    const socket = io(process.env.REACT_APP_SOCKET_URL, {
      path: '/ws',
      auth: {
        token: Cookies.get("userTokenAcces")
      }
    });

    socket.on('nouveauProjet', fetchProjetCount);
    socket.on('updateProjet', fetchProjetCount);

    // Nettoyer les abonnements WebSocket lors du démontage du composant
    return () => {
      logger.log("CountListProjetsByUserId: Closing WebSocket");
      socket.off('nouveauProjet', fetchProjetCount);
      socket.off('updateProjet', fetchProjetCount);
      socket.disconnect();
  };

  }, [fetchProjetCount]);

  return (
    <>{userId ? <span className="badge badge-info">{projetCount}</span> : null}</>
  );
}