

import React, { useEffect, useState, useContext, useCallback } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import customAxios from "../Utilities/getAxiosToken";
import "./../css/Global.css";

import { AuthContext } from "../../contexts/AuthProvider";
import io from 'socket.io-client'; 
import Cookies from "js-cookie";

export function CountCatégories() {
 
  const { userId } = useContext(AuthContext);
  const [nbCategories, setNbCategories] = useState(0);

  const chargerCategories = useCallback(async () => {
    if (userId ) {
      try {
        const response = await customAxios.get(`/api/categories/nbcategories`);
        const { count } = response.data;
        setNbCategories(count);
      } catch (error) {
        console.error("Erreur lors du chargement des catégories:", error);
        setNbCategories(0); // Réinitialiser en cas d'erreur
      }
    }
  }, [userId]);

  useEffect(() => {
    

    chargerCategories();

    const socket = io(process.env.REACT_APP_SOCKET_URL, {
      path: '/ws',
      auth: {
        token: Cookies.get("userTokenAcces")
      }
    });
    socket.on('nouveauModèle', chargerCategories);
    socket.on('updateModèle', chargerCategories);

    return () => {
      logger.log("CountCatégories: Closing WebSocket");
      socket.disconnect();
  };

  }, [chargerCategories]);

  return userId ? <span className="badge badge-info">{nbCategories}</span> : null;
}
