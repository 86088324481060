
import Cookies from "js-cookie";
import React, { createContext, useState, useEffect, useCallback } from "react";

import customAxios from "../components/Utilities/getAxiosToken";


const AuthContext = createContext();

const AuthProvider = ({ children }) => {

  const [user, setUser] = useState(null);
  const [permissions, setPermissions] = useState([]);

  // // Pour enregister l'état initial du Manager
  const [utilisateurManager, setUtilisateurManager] = useState(null);
  // // Pour enregister l'état initial de l'Admin
  const [utilisateurAdmin, setUtilisateurAdmin] = useState(null);


  const [triggerAddAction, setTriggerAddAction] = useState(false); // à true si on veut ajouter une nouvelle action
  const [triggerAddCorpsDeMetiers, setTriggerAddCorpsDeMetiers] =
    useState(false); // à true si on veut ajouter un nouveau CorpsDeMetiers
  const [triggerAddModele, setTriggerAddModele] = useState(false);
  const [triggerAddPrevisionnel, setTriggerAddPrevisionnel] = useState(false);
  const [triggerAddEcheance, setTriggerAddEcheance] = useState(false);

  // Gestion des rôles
  const [isLogged, setIsLogged] = useState(false); // Dans App.js lance le login is isLogged is false
  const [isManager, setIsManager] = useState(false);
  const [isAdmin, setIsAdmin] = useState(false); // Dans App.js Affiche le sélecteur d'utilisateurs si isManger is true

  const [isAgence, setIsAgence] = useState(false); // Dans App.js Affiche le sélecteur d'utilisateurs si isManger is true
  const [isPartenaire, setIsPartenaire] = useState(false); // Dans App.js Affiche le sélecteur d'utilisateurs si isManger is true
  const [isClient, setIsClient] = useState(false); // Dans App.js Affiche le sélecteur d'utilisateurs si isManger is true
  const [isPrestataire, setIsPrestataire] = useState(false); // Dans App.js Affiche le sélecteur d'utilisateurs si isManger is true

  const [isHttps, setIsHttps] = useState(false); // Dans App.js Affiche le sélecteur d'utilisateurs si isManger is true

  const [userId, setUserId] = useState(null); // Utilisateur Connecté - User log in
  const [userTiersId, setUserTiersId] = useState(null); // TiersId auquel est rattaché le userId

  const [selectedMenu, setSelectedMenu] = useState("");
  const [zone2Content, setZone2Content] = useState("");
  const [projetId, setProjetId] = useState(""); // Pointe sur le projetId en cours
  const [projetCible, setprojetCible] = useState(""); // On récupère la cible du projet issue du modèle

  const [modeleId, setModeleId] = useState(""); // Pointe sur le modeleId en cours
  const [utilisateurId, setUtilisateurId] = useState(""); // Pointe sur l'utilisateurId en cours
  const [tiersId, setTiersId] = useState(null); // Pointe sur le tiersId en cours
  const [agenceId, setAgenceId] = useState(""); // Pointe sur les Paramètres communs
  const [etapeId, setEtapeId] = useState(null);

  const [modeleOrder, setModeleOrder] = useState([]); // Nouvel état pour l'ordre des modèles

  const [debugAffichage, setDebugAffichage] = useState(false);
  const [modificationDestinataires, setModificationDestinataires] =
    useState(false);



  // Pour déployer les Destinataires
  const [isDestinatairesOpen, setToggleDestinataire] = useState(false);
 
  // Dans votre fournisseur de contexte
  const [selectedArchive, setSelectedArchive] = useState(null);
  const [isMenuToggled, setIsMenuToggled] = useState(false);
  
  const [addNewUser, setNewUser] = useState(false);
  const [newPrevisionnel, setNewPrevisionnel] = useState(false);
  const [newEcheancier, setNewEcheancier] = useState(false);

  const [newCorpsDeMetier, setNewCorpsDeMetier] = useState(false);
  const [newAction, setNewAction] = useState(false);
  const [showSearch, setShowSearch] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const toggleSearch = () => setShowSearch(!showSearch);




  const fetchUserAndPermissions = async () => {
    try {

      // Récupère les permissions basées sur le rôle et le type d'utilisateur
      const permissionsResponse = await customAxios.get(
        `/api/permissions/${user.role}/${user.userTypeUtilisateur}`
      );
      setPermissions(permissionsResponse.data.data.menus);
    } catch (error) {
      console.error("Erreur lors de la récupération des informations de l'utilisateur :", error);
    }
  };

  useEffect(() => {
    if (isLogged) {  // Assurez-vous que `isLogged` est bien mis à jour à la connexion
        fetchUserAndPermissions();
    }
}, [isLogged,userId]);

  const handleShowSearch= (flag) => {
    logger.log("AuthProvider setShowSearch", flag);
    setShowSearch(flag);
  };
  const handleSearchTerm = (searchTerm) => {
    logger.log("AuthProvider setSearchTerm", searchTerm);
    setSearchTerm(searchTerm);
  };

  const handleAgenceId = (paramId) => {
    logger.log("AuthProvider handleAgenceId", paramId);
    setAgenceId(paramId);
  };

  const handleAddNewAction = (action) => {
    logger.log("AuthProvider handleAddNewAction", action);
    setNewAction(action);
  };

  const handleAddNewCorpsDeMetier = (corpsDeMetier) => {
    logger.log("AuthProvider handleAddNewCorpsDeMetier", corpsDeMetier);
    setNewCorpsDeMetier(corpsDeMetier);
  };

  // Fonctions pour gérer l'ajout de nouveaux éléments
  const handleAddNewUser = (user) => {
    logger.log("AuthProvider handleAddNewUser", user);
    setNewUser(user);
  };

  const handleIsHttps = (status) => {
    logger.log("AuthProvider handleIsHttps", status);
    setIsHttps(status);
  };
  const handleIsMenuToggled = (toggled) => {
    setIsMenuToggled(toggled);
  };

  const handleAddNewPrevisionnel = (previsionnel) => {
    // Logique pour ajouter un nouvel élément prévisionnel
    setNewPrevisionnel(previsionnel);
  };

  const handleAddNewEcheancier = (echeancier) => {
    // Logique pour ajouter un nouvel élément échéancier
    setNewEcheancier(echeancier);
  };

  const handleprojetCible = (cible) => {
    logger.log("AuthProvider changeprojetCible", cible);
    setprojetCible( cible );
  };

  const handleToggleDestinataires = (flag) => {
    logger.log("AuthProvider handleToggleDestinataires", flag);
    setToggleDestinataire(flag);
  };

  const handleModelOrder = (items) => {
    logger.log("AuthProvider handleModelOrder", items);
    setModeleOrder(items);
  };

  

  const handleModificationDestinataires = (action) => {
    logger.log("AuthProvider handleModificationDestinataires", action);
    setModificationDestinataires(action);
  };

  const handleEtapeId = (etape) => {
    logger.log("AuthProvider handleEtapeId", etape);
    setEtapeId(etape);
  };

  const handleProjetId = (projetId) => {
    logger.log("AuthProvider handleProjetId", projetId);
    setProjetId(projetId);
  };

  const handleModeleId = (modeleId) => {
    logger.log("AuthProvider handleModeleId", modeleId);
    setModeleId(modeleId);
  };

  const handleSelectedMenu = (selectedMenu) => {
    logger.log("AuthProvider handleSelectedMenu", selectedMenu);
    setSelectedMenu(selectedMenu);
  };

  // On stocke l'Utilisateur de travail
  const handleUtilisateurId = (utilisateurId) => {
    logger.log("AuthProvider handleUtilisateurId", utilisateurId);
    setUtilisateurId(utilisateurId);
  };

  // On stocke le tiers du serId
  const handleUserTiersId = (tiersId) => {
    logger.log("AuthProvider handleUserTiersId", tiersId);
    setUserTiersId(tiersId);
  };

  const handleTriggerAddAction = (flag) => {
    logger.log("AuthProvider handleTriggerAddAction", flag);
    setTriggerAddAction(flag);
  };

  const handleTriggerAddModele = (flag) => {
    logger.log("AuthProvider handleTriggerAddModele", flag);
    setTriggerAddModele(flag);
  };

  const handleTriggerAddCorpsDeMetiers = (flag) => {
    logger.log("AuthProvider handleTriggerAddCorpsDeMetiers", flag);
    setTriggerAddCorpsDeMetiers(flag);
  };

  const handleTriggerAddPrevisionnel = (flag) => {
    logger.log("AuthProvider handleTriggerAddPrevisionnel", flag);
    setTriggerAddPrevisionnel(flag);
  };

  const handleTriggerAddEcheance = (flag) => {
    logger.log("AuthProvider handleTriggerAddEcheance", flag);
    setTriggerAddEcheance(flag);
  };


  const handleDebugAffichage = (flag) => {
    logger.log("AuthProvider handleDebugAffichage", flag);
    setDebugAffichage(flag);
  };

  // On stocke le tiers de travail
  const handleTiersId = (tiersId) => {
    logger.log("AuthProvider handleTiersId", tiersId);
    setTiersId(tiersId);
  };

  useEffect(() => {
    console.log("AuthContext userId updated: ", userId);
  }, [userId]);

  useEffect(() => {
    if (user?.userId) {
      console.log("AuthContext user.userId changed:", user.userId);
    }
  }, [user?.userId]);

  const handleLogin = async (userId, nom, prenom, role, agenceId, userTypeUtilisateur) => {
    logger.log("AuthProvider handleLogin", userId, nom, prenom, role, agenceId, userTypeUtilisateur);   

    setAgenceId(agenceId);
    setTiersId(agenceId);
    setUserTiersId(agenceId);       // Détermine le tiersId de l'utilisateur connecté
    setUser({ userId, nom, prenom, role, userTypeUtilisateur });
    setUserId(userId); // Utilisation de user._id, pas {userId} textuel

    // Mise à jour de utilisateurManager seulement si role est "Manager" et si utilisateurManager n'est pas déjà défini
    if (role === "Manager" && !utilisateurManager) {
      setUtilisateurManager({ userId, nom, prenom, role, agenceId, userTypeUtilisateur });
    }

    // Mise à jour de utilisateurAdmin seulement si role est "Admin" et si utilisateurAdmin n'est pas déjà défini
    if (role === "Admin" && !utilisateurAdmin) {
      setUtilisateurAdmin({ userId, nom, prenom, role, agenceId, userTypeUtilisateur });
    }

 
    setIsLogged(true);
    setIsManager(role === "Manager");
    setIsAdmin(role === "Admin");
    setIsPartenaire(userTypeUtilisateur === "PARTENAIRE");
    setIsAgence(userTypeUtilisateur === "AGENCE");
    setIsClient(userTypeUtilisateur === "CLIENT");
    setIsPrestataire(userTypeUtilisateur === "PRESTATAIRE");

    // Définir le menu par défaut en fonction du type de tiers
    definirMenuParDefautALaConnexion(userTypeUtilisateur);
};

// useEffect(() => {
//   console.log("AuthContext user.userId updated: ", user.userId);
// }, [user.userId]);

const definirMenuParDefautALaConnexion = (typeTiers) => {
    const menuMapping = {
        PARTENAIRE: "ATTENTES",
        PRESTATAIRE: "REQUÊTES",
        AGENCE: "REQUÊTES",
        ADMINISTRATEUR: "ADMIN AGENCES",
    };
    logger.log("Login definirMenuParDefautALaConnexion", typeTiers);
    handleSelectedMenu(menuMapping[typeTiers] || "DEFAULT");
};


  const handleLogout = () => {
    logger.log("AuthProvider handleLogout", tiersId);
    setUtilisateurManager(null);
    setUtilisateurAdmin(null);
    setIsLogged(false);
    setUserId("");
    setSelectedMenu("");
    setZone2Content("");
    setProjetId("");
    setModeleId("");
    setAgenceId("");
    Cookies.remove("userTokenAcces");
  };



  return (
    <AuthContext.Provider
      value={{
        user,
        utilisateurManager,
        utilisateurAdmin,
        permissions,
        isLogged,
        isManager,
        setIsManager,
        isAdmin,
        setIsAdmin,
        isClient,
        setIsClient,
        isPartenaire,
        setIsPartenaire,
        userId,
        agenceId,
        handleAgenceId,
        utilisateurId,
        isPrestataire,
        setIsPrestataire,

        isHttps,
        handleIsHttps,
        isAgence,
        setIsAgence,
        projetCible,
        handleprojetCible,

        isMenuToggled,
        handleIsMenuToggled,

        toggleSearch,
        showSearch,
        handleShowSearch,
        handleSearchTerm, 
        searchTerm,

        userTiersId,
        tiersId,
        selectedMenu,
        zone2Content,
        projetId,
        modeleId,
        etapeId,

        addNewUser,
        handleAddNewUser,
        newPrevisionnel,
        handleAddNewPrevisionnel,
        newEcheancier,
        handleAddNewEcheancier,
        newCorpsDeMetier,
        handleAddNewCorpsDeMetier,
        newAction,
        handleAddNewAction,

        modeleOrder,
        handleModelOrder,

        modificationDestinataires,
        handleModificationDestinataires,

        handleProjetId,
        handleModeleId,
        handleEtapeId,
        handleSelectedMenu,
        handleUtilisateurId,
        handleTiersId,
        handleUserTiersId,
        handleLogin,
        handleLogout,
        triggerAddAction,
        handleTriggerAddAction,
        triggerAddCorpsDeMetiers,
        handleTriggerAddCorpsDeMetiers,
        triggerAddModele,
        handleTriggerAddModele,
        triggerAddPrevisionnel,
        handleTriggerAddPrevisionnel,

        triggerAddEcheance,
        handleTriggerAddEcheance,

        debugAffichage,
        handleDebugAffichage,

        isDestinatairesOpen,
        handleToggleDestinataires,

        selectedArchive,

      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export { AuthContext, AuthProvider };
