import React, { useRef, useEffect, useContext, useState, useCallback } from "react";
import { BsCloudDownload } from "react-icons/bs";

import { AuthContext } from "../../contexts/AuthProvider";
import customAxios from "../Utilities/getAxiosToken";
import Modal from "../Modal/Modal";

const BoutonDownLoad = ({ etape, style }) => {
 
  const { userId, userTiersId } = useContext(AuthContext);
  const [showModal, setShowModal] = useState(false);
  const [selectedTiersId, setSelectedTiersId] = useState(null);
  const fileInputRef = useRef(null);
  const [typeTiers, setTypeTiers] = useState("");

  const formatDateForFileName = (date) => {
    const d = new Date(date);
    const year = d.getFullYear();
    const month = `${d.getMonth() + 1}`.padStart(2, "0");
    const day = `${d.getDate()}`.padStart(2, "0");
    const hour = `${d.getHours()}`.padStart(2, "0");
    const minute = `${d.getMinutes()}`.padStart(2, "0");
    const second = `${d.getSeconds()}`.padStart(2, "0");
    return `${year}${month}${day}_${hour}:${minute}:${second}`;
  };

  const prepareMetadata = (file, uploadDate) => ({
    userId,
    echeanceEtape: etape.echeanceEtape,
    etapeId: etape.etapeId,
    projetId: etape.projetId,
    actionId: etape.actionId,
    destinataireId: selectedTiersId,
    typeEtape: etape.typeEtape,
    rubriqueEtape: etape.rubrique,
    prefixeEtape: etape.prefixe,
    uploadDate,
    emetteurId: etape.emetteursEtape,
    originalFileName: file.name
  });

  const fetchData = useCallback(async () => {
    try {
      const response = await customAxios.get(`/api/tiers/${userTiersId}`);
      setTypeTiers(response.data.typeTiers);
    } catch (error) {
      console.error("Erreur lors de la récupération des données initiales", error);
    }
  }, [ userTiersId]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const handleFileUpload = async (event) => {
    const files = event.target.files;
    if (!files.length) {
      alert("Aucun fichier sélectionné.");
      return;
    }

    Array.from(files).forEach(async (file) => {
      const uploadDate = formatDateForFileName(new Date());
      const metadata = prepareMetadata(file, new Date());
      logger.log("BoutonDownLoad Fichier upload : metadata", metadata);

      const prefixedFileName = `${etape.rubrique}_${uploadDate}`;
      const formData = new FormData();
      formData.append("file", file);
      formData.append("fileName", prefixedFileName);
      formData.append("metadata", JSON.stringify(metadata));

      try {
        logger.log("BoutonDownLoad Fichier upload : formData", formData);
        const response = await customAxios.post(`/api/files/upload`, formData);
        logger.log("BoutonDownLoad Fichier uploadé avec succès :", response.data);
      } catch (error) {
        console.error("Erreur lors de l'upload du fichier :", error);
        alert("Erreur lors de l'upload du fichier.");
      }
    });
  };

  const triggerFileInput = () => {
    if (typeTiers === "AGENCE") {
      if (etape.destinatairesEtape && etape.destinatairesEtape.length > 1) {
        setShowModal(true);
      } else if (etape.destinatairesEtape && etape.destinatairesEtape.length === 1) {
        setSelectedTiersId(etape.destinatairesEtape[0].tiers);
        fileInputRef.current.click();
      } else {
        alert("Aucun destinataire trouvé.");
      }
    } else {
      const destinataire = etape.destinatairesEtape?.find(dest => dest.tiers === userTiersId);
      if (destinataire) {
        setSelectedTiersId(destinataire.tiers);
        fileInputRef.current.click();
      } else {
        alert("Aucun destinataire correspondant trouvé.");
      }
    }
  };

  const handleModalDownloadClick = () => {
    setShowModal(false);
    fileInputRef.current.click();
  };

  return (
    <>
      <Modal isOpen={showModal} onRequestClose={() => setShowModal(false)}>
        <h4>Sélectionner un destinataire</h4>
        <ul className="liste-sans-puces" style={{ margin: '20px' }}>
          {etape.destinatairesEtape?.map((dest, index) => (
            <li key={index}>
              <label style={{ display: 'flex', alignItems: 'center' }}>
                <input
                  type="radio"
                  name="destinataire"
                  value={dest.tiers}
                  onChange={(e) => setSelectedTiersId(e.target.value)}
                  checked={selectedTiersId === dest.tiers}
                  style={{ marginRight: '10px' }}
                />
                {dest.nom}
              </label>
            </li>
          ))}
        </ul>
        <div style={{ display: 'flex', justifyContent: 'space-around' }}>
          <button className="btn" onClick={() => setShowModal(false)}>Annuler</button>
          <button className="btn" onClick={handleModalDownloadClick}>Télécharger</button>
        </div>
      </Modal>

      <input
        type="file"
        multiple
        style={{ display: "none" }}
        onChange={handleFileUpload}
        ref={fileInputRef}
      />

      <BsCloudDownload
        style={style} 
        onClick={triggerFileInput}
      />
    </>
  );
};

export default BoutonDownLoad;