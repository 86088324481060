

import React, { useEffect, useState, useContext, useCallback } from "react";

import { AuthContext } from "../../contexts/AuthProvider";
import customAxios from "../Utilities/getAxiosToken";
import useGetModeles from "../Utilities/GetModeles";
import {
  getFontColor,
  getBackgroundClass,
  calculerDifferenceJours,
  getCategory
} from "../Utilities/attentesEtapes";
import io from 'socket.io-client'; 
import Cookies from "js-cookie";

export const CountEtapesAttentes = ({ AllAgences }) => {
  const { fetchModeles }  = useGetModeles(); 
  
  const [etapesEnAttente, setEtapesEnAttente] = useState([]);
  const { userId, agenceId } = useContext(AuthContext);
 
  const [attentesCounter, setAttentesCounter] = useState({
    success: 0,
    warning: 0,
    danger: 0,
  });



  const fetchEtapesEnAttente = useCallback(async () => {
    try {
      if (!userId) return;
  
      let response, attentes = [];
  
      if (AllAgences) {
        let listeModeleIds = await fetchModeles(agenceId);
        if (!listeModeleIds) return;
  
        // logger.log("CountEtapesAttentes fetchEtapesEnAttente listeModeleIds", listeModeleIds);
        for (let modeleIdLocal of listeModeleIds) {
          // const payload = { modeleIds: modeleIdLocal.modeleId };
          const payload = { modeleIds: modeleIdLocal._id };
          // logger.log("CountEtapesAttentes fetchEtapesEnAttente payload", payload);
  
          try {
            response = await customAxios.post(`/api/projets/attentesByModeleIds`, payload);
            // logger.log("CountEtapesAttentes fetchEtapesEnAttente Réponse de la requête POST:", response.data);

            attentes = [...attentes, ...response.data];
          } catch (postError) {
            console.error("CountEtapesAttentes fetchEtapesEnAttente Erreur lors de la requête POST:", postError);
          }
        }
      } else {
        try {
          // logger.log("CountEtapesAttentes fetchEtapesEnAttente /api/projets/attentesByUserId");
          const requestBody = {
            userId: userId,
          };
          const response = await customAxios.post(
            `/api/projets/attentesByUserId`,
            requestBody
          );
          attentes = response.data;
          // logger.log("CountEtapesAttentes fetchEtapesEnAttente Réponse de la requête POST:", response.data);
        } catch (getError) {
          console.error("CountEtapesAttentes fetchEtapesEnAttente Erreur lors de la requête POST:", getError);
        }
      }
  
      setEtapesEnAttente(attentes || []);
    } catch (error) {
      console.error("CountEtapesAttentes fetchEtapesEnAttente Erreur lors de la récupération des étapes en attente", error);
    }
  }, [userId, AllAgences, agenceId]);
  
  
  useEffect(() => {
    fetchEtapesEnAttente();

    // logger.log("CountEtapesAttentes const socket = io()", process.env.REACT_APP_SOCKET_URL);

    // authorization
    const socket = io(process.env.REACT_APP_SOCKET_URL, {
      path: '/ws',
      auth: {
        token: Cookies.get("userTokenAcces")
      }
    });

    const handleWebSocketEvent = () => {
      // logger.log('WebSocket event received');
      fetchEtapesEnAttente(); // Rafraîchit les données chaque fois qu'un événement WebSocket est reçu
    };

    socket.on('updateEtape', handleWebSocketEvent);
    socket.on('updateProjet', handleWebSocketEvent);

    socket.on("connect_error", (err) => {
      console.error('Erreur de connexion WebSocket:',  err);
      logger.log(err.message);
      logger.log(err.description);
      logger.log(err.context);
    });
    return () => {
      logger.log("CountEtapesAttentes: Closing WebSocket");
      socket.disconnect();
  };

  }, [ fetchEtapesEnAttente]);

  useEffect(() => {
    const newAttentesCounter = etapesEnAttente.reduce(
      (acc, etape) => {
        const joursRestants = calculerDifferenceJours(etape.echeanceEtape);
        const category = getCategory(joursRestants); // Utilisation de getCategory
  
        if (acc[category] !== undefined) {
          acc[category] += 1;
        }
        return acc;
      },
      { success: 0, warning: 0, danger: 0 } // Initialisation du compteur
    );
  
    setAttentesCounter(newAttentesCounter);
  }, [etapesEnAttente]);


  const badgeCategories = [
    { key: "success", className: "bg-vert bg-gradient" },
    { key: "warning", className: "bg-jaune bg-gradient" },
    { key: "danger", className: "bg-rouge bg-gradient" },
  ];
  
  return (
    <>
      {badgeCategories.map((category) => (
        <div
          key={category.key}
          className={`badge ${category.className}`}
          style={{ color: getFontColor(category.className) }}
        >
          {attentesCounter[category.key]}
        </div>
      ))}
    </>
  );


};
