import React, { useEffect, useState, useContext, useRef, useCallback, useMemo } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "./../css/Global.css";

import { AuthContext } from "../../contexts/AuthProvider";
import customAxios from "../Utilities/getAxiosToken";
import { Tooltip } from "react-tooltip";
import io from "socket.io-client";
import Cookies from "js-cookie";
import {
  BsEye,
  BsArrowDown,
  BsArrowUp,
  BsPencil,
  BsFiles,
  BsTrash,
  BsCheck,
  BsX,
  BsCheckCircle,
  BsXCircle,
  BsFileText,
  BsTextLeft,
  BsToggleOn,
  BsCalendar3,
} from "react-icons/bs";

// Si type='ADMIN MODÉLES' alors on est dans zone 4 - ADMIN MODELES pour paramétrer les modèles
// Si type= "administration" alors on est dans l'administration des actions
//
export function GestionActions(props) {
  const { type } = props;

  const [listeActions, setListeActions] = useState([]);
  const [sortField, setSortField] = useState("inModel");
  const [sortOrder, setSortOrder] = useState("desc"); // 'asc' pour croissant, 'desc' pour décroissant
 
  
  const {
    newAction,
    handleAddNewAction,
    searchTerm,
    modeleId,
  } = useContext(AuthContext);


  const [modeleActions, setEtapesModele] = useState([]);
  const [editModeIndex, setEditModeIndex] = useState(null);
  const [rubriques, setRubriques] = useState([]);
  const [editFormData, setEditFormData] = useState({});
  const [tousLesTypesTiers, setTousLesTypesTiers] = useState([]);   // Pour éditer les types de Vue
  const [tousLesTypesdActions, setTousLesTypesActions] = useState([]);
  const inputRef = useRef(null);


  // Retourne le nombre de projets et de modeles utilisant cette étape
  const fetchActionStats = async (actionId) => {
    try {
      const response = await customAxios.get(`/api/actions/action-stats/${actionId}`);
      // logger.log("Gestion Actions fetchActionStats",response.data);
      return response.data;
    } catch (error) {
      console.error(`Erreur lors de la récupération des statistiques pour l'action ${actionId}:`, error);
      return { modelesCount: 0, projetsCount: 0 };
    }
  };

  const handleAddAction = useCallback(() => {
    if (newAction) {
      const newAction = {
        description: "AAAA",
        prefixe: "",
        type: "N/A",
        rubrique: "",
        destinatairesModifiables: false,
      };
      setEditModeIndex(newAction.length);
      setEditFormData(newAction);
      setListeActions((prevListeActions) => [...prevListeActions, newAction]);
      handleAddNewAction(false);
    } else {
      alert("Vous n'êtes pas autorisé à ajouter de nouveaux corps de métiers.");
    }
  }, [newAction, handleAddNewAction]);

  useEffect(() => {
    if (editModeIndex !== null && inputRef.current) {
      inputRef.current.focus();
    }
  }, [editModeIndex]);


  const fetchData = useCallback(async () => {
    try {
      const response = await customAxios.get(`/api/actions/types-actions-et-tiers`);
      logger.log("GestionActions: fetchData", response.data);
      const result = response.data;
      setTousLesTypesTiers(result.tiers); // Pour éditer les types de Vue
      setTousLesTypesActions(result.typesActions);
    } catch (error) {
      console.error("Failed to fetch data:", error);
    }
  }, []);

  const fetchEtapeDetails = useCallback(async (etapeId) => {
    try {
      // logger.log("GestionActions: fetchEtapeDetails", etapeId);
      const response = await customAxios.get(`/api/modeles/etapesModele/${etapeId}`);
      return response.data;
    } catch (error) {
      console.error("fetchEtapeDetails: Erreur lors de la récupération des détails de l'étape du modèle:", error);
      return null;
    }
  }, []);

  const fetchModeleActions = useCallback(async () => {
    if (modeleId !== null) {
      try {
        logger.log("GestionActions: fetchModeleActions", modeleId);
        const response = await customAxios.get(`/api/modeles/${modeleId}`);

        const data = response.data;
        logger.log("GestionActions: fetchModeleActions response", response);
        const etapesPromises = data.etapes.map((etapeId) => fetchEtapeDetails(etapeId));
        const etapesDetails = await Promise.all(etapesPromises);
        setEtapesModele(etapesDetails);
      } catch (error) {
        console.error("Erreur lors de la récupération des actions du modèle:", error);
      }
    } else {
      console.warn("modeleId est null, la requête API n'a pas été effectuée.");
    }
  }, [ modeleId, fetchEtapeDetails]);

  const extractRubriques = useCallback((data) => {
    const rubriquesUniques = [...new Set(data.map((item) => item.rubrique).filter(rubrique => rubrique))];
    const sortedRubriques = rubriquesUniques.sort((a, b) => a.localeCompare(b));
    setRubriques(sortedRubriques);
  }, []);

  const isActionInModel = useCallback((actionId) => {
    const isInModel = modeleActions.some((action) => action.actionRef._id === actionId);
    return isInModel;
  }, [modeleActions]);

  const fetchlisteActions = useCallback(async () => {
    try {
      const response = await customAxios.get(`/api/actions`);
      const actionsIDs = response.data;

      logger.log("GestionActions: fetchlisteActions", modeleId, response.data);

      if (actionsIDs && actionsIDs.length) {
        let data = await Promise.all(actionsIDs.map(async (action) => {
          const stats = await fetchActionStats(action._id);
          return {
            ...action,
            inModel: isActionInModel(action._id),
            modelesCount: stats.modelesCount,
            projetsCount: stats.projetsCount
          };
        }));

        logger.log("GestionActions: fetchlisteActions sortField", modeleId, sortField);

        data = data.sort((a, b) => {
          if (sortField === "inModel") {
            if (a.inModel && !b.inModel) return sortOrder === "asc" ? -1 : 1;
            if (!a.inModel && b.inModel) return sortOrder === "asc" ? 1 : -1;
            return 0;
          } else if (["modelesCount", "projetsCount"].includes(sortField)) {
            const aValue = a[sortField] || 0;
            const bValue = b[sortField] || 0;
            return sortOrder === "asc" ? aValue - bValue : bValue - aValue;
          } else if (sortField === "signature" || sortField === "destinatairesModifiables") {
            // Gestion des booléens : false < true
            return sortOrder === "asc"
              ? a[sortField] - b[sortField]
              : b[sortField] - a[sortField];
          } else {
            const aValue = a[sortField] || "";
            const bValue = b[sortField] || "";
            return aValue.localeCompare(bValue, undefined, { numeric: true }) * (sortOrder === "asc" ? 1 : -1);
          }
        });

        logger.log("GestionActions: fetchlisteActions data", data);
        setListeActions(data);
        extractRubriques(data);
      }
    } catch (error) {
      if (error.response && error.response.status === 401) {
      } else {
        console.error(error);
      }
    }
  }, [sortField, sortOrder, isActionInModel, extractRubriques]);

  useEffect(() => {
    setSortField("inModel");
    setSortOrder("asc");
    fetchModeleActions();
  }, [modeleId, fetchModeleActions]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  useEffect(() => {
    // if (modeleId) {
    //   fetchlisteActions();
    // }

    fetchlisteActions();
    if (newAction) {
      handleAddAction();
      handleAddNewAction(false);
    }


    const socket = io(process.env.REACT_APP_SOCKET_URL, {
      path: '/ws',
      auth: {
        token: Cookies.get("userTokenAcces")
      }
    });

    socket.on("updateModèle", () => {
      fetchModeleActions();
      fetchlisteActions();
    });

    return () => {
      socket.off("updateModèle");
      socket.disconnect();
    };
  }, [newAction, modeleId, fetchModeleActions, fetchlisteActions]);

  const handleAddRubrique = (e) => {
    if (e.target.value === "ajouter") {
      const newRubriqueName = prompt("Entrez le nom de la nouvelle rubrique:");
      if (newRubriqueName && newRubriqueName.trim() && !rubriques.includes(newRubriqueName.trim())) {
        const updatedRubriques = [...rubriques, newRubriqueName.trim()]
          .filter(Boolean)
          .sort((a, b) => a.localeCompare(b));

        setRubriques(updatedRubriques);
        setEditFormData({ ...editFormData, rubrique: newRubriqueName.trim() });
        e.target.value = newRubriqueName.trim();
      } else if (newRubriqueName) {
        alert("Cette rubrique existe déjà ou le nom est invalide.");
      }
    } else {
      setEditFormData({ ...editFormData, rubrique: e.target.value });
    }
  };

  const handleSort = (field) => {
    setSortOrder(sortField === field && sortOrder === "asc" ? "desc" : "asc");
    setSortField(field);
    fetchlisteActions();
  };

  const forceSortOnDescription = () => {
    setSortField("description");
    setSortOrder("asc");
  };

  const handleSortByInput = () => {
    setSortField("inModel");
    setSortOrder(sortOrder === "asc" ? "desc" : "asc");
  };

  const getTooltipContent = (field) => {
    switch (field) {
      case "SUPPRESSION":
        return "Suppression de l'action";
      case "DESCRIPTION":
        return "Description détaillée de l'action";
      case "RUBRIQUE":
        return "Rubrique Principale.";
      case "PRÉFIXE":
        return "Préfixe du fichier stocké.";
      case "TYPE":
        return "Type d'action";
      case "CIBLE":
        return "Vue des étapes";
      case "SIGNATURE":
          return "Signature exigée pour cette action";
      case "DEST":
        return "Destinataires de l'action";
      case "FICHIER":
        return "Action associée à un fichier";
      case "DATE":
        return "Action associée à une date";
      case "TEXT":
        return "Action associée à un commentaire";
      case "OUI/NON":
        return "Action associée à Oui ou Non";
      case "ACTIONS":
        return "Editions de l'action";
      case "PROJETS CLIENTS":
        return "Projets attachés à l'action";
      case "MODÈLES":
        return "Modèles attachés à l'action";
      case "ACTION_ACTIVE":
        return "Action dans modèle ou pas?";
      default:
        return "";
    }
  };

  const tooltips = [
    "SUPPRESSION",
    "DESCRIPTION",
    "PRÉFIXE",
    "TYPE",
    "SIGNATURE",
    "DESTINATAIRE",
    "CIBLE",
    "RUBRIQUE",
    "ACTIONS",
    "ACTION_ACTIVE",
    "MODÈLES",
    "PROJETS CLIENTS"
  ].map((field, index) => (
    <span key={`${field}-${index}`}>
      <Tooltip
        key={`${field}-${index}`}
        id={`tooltip-${field}`}
        effect="solid"
        place="top"
        className="custom-tooltip"
      />
    </span>
  ));

  const renderActionIcon = (type, key) => {
    const tooltipContent = getTooltipContent(type);

    return (
      <span>
        {(() => {
          switch (type) {
            case "FICHIER":
              return (
                <BsFileText
                  data-tooltip-id={`tooltip-${type}-${key}`}
                  size="1.3em"
                />
              );
            case "DATE":
              return (
                <BsCalendar3
                  data-tooltip-id={`tooltip-${type}-${key}`}
                  size="1.2em"
                />
              );
            case "TEXT":
              return (
                <BsTextLeft
                  data-tooltip-id={`tooltip-${type}-${key}`}
                  size="1.3em"
                />
              );
            case "OUI/NON":
              return (
                <BsToggleOn
                  data-tooltip-id={`tooltip-${type}-${key}`}
                  size="1.3em"
                />
              );
            default:
              return <span>-</span>;
          }
        })()}
        <Tooltip
          id={`tooltip-${type}-${key}`}
          place="top"
          effect="solid"
          className="custom-tooltip"
        >
          {tooltipContent}
        </Tooltip>
      </span>
    );
  };

  const handleDelete = async (id) => {
    const userConfirmed = window.confirm("Êtes-vous sûr de vouloir supprimer cette action?");
    if (userConfirmed) {
      try {
        await customAxios.delete(`/api/actions/${id}`);
        await customAxios.delete(`/api/actions/etapes-modeles/${id}`);
        fetchlisteActions();
      } catch (error) {
        console.error("Erreur lors de la suppression de l'action:", error);
      }
    } else {
      logger.log("Suppression annulée par l'utilisateur.");
    }
  };

  const handleEditClick = (index, action) => {
    setEditModeIndex(index);
    setEditFormData(action);
  };

  const SortableTableHeader = ({ field, children }) => {
    return (
      <th
           className="rotated-header"
        onClick={() => handleSort(field)}
        data-tooltip-id={`tooltip-${children}`}
        data-tooltip-content={getTooltipContent(`${children}`)}
        style={{ textAlign: "center", cursor: "pointer" }}
      >
        <span style={{ fontWeight: 500 }}>{children}</span>
        {sortField === field &&
          (sortOrder === "asc" ? <BsArrowUp /> : <BsArrowDown />)}
      </th>
    );
  };

  const handleMultiSelectChange = (e, index, fieldName) => {
    const selectedValues = Array.from(e.target.selectedOptions).map(
      (option) => option.value
    );

    setEditFormData({ ...editFormData, [fieldName]: selectedValues });
  };

  const handleFieldChange = (e, field) => {
    setEditFormData({ ...editFormData, [field]: e.target.value });
  };

  const filteredActions = useMemo(() => {

    logger.log("GestionActions: filteredActions ", listeActions);

    const result = listeActions.filter((action) => {
      const searchTermLowerCase = searchTerm.toLowerCase();

      const vueActionMatches =
        Array.isArray(action.vueAction) &&
        action.vueAction.some((vue) =>
          vue.toLowerCase().includes(searchTermLowerCase)
        );

      return (
        (action.description &&
          action.description.toLowerCase().includes(searchTermLowerCase)) ||
        (action.prefixe &&
          action.prefixe.toLowerCase().includes(searchTermLowerCase)) ||
        (action.rubrique &&
          action.rubrique.toLowerCase().includes(searchTermLowerCase)) ||
        vueActionMatches ||
        (action.type &&
          action.type.toLowerCase().includes(searchTermLowerCase))
      );
    });

    return result;
  }, [listeActions, searchTerm]);

  const handleSaveClick = async () => {
    if (editModeIndex != null) {
      if (editFormData._id) {
        try {
          await customAxios.put(
            `/api/actions/${editFormData._id}`,
            editFormData
          );
        } catch (error) {
          console.error("Erreur lors de la mise à jour de l'action:", error);
        }
      } else {
        try {
          await customAxios.post(
            `/api/actions/add`,
            editFormData
          );
        } catch (error) {
          console.error("Erreur lors de l'ajout de l'action:", error);
        }
      }

      fetchlisteActions();
    }

    setEditModeIndex(null);
    setEditFormData({});
    forceSortOnDescription();
  };

  const handleCancelClick = () => {
    if (editModeIndex != null) {
      if (!editFormData._id) {
        setListeActions((prevActions) =>
          prevActions.filter((_, index) => index !== editModeIndex)
        );
      }
    }

    setEditModeIndex(null);
    setEditFormData({});
  };

  const handleDuplicateClick = (index, action) => {
    const newAction = {
      ...action,
      _id: undefined,
      description: `${action.description} (Copie)`,
    };

    const newActions = [
      ...listeActions.slice(0, index + 1),
      newAction,
      ...listeActions.slice(index + 1),
    ];

    setListeActions(newActions);
    setEditModeIndex(index + 1);
    setEditFormData(newAction);
  };

  // On clicque sur la checkbox pour ajouter ou supprimer une action du modèle
  const handleCheckboxChange = async (event, actionId) => {
    const isChecked = event.target.checked;
    logger.log("GestionActions: handleCheckboxChange isChecked", isChecked, " modeleActions ", modeleActions, " actionId ", actionId, "modeleActions ", modeleActions);
    try {
      let updatedEtapes = [], etapeDuModeleASupprimer;

      if (isChecked) {
        logger.log("GestionActions: handleCheckboxChange on doit ajouter une nouvelle étape avec actionId", actionId);
       
        const response = await customAxios.get(`/api/actions/${actionId}`);
        updatedEtapes = [...modeleActions, {
          // Il faut récupérer actionref par rapport à actionId
          actionRef: response.data, 
          actionRegles: [] }];
      } else {

        etapeDuModeleASupprimer = modeleActions.filter(
          (action) => action.actionRef._id === actionId
        );
        // On doit supprimer l'action actionId des étapes du modèle
        logger.log("GestionActions: handleCheckboxChange on doit supprimer l'étape qui contient actionId", actionId);
        updatedEtapes = modeleActions.filter(
          (action) => action.actionRef._id !== actionId
        );
      }

      updatedEtapes = updatedEtapes.map((etape, index) => ({
        ...etape,
        ordre: index + 1,
      }));

      logger.log("GestionActions: handleCheckboxChange modeleId", modeleId, "updatedEtapes ", updatedEtapes);
      let response;

      if (isChecked) {
        // On ajoute l'étape dans les étapes du modèle et dans tous les projets qui utilisent ce modèle
        response = await customAxios.patch(
          `/api/modeles/addTacheAuModele/${modeleId}/${actionId}`,
          {
            etapes: updatedEtapes,
          }
        );
      
      } else {
        response = await customAxios.patch(
          `/api/modeles/removeEtapeAuModele/${modeleId}/${etapeDuModeleASupprimer[0]._id}`,
          {
            etapes: updatedEtapes,
          }
        );


      }

      // On récupère toutes les étapes détaillées du modèle
      const etapesPromises = response.data.etapes.map((etapeId) => fetchEtapeDetails(etapeId));
      const etapesDetails = await Promise.all(etapesPromises);

      setEtapesModele(etapesDetails);

    } catch (error) {
      console.error("Erreur lors de la mise à jour du modèle:", error);
    }
  };

  return (
    <div style={{ zIndex: "0" }}>
      <div
        className="titre-zone2"
        style={{
          display: "flex",
          justifyContent: "space-around",
          alignItems: "center",
          width: "90%",
        }}
      >
        <h5>GESTION DES ACTIONS</h5>
      </div>

      <table style={{ width: "100%" }}>
        <thead className="table-header">
          <tr>
            {type === "ADMIN MODÉLES" && (
              <th
                className="rotated-header"
                style={{ cursor: "pointer" }}
                data-tooltip-id={`tooltip-ACTION_ACTIVE`}
                data-tooltip-content={getTooltipContent("ACTION_ACTIVE")}
                onClick={handleSortByInput}
              >
                <BsEye size="1.2em" />
              </th>
            )}

            <SortableTableHeader field="description">
              DESCRIPTION
            </SortableTableHeader>

            {type === "administration" && (
              <SortableTableHeader field="prefixe">PRÉFIXE</SortableTableHeader>
            )}
            <SortableTableHeader field="type">TYPE</SortableTableHeader>
            <SortableTableHeader field="signature">SIGNATURE</SortableTableHeader>
            <SortableTableHeader field="destinatairesModifiables">DESTINATAIRE</SortableTableHeader>
            <SortableTableHeader field="vueAction">VUE</SortableTableHeader>

            {type === "administration" && (
              <SortableTableHeader field="rubrique">RUBRIQUE</SortableTableHeader>
            )}
            <SortableTableHeader field="modelesCount">MODÈLES</SortableTableHeader>
            <SortableTableHeader field="projetsCount">PROJETS</SortableTableHeader>

            {type === "administration" && (
              <th
                className="rotated-header"
                data-tooltip-id={`tooltip-ACTIONS`}
                data-tooltip-content={getTooltipContent("ACTIONS")}
                style={{ textAlign: "center" }}
              >
                ACTIONS
              </th>
            )}
          </tr>
        </thead>

        <tbody>
          {filteredActions.map((action, index) => (
            <tr
              className={index % 2 === 0 ? "bg-custom" : ""}
              key={`${action._id}-${index}`}
            >
              {type === "ADMIN MODÉLES" && (
                <td
                  style={{
                    // width: "8%",
                    width: "25px",
                    borderRadius: "0 6px 6px 0",
                  }}
                >
                  <>
                    <input
                      type="checkbox"
                      checked={isActionInModel(action._id)}
                      onChange={(e) => handleCheckboxChange(e, action._id)}
                      style={{ margin: "0 5px" }}
                    />
                  </>
                </td>
              )}
              <td
                style={{
                  borderRadius: "6px 0 0 6px",
                  // paddingLeft: "10px",
                }}
              >
                {editModeIndex === index ? (
                  <input
                    ref={inputRef}
                    type="text"
                    value={editFormData.description}
                    onChange={(e) => handleFieldChange(e, "description")}
                  />
                ) : (
                  action["description"]
                )}
              </td>
  
              {type === "administration" && (
                <td style={{ fontSize: "var(--fs-plus-petite)", padding: "4px" }}>
                  {editModeIndex === index ? (
                    <input
                      type="text"
                      value={editFormData.prefixe}
                      onChange={(e) => handleFieldChange(e, "prefixe")}
                    />
                  ) : (
                    action["prefixe"]
                  )}
                </td>
              )}

              <td
                style={{
                  textAlign: "center",
                  fontSize: "var(--fs-plus-petite)",
                }}
              >
                {editModeIndex === index ? (
                  <select
                    value={editFormData.type}
                    onChange={(e) => handleFieldChange(e, "type")}
                  >
                    {tousLesTypesdActions.map((typeAction, index) => (
                      <option key={`${typeAction}-${index}`} value={typeAction}>
                        {typeAction}
                      </option>
                    ))}
                  </select>
                ) : (
                  renderActionIcon(action["type"], action._id)
                )}
              </td>

              <td style={{ textAlign: "center" }}>
                  {editModeIndex === index ? (
                    <select
                      value={editFormData.signature ? "true" : "false"} // Assurez-vous que la valeur est une chaîne "true" ou "false"
                      onChange={(e) =>
                        setEditFormData({
                          ...editFormData,
                          signature: e.target.value === "true", // Convertir en booléen
                        })
                      }
                      style={{
                        display: "block",
                        width: "100%",
                        height: "100%",
                        fontSize: "var(--fs-plus-petite)",
                      }}
                    >
                      <option value="true">Oui</option>
                      <option value="false">Non</option>
                    </select>
                  ) : action["signature"] ? (
                    <BsCheckCircle
                      data-tooltip-id="tooltip-BsCheckCircle"
                      data-tooltip-content="Signature nécessaire"
                    />
                  ) : (
                    <BsXCircle
                      data-tooltip-id="tooltip-BsCheckCircle"
                      data-tooltip-content="Signature pas nécessaire"
                    />
                  )}

                  <Tooltip
                    id="tooltip-BsCheckCircle"
                    place="top"
                    effect="solid"
                    className="custom-tooltip"
                  />
                </td>
              <td style={{ textAlign: "center" }}>
                {editModeIndex === index ? (
                    <select
                    value={editFormData.destinatairesModifiables ? "true" : "false"}
                    onChange={(e) =>
                      setEditFormData({
                        ...editFormData,
                        destinatairesModifiables: e.target.value === "true",
                      })
                    }
                    style={{
                      display: "block",
                      width: "100%",
                      height: "100%",
                      fontSize: "var(--fs-plus-petite)",
                    }}
                  >
                    <option value="true">Oui</option>
                    <option value="false">Non</option>
                  </select>
                ) : action["destinatairesModifiables"] ? (
                  <BsCheckCircle
                    data-tooltip-id="tooltip-BsCheckCircle"
                    data-tooltip-content="Destinataires nécessaires"
                  />
                ) : (
                  <BsXCircle
                    data-tooltip-id="tooltip-BsCheckCircle"
                    data-tooltip-content="Destinataires pas nécessaire"
                  />
                )}

                <Tooltip
                  id="tooltip-BsCheckCircle"
                  place="top"
                  effect="solid"
                  className="custom-tooltip"
                />
              </td>

              <td style={{ textAlign: "center" }}>
                {editModeIndex === index ? (
                  <select
                    value={editFormData.destinatairesModifiables ? "true" : "false"} // Assurez-vous que la valeur est une chaîne
                    onChange={(e) =>
                      setEditFormData({
                        ...editFormData,
                        destinatairesModifiables: e.target.value === "true", // Convertir en booléen
                      })
                    }
                    style={{
                      display: "block",
                      width: "100%",
                      height: "100%",
                      fontSize: "var(--fs-plus-petite)",
                    }}
                  >
                    <option value="true">Oui</option>
                    <option value="false">Non</option>
                  </select>
                ) : action["destinatairesModifiables"] ? (
                  <BsCheckCircle
                    data-tooltip-id="tooltip-BsCheckCircle"
                    data-tooltip-content="Destinataires nécessaires"
                  />
                ) : (
                  <BsXCircle
                    data-tooltip-id="tooltip-BsCheckCircle"
                    data-tooltip-content="Destinataires pas nécessaire"
                  />
                )}

                <Tooltip
                  id="tooltip-BsCheckCircle"
                  place="top"
                  effect="solid"
                  className="custom-tooltip"
                />
              </td>

              {type === "administration" && (
                <td
                  style={{
                    fontSize: "var(--fs-plus-petite)",
                    paddingLeft: "15px",
                  }}
                >
                  {editModeIndex === index ? (
                    <select
                      value={editFormData.rubrique}
                      onChange={(e) => handleFieldChange(e, "rubrique")}
                      onBlur={(e) => handleAddRubrique(e)}
                      style={{
                        width: "100%",
                        padding: "0.375rem 0.75rem",
                        lineHeight: "1.5",
                        borderColor: "#ced4da",
                      }}
                    >
                      {rubriques.map((rubrique, index) => (
                        <option key={`${rubrique}-${index}`} value={rubrique}>
                          {rubrique}
                        </option>
                      ))}
                      <option value="ajouter">
                        Ajouter une nouvelle rubrique...
                      </option>
                    </select>
                  ) : (
                    action["rubrique"]
                  )}
                </td>
              )}

              <td
                data-tooltip-id={`TooltipCompteurModeles-${index}`}
                data-tooltip-content="Nombre de modèles utilisant cette tâche"
                style={{ textAlign: "center"}}
              >
                <span
                  className={`badge ${action.modelesCount > 0 ? 'bg-rouge bg-gradient' : 'bg-vert bg-gradient'}`} 
                >
                  {action.modelesCount}
                </span>
                <Tooltip
                  id={`TooltipCompteurModeles-${index}`}
                  place="top"
                  effect="solid"
                  className="custom-tooltip"
                />
              </td>
              <td 
                data-tooltip-id={`TooltipCompteurProjets-${index}`}
                data-tooltip-content="Nombre de projets utilisant cette tâche"
                style={{ textAlign: "center" }}
              >
                <span
                  className={`badge ${action.projetsCount > 0 ? 'bg-jaune bg-gradient' : 'bg-vert bg-gradient'}`} 
                >
                  {action.projetsCount}
                </span>
                <Tooltip
                  id={`TooltipCompteurProjets-${index}`}
                  place="top"
                  effect="solid"
                  className="custom-tooltip"
                />
              </td>

              {type === "administration" && (
                <td
                  style={{
                    borderRadius: "0 6px 6px 0",
                  }}
                >
                  {editModeIndex === index ? (
                    <>
                      <BsCheck
                        size="1.2em"
                        onClick={() => handleSaveClick(action._id)}
                        style={{ cursor: "pointer", margin: "0 5px" }}
                      />
                      <BsX
                        size="1.2em"
                        onClick={handleCancelClick}
                        style={{ cursor: "pointer", margin: "0 5px" }}
                      />
                    </>
                  ) : (
                    <>
                      <BsFiles
                        size="1.2em"
                        data-tooltip-id={`duplicateTip-${index}`}
                        data-tooltip-content={"Duplication de l'action."}
                        onClick={() => handleDuplicateClick(index, action)}
                        style={{ cursor: "pointer", margin: "0 5px" }}
                      />
                      <Tooltip
                        id={`duplicateTip-${index}`}
                        place="top"
                        effect="solid"
                        className="custom-tooltip"
                      />

                      <BsPencil
                        size="1.2em"
                        style={{ cursor: "pointer", margin: "0 5px" }}
                        data-tooltip-id={`editTip-${index}`}
                        data-tooltip-content={"Edition de l'action."}
                        onClick={() => handleEditClick(index, action)}
                      />
                      <Tooltip
                        id={`editTip-${index}`}
                        place="top"
                        effect="solid"
                        className="custom-tooltip"
                      />

                      {action.projetsCount > 0 ? (
                        <BsTrash
                          size="1.2em"
                          data-tooltip-id={`poubelleTip-${index}`}
                          data-tooltip-content={"Impossible de supprimer cette action car elle est utilisée dans des projets."}
                          style={{ cursor: "not-allowed", margin: "0 5px", color: "grey" }}
                        />
                      ) : (
                        <BsTrash
                          size="1.2em"
                          data-tooltip-id={`poubelleTip-${index}`}
                          data-tooltip-content={"Suppression de l'action."}
                          onClick={() => handleDelete(action._id, action.projetsCount)}
                          style={{ cursor: "pointer", margin: "0 5px", color: "grey" }}
                        />
                      )}
                      <Tooltip
                        id={`poubelleTip-${index}`}
                        place="top"
                        effect="solid"
                        className="custom-tooltip"
                      />
                    </>
                  )}
                </td>
              )}
            </tr>
          ))}
        </tbody>
      </table>
      {tooltips}
    </div>
  );
}
