import React, { useContext, useState, useEffect } from "react";

import { ListeEtapes } from "./Etapes/ListeEtapes";
import { ProjetByProjetId } from "./Projets/ProjetByProjetId";
import { ProjetNomByProjetId } from "./Projets/ProjetNomByProjetId";

import { GetModeleById } from "./Modeles/GetModeleById";
import { AfficheTiersByTiersId } from "./Tiers/AfficheTiersByTiersId";

import { UtilisateurById } from "./Utilisateurs/UtilisateurById";
import { ProjetNote } from "./Projets/ProjetNote";
import "./css/Global.css";
import "./css/Zone3.css";

import {Siret} from "./Siret/Siret"
import { AuthContext } from "../contexts/AuthProvider";
import { SyntheseGlobale } from "./Synthèses/SyntheseGlobale";
import { ListeDestinataires } from "./Destinataires/ListeDestinataires";
import { ListeFichiers } from "./Fichiers/ListeFichiers";
import { FiChevronRight, FiChevronDown } from "react-icons/fi";


// export function Zone3() {}

export function Zone3() {
  const {
    userId,
    utilisateurId,
    tiersId,
    selectedMenu,
    projetId,
    modeleId,
    debugAffichage,
    isAgence,
    isPrestataire,
    isDestinatairesOpen,
    selectedArchive,
    handleToggleDestinataires
  } = useContext(AuthContext);
  const [utilisateur] = useState([]);

  // useEffect(() => {
  
  // }, [userId, isAgence, isPrestataire, selectedMenu, selectedArchive, projetId, modeleId, tiersId, isDestinatairesOpen, utilisateurId]); // Ajoutez d'autres dépendances si nécessaire

  const toggleDestinataires = () => {

    handleToggleDestinataires(!isDestinatairesOpen);
    // logger.log(
    //   "zone4 toggleDestinataire isDestinatairesOpen",
    //   isDestinatairesOpen
    // );
  };

  // logger.log("Zone3 selectedMenu", selectedMenu, "selectedArchive",selectedArchive, " projetId ", projetId);

  return (
      <div className={`zone-3  marge-zones }`}>
    
      {/* Juste pour savoir où sont les zones */}
      {debugAffichage && <h4 className="zone-title">Zone 3</h4>}

      {projetId &&
        (isAgence || isPrestataire) &&  
        (selectedMenu === "PROJETS CLIENTS" || selectedMenu === "ALL PROJETS" ||  selectedMenu === "PROJETS PARTENAIRES" ||  selectedMenu === "PROJETS FOURNISSEURS"  || selectedMenu === "PROJETS PRESTATAIRES" || selectedMenu === "PROJET AGENCE" )  && 
        (
        
          <div>
            {/* On récupère toutes les informations du projets */}
            <ProjetByProjetId />
            <ProjetNomByProjetId />  
            <ProjetNote />
            <hr />
            <ListeEtapes />  {/* Affiche toutes les étapes du projet */}
            <hr />
            <ListeFichiers AllAgences={false} /> {/* On liste tous les fichiers du projetId en cours */}

          </div>
        )}

        {selectedMenu.includes("PROJET") && !projetId && (
          <div className="form-group cadre-zone3">
            <h5 className="titre-zone3" style={{ textAlign: "center" }}>
              Aucun projet sélectionné
            </h5>
          </div>
        )}


      { selectedMenu === "NEW AGENCE"  && (
                <>
                {/* Test Siret */}
                  <Siret />
                </>
              )}

 


        {selectedMenu === "REQUÊTES" && 
        (
          <>
            <div  className="cadre-zone3 d-flex justify-content-center align-items-center cursor-pointer">
              <div className="rules-text" onClick={toggleDestinataires}>
                    VOIR DESTINATAIRES
                    </div>
              <div
                onClick={toggleDestinataires}
                className="icon-size"
                data-tooltip-id="TooltipGDestinataires"
                data-tooltip-content="Cliquez pour Sélectionner les Destinataires. Sélectionner une étape éligible."
              >
                {isDestinatairesOpen ? (
                  <FiChevronDown />
                ) : (
                  <FiChevronRight />
                )}
              </div>
            </div>
            <ListeDestinataires typeTiers={["PARTENAIRE", "AGENCE","PRESTATAIRE"]} />
          </>
        )}



      {selectedMenu === "SYNTHÈSES" && (
        <>
          {/* Synthèse juste sur les projets de userId*/}
          <SyntheseGlobale AgenceAuComplet={false} />
        </>
      )}
      {selectedMenu === "ALL SYNTHÈSES" && (
        <>
          {/* Synthèse sur tous les projets de l'agence */}
          <SyntheseGlobale AgenceAuComplet={true} />
        </>
      )}

      {modeleId && selectedMenu === "ADMIN MODÈLES" && (
        <div className="custom-heading">
          <h5>
            <GetModeleById />
          </h5>
        </div>
      )}

      {(selectedMenu === "PARTENAIRES" ||
          selectedMenu === "CLIENTS" ||
          selectedMenu === "ALL CLIENTS" ||
          selectedMenu === "ALL PARTENAIRES" ||
          selectedMenu === "ADMIN PARTENAIRES" ||
          selectedMenu === "ALL PRESTATAIRES" ||
          selectedMenu === "ADMIN AGENCES" ||
          selectedMenu === "ADMIN GÉNÉRAL" ) && (
          <>
            <AfficheTiersByTiersId />
          </>
        )}


    </div>
  );
}
