import React, { useEffect, useState, useContext } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "./../css/Global.css";

import { AuthContext } from "../../contexts/AuthProvider";
import customAxios from "../Utilities/getAxiosToken";
import { TfiEmail } from "react-icons/tfi";
import { BsDashCircle, BsPlusCircle } from "react-icons/bs";
import { Tooltip } from "react-tooltip";
import { FiChevronRight, FiChevronDown } from "react-icons/fi";
// import ReactTooltip from "react-tooltip"; // Assurez-vous d'utiliser le nom correct de l'importation

// Utilisé depuis un tiers par exemple un partenaire/
export function ListeContactsByTiers({ typeFormat }) {
 
  const { tiersId, handleLogout, handleUtilisateurId, handleAddNewUser } =
    useContext(AuthContext);
  const [contacts, setContacts] = useState([]);
  const [selectedContacts, setSelectedContacts] = useState([]);
  const [selectedContactId, setSelectedContactId] = useState(null);
  const [isContactsOpen, setIsContactsOpen] = useState(true);


  const toggleContacts = () => {
    handleUtilisateurId(""); //On reset l'utilisateurId en cours
    setIsContactsOpen(!isContactsOpen);
  };
  // logger.log("ListeContactsByTiers DEBUT" );

  // Fonction pour récupérer les détails d'un utilisateur par son ID
  const fetchUtilisateurByid = async (utilisateurId) => {
    try {
      if (utilisateurId) {

        const params = new URLSearchParams({ isActif: true });
        const response = await customAxios.get(
          `/api/utilisateurs/${utilisateurId}?${params}`
        );
        // logger.log("ListeContactsByTiers fetchUtilisateurByid",response.data );
        return response.data;
      }
    } catch (error) {
      if (error.response && error.response.status === 401) {
        handleLogout();
      } else {
        console.error(
          "Erreur lors de la récupération des détails de l'utilisateur:",
          error
        );
      }
      return null; // Retourne null en cas d'erreur
    }
  };

  useEffect(() => {
    const fetchContacts = async () => {
      // logger.log("ListeContactsByTiers fetchContacts DEBUT tiersId", tiersId);
      if (tiersId) {
        try {
          const params = new URLSearchParams({ isActif: true });
          const responseUtilisateurs = await customAxios.get(
            `/api/utilisateurs/tiers/${tiersId}?${params}`
          );
  
          const contactIds = responseUtilisateurs.data.utilisateurs;
          const nbUtilisateurs = responseUtilisateurs.data.nbUtilisateurs;

          // logger.log("ListeContactsByTiers fetchContacts de tiersId", tiersId, nbUtilisateurs, contactIds);

          // [Log] ListeContactsByTiers fetchContacts de tiersId – "65bf61d2ace76cbd83c42f57" (main.9b950ebaffffa1dde9ca.hot-update.js, line 80)
          // Array (2)
          // 0 {observations: "", _id: "65bf61d2ace76cbd83c42f60", nom: "LEENART", prenom: "Stéphane", telephone: "06 31 55 42 02", …}
          // 1 {observations: "", _id: "65bf61d2ace76cbd83c42f75", nom: "CHEMMACHE", prenom: "Anissa", telephone: "06 18 71 22 08", …}

          if (nbUtilisateurs === 0) {
            // Gérer le cas où il n'y a pas d'utilisateurs
            setContacts([]);
            // setIsLoading(false);
            logger.log("Aucun contact à afficher");
            return; // Sortir de la fonction car il n'y a pas de contacts à traiter
          }

          // Extraire les identifiants des utilisateurs
          const userIds = contactIds.map((contact) => contact._id);
          const contactDetailsPromises = userIds.map(fetchUtilisateurByid);
          const contactDetails = await Promise.all(contactDetailsPromises);

          setContacts(contactDetails.filter((contact) => contact !== null));

        } catch (error) {
          if (error.response && error.response.status === 401) {
            handleLogout();
          } else {
            console.error(
              "Erreur lors de la récupération des contacts :",
              error
            );
          }
        }
      } else {
        // Pas de contacts
        setContacts([]);
        // setIsLoading(false);
      }
    };

    fetchContacts();
  }, [ tiersId, handleLogout]);


  const archiveContact = (contactId) => {
    const updatedSelectedContacts = [...selectedContacts];
    if (updatedSelectedContacts.includes(contactId)) {
      updatedSelectedContacts.splice(
        updatedSelectedContacts.indexOf(contactId),
        1
      );
    } else {
      updatedSelectedContacts.push(contactId);
    }
    setSelectedContacts(updatedSelectedContacts);
  };

  const toggleContactDetails = (contactId) => {
    const newSelectedContactId =
      selectedContactId === contactId ? null : contactId;
    logger.log(
      "ListeContactsByTiers toggleContactDetails utilisateurId",
      contactId,
      selectedContactId,
      newSelectedContactId
    );

    setIsContactsOpen(!isContactsOpen);
    setSelectedContactId(newSelectedContactId);
    handleUtilisateurId(newSelectedContactId ? contactId : ""); // Mettre à jour l'ID de l'utilisateur en fonction du nouvel état
  };

  const handleDeactivateUser = async (userId) => {
    try {
      const response = await customAxios.patch(`/api/utilisateurs/${userId}`, { isActif: false });

      logger.log(`ListeContactsByTiers: Utilisateur désactivé: ${response.data.prenom} ${response.data.nom}`);
      // Mettez à jour l'état ou effectuez d'autres actions nécessaires après la désactivation
    } catch (error) {
      console.error(`Erreur lors de la désactivation de l'utilisateur: ${error.message}`);
    }
  };
  // Ajouter une fonction pour gérer la confirmation
function handleDeactivateUserWithConfirmation(userId) {
  const confirmation = window.confirm("Êtes-vous sûr de vouloir supprimer cet utilisateur ?");
  if (confirmation) {
    handleDeactivateUser(userId);
  }
}

  return contacts.length > 0 ? (
      // <div className="d-flex flex-column Tooltip cadre-zone4">
    <div className="d-flex flex-column Tooltip">
      <table className="contenu-zone4 center-content">
        <tbody>
          {contacts.map((contact, index) => (
            <tr
              data-for={`tooltipContact-${index}`}
              data-tip={`Cliquez pour obtenir des détails ou modifier le contact: ${contact._id}`}
              key={contact._id} // Utilisation de contact._id comme clé pour une meilleure gestion des listes
              className={index % 2 === 1 ? "bg-custom" : "" }
            >
              <td>
                {contact.prenom} {contact.nom}
              </td>
              {typeFormat !== "COURT" && (
                <>
                  <td>
                    {typeFormat === "COURT" ? (
                      <i className="fa fa-phone"></i>
                    ) : (
                      <>
                        <i className="fa fa-phone"></i> {contact.telephone}
                      </>
                    )}
                  </td>
                  <td>
                    <TfiEmail />
                  </td>
                </>
              )}
              <td>
                <span
                  onClick={() => toggleContactDetails(contact._id)} >
                  {isContactsOpen ? (
                    <FiChevronRight className="couleur-icon double-size souris-pointeur" />
                  ) : (
                    <FiChevronDown className="couleur-icon double-size souris-pointeur" />
                  )}
                </span>
                <span style={{ paddingRight: "10px" }}></span>
                </td>

                <td>
                  {contacts.length > 1 && 
                  <BsDashCircle 
                    data-tooltip-id={`TooltipContacts`}
                    data-tooltip-content={"Supprimer le Contact"}
                    onClick={() => handleDeactivateUserWithConfirmation(contact._id)} 
                    />}
                  <span style={{ paddingRight: "4px" }}></span>
                  

                  <BsPlusCircle 
                      data-tooltip-id={`TooltipContacts`}
                      data-tooltip-content={"Ajouter un Contact"}
                      onClick={() => handleAddNewUser(true)}
                    />   
              </td>
              
              <Tooltip
                id={`tooltipContact-${index}`}
                place="top"
                effect="solid"
                className="custom-tooltip"
              />
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  ) : null;
}
