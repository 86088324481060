

import customAxios from "./getAxiosToken";


export const useGetTiers = () => {
 

  const getNameTiersByTiersId = async (tiersId) => {
    // Vérification si tiersId est null ou undefined
    if (tiersId == null) {
      console.warn("tiersId est null ou undefined");
      return "NomInconnu";
    }
  
    try {
      const response = await customAxios.get(`/api/tiers/${tiersId}`);
      const tiersData = response.data;
      return tiersData && tiersData.nom ? tiersData.nom : "NomInconnu";
    } catch (error) {
      console.error("Erreur lors de la récupération du nom à partir de tiersId", error);
      return "NomInconnu";
    }
  };

  const getTypeTiersByTiersId = async (tiersId) => {
    // Vérification si tiersId est null ou undefined
    if (tiersId == null) {
      console.warn("tiersId est null ou undefined");
      return "NomInconnu";
    }
  
    try {
      const response = await customAxios.get(`/api/tiers/${tiersId}`);
      const tiersData = response.data;
      return tiersData && tiersData.typeTiers ? tiersData.typeTiers : "NomInconnu";
    } catch (error) {
      console.error("Erreur lors de la récupération du type à partir de tiersId", error);
      return "NomInconnu";
    }
  };

  return {
    getNameTiersByTiersId,
    getTypeTiersByTiersId,
  };
};
