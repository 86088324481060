import React, { useEffect, useState, useContext, useCallback } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "./css/ListeProjets.css";
import "./../css/Global.css";

import { AuthContext } from "../../contexts/AuthProvider";
import customAxios from "../Utilities/getAxiosToken";
import { Tooltip } from "react-tooltip";
import useGetModeles from "../Utilities/GetModeles";
import { useGetTiers } from "../Utilities/GetTiers";
import { BsArchive } from "react-icons/bs";

import io from "socket.io-client";
import Cookies from "js-cookie";

import ContentEditableField from "../Utilities/ContentEditableField";

//On affiche les projets d'un Tiers EDB par exemple
// Souvent en Zone4
// etatProjet = "ouvert" ou "archivé" pour l'utilisateur
export function ListeProjetsByTiersId  ({ etatProjet }) {
  const { fetchModeles } = useGetModeles();
  const { getTypeTiersByTiersId } = useGetTiers();

  const [projets, setListeProjets] = useState([]);
  const [listeModelesDétaillés, setListeModelesDétaillés] = useState([]);
  const [typeTiers, setTypeTiers] = useState(null);

  const { handleProjetId, handleSelectedMenu, userId, agenceId, tiersId } =
    useContext(AuthContext);

  // pour accéder directement au projet
  const handleLabelClick = (projetId) => {
    logger.log("ListeProjetsByTiersId: handleLabelClick", projetId);
    handleProjetId(projetId);

    // Vérifier la valeur de typeTiers et appeler handleSelectedMenu en conséquence
    if (typeTiers === "CLIENT") {
      handleSelectedMenu("PROJETS CLIENTS"); // Pour simuler un reroutage
    } else if (typeTiers === "PARTENAIRE") {
      handleSelectedMenu("PROJETS PARTENAIRES"); // Pour simuler un reroutage
    } else if (typeTiers === "PRESTATAIRE") {
      handleSelectedMenu("PROJETS PRESTATAIRES"); // Pour simuler un reroutage
    } else if (typeTiers === "FOURNISSEURS") {
      handleSelectedMenu("PROJETS FOURNISSEURS"); // Pour simuler un reroutage
    } else if (typeTiers === "AGENCE") {
      handleSelectedMenu("PROJET AGENCE"); // Pour simuler un reroutage
    }
  };

  // Permet de lister les Projets de l'utilisateur et si je suis Manager, je peux lister les projets de tous les utilisateurs
  const fetchListeProjets = useCallback(async () => {
    try {

      const modeleIsActif = true; // Tous les modèles isActifs
      const response = await customAxios.get(
        `/api/projets/All/${tiersId}/${modeleIsActif}/${userId}/${etatProjet}`
      );

      const touslesProjets = response.data.projects;
      logger.log(
        "ListeProjetsByTiersId: fetchListeProjets",
        tiersId,
        touslesProjets
      );

      setListeProjets(touslesProjets);
    } catch (error) {
      console.error(error);
    }
  }, [userId, agenceId, tiersId]);

  const chargerTousLesModeles = useCallback(async () => {
    logger.log(
      "ListeProjetsByTiersId: chargerTousLesModeles tiersId",
      tiersId
    );
    if (userId && agenceId) {
      try {
        const tousLesModeles = await fetchModeles(agenceId);

        logger.log(
          "ListeProjetsByTiersId: chargerTousLesModeles",
          tousLesModeles
        );
        // On filtre les modeles adapté au typeTiers
        // On récupère le typeTiers de tiersId
        const typeTiers = await getTypeTiersByTiersId(tiersId);

        setTypeTiers(typeTiers);
        logger.log(
          "ListeProjetsByTiersId: chargerTousLesModeles typeTiers",
          typeTiers
        );
        const modelesFiltres = tousLesModeles.filter(
          (modele) => modele.cible === typeTiers
        );
        setListeModelesDétaillés(modelesFiltres);
      } catch (erreur) {
        console.error("Erreur lors de la récupération des modèles :", erreur);
      }
    }
  }, [userId, agenceId, tiersId]);

  useEffect(() => {
    if (!tiersId) return;

    fetchListeProjets();
    chargerTousLesModeles();

    logger.log(
      "ListeProjetsByTiersId: const socket = io()",
      process.env.REACT_APP_SOCKET_URL
    );

    const socket = io(process.env.REACT_APP_SOCKET_URL, {
      path: "/ws",
      auth: {
        token: Cookies.get("userTokenAcces"),
      },
    });

    socket.on("updateProjet", (data) => {
      logger.log("ListeProjetsByTiersId: webSockets nouveauProjet", data);
      fetchListeProjets();
    });
    socket.on("nouveauProjet", (data) => {
      logger.log("ListeProjetsByTiersId: webSockets nouveauProjet", data);
      fetchListeProjets();
    });
    return () => {
      logger.log("ListeProjetsByTiersId: Closing WebSocket");
      socket.off('nouveauProjet', fetchListeProjets);
      socket.off('updateProjet', fetchListeProjets);
      socket.disconnect();
  };
    // Nettoyage de la connexion à la fermeture du composant
  }, [userId, agenceId, tiersId]);

  const handleSave = async (index, newValue, key) => {
    try {
      logger.log(
        "ListeProjetsByTiersId handleSave après modification du champ ",
        key,
        index,
        newValue
      );

      const updatedProjet = { ...projets[index], [key]: newValue };
      await customAxios.patch(
        `/api/projets/alone/${updatedProjet._id}`,
        updatedProjet
      );
      const updatedProjets = [...projets];
      updatedProjets[index] = updatedProjet;
      setListeProjets(updatedProjets);
    } catch (error) {
      console.error(error);
      alert(
        "ListeProjetsByTiersIdUne erreur est survenue lors de la mise à jour de l’élément."
      );
    }
  };

  const handleArchiveClick = async (projetId, etatActuel) => {
    try {
      // Trouver le projet correspondant à projetId dans la liste actuelle des projets
      const projet = projets.find((p) => p._id === projetId);
      if (!projet) {
        console.error("Projet introuvable");
        return;
      }

      // Trouver l'accès projet pour le userId dans accesProjet
      const accesProjet = projet.accesProjet.find(
        (acces) => acces.utilisateur === userId
      );

      if (!accesProjet) {
        console.error("Accès projet pour l'utilisateur introuvable");
        return;
      }

      // Déterminer l'état actuel et le nouvel état pour l'archivage
      const etatActuel = accesProjet.etat;
      const nouvelEtat = etatActuel === "ouvert" ? "archivé" : "ouvert";

      logger.log(
        "ListeProjetsByTiersId handleArchiveClick etatActuel",
        etatActuel,
        nouvelEtat,
        projetId,
        userId
      );

      // Mettre à jour l'état du projet sur le serveur
      const response = await customAxios.put(`/api/projets/${projetId}`, {
        etat: nouvelEtat,
        userId: userId,
      });

      if (response.status === 200) {
        // Mettre à jour l'état localement pour l'utilisateur dans accesProjet
        await fetchListeProjets();
      } else {
        console.error("La mise à jour du projet a échoué.");
      }
    } catch (error) {
      console.error("Erreur lors de la mise à jour du projet:", error);
    }
  };

  return (
    <div className="d-flex flex-column cadre-zone4 Tooltip">
      {projets.length > 0 ? (
        <>
          <table className="table-zone4">
            <tbody>
              {projets.map((projet, index) => (
                <tr key={index}>
                  <td
                    className="td-nom-projet"
                    onClick={() => handleLabelClick(projet._id)}
                    data-tooltip-id={`infoProjet-${projet._id}-${index}`}
                    data-tooltip-content={"Nom du projet"}
                  >
                    {projet.nom}
                  </td>
                  <td
                    data-tooltip-id={`infoContrat-${projet._id}-${index}`}
                    data-tooltip-content={"Type de Contrat du projet"}
                  >
                    {projet.modele.nom}
                  </td>
                  <td
                    style={{ fontSize: "var(--fs-plus-petite)" }}
                    data-tooltip-id={`infoDescription-${projet._id}-${index}`}
                    data-tooltip-content={
                      "Cliquez pour modifier la Description"
                    }
                  >
                    <ContentEditableField
                      initialValue={projet.description}
                      onSave={(value) =>
                        handleSave(index, value, "description")
                      }
                    />
                  </td>

                  {typeTiers === "CLIENT" && (
                    <td
                      data-tooltip-id={`infoArchive-${projet._id}-${index}`}
                      data-tooltip-content={etatProjet === "archivé" ? "Désarchive le projet" : "Archive le projet"}
                      onClick={() => handleArchiveClick(projet._id, projet.etat)}
                      style={{ cursor: "pointer" }}
                    >
                      <BsArchive color="var(--texte-zone)" />
                    </td>
                  )}


                </tr>
              ))}
            </tbody>
          </table>

          {/* Placer les Tooltips en dehors de chaque ligne */}
          {projets.map((projet, index) => (
            <React.Fragment key={index}>
              <Tooltip
                id={`infoDescription-${projet._id}-${index}`}
                place="top"
                effect="solid"
                className="custom-tooltip"
              />
              <Tooltip
                id={`infoProjet-${projet._id}-${index}`}
                place="top"
                effect="solid"
                className="custom-tooltip"
              />
              <Tooltip
                id={`infoContrat-${projet._id}-${index}`}
                place="top"
                effect="solid"
                className="custom-tooltip"
              />
              <Tooltip
                id={`infoArchive-${projet._id}-${index}`}
                place="top"
                effect="solid"
                className="custom-tooltip"
              />
            </React.Fragment>
          ))}
        </>
      ) : null}
    </div>
  );
}
