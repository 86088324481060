import React, { useContext, useEffect, useState } from "react";
import "./css/Global.css";
import "./css/Zone4.css";
import { AuthContext } from "../contexts/AuthProvider";
import { ProjetPreviEcheancierById } from "./Projets/ProjetPreviEcheancierById";

import { UtilisateursByProjetId } from "./Projets/UtilisateursByProjetId";
import { UtilisateursByTiersId } from "./Tiers/UtilisateursByTiersId";
import { UtilisateurById } from "./Utilisateurs/UtilisateurById";
import { CreateurByProjetId } from "./Projets/CreateurByProjetId";
import { CreateurByTiersId } from "./Tiers/CreateurByTiersId";

import { ListeProjetsByTiersId } from "./Projets/ListeProjetsByTiersId";
import { ListeProjetsSansAttentes } from "./Projets/ListeProjetsSansAttentes";
import { ListeTiersByTypeTiersAndTiersId } from "./Tiers/ListeTiersByTypeTiersAndTiersId";
import { ListeDestinataires } from "./Destinataires/ListeDestinataires";
import { ListeCompétences } from "./CorpsDeMétiers/ListeCompétences";
import { ListeContactsByTiers } from "./Tiers/ListeContactsByTiers";
import { ListeProjets } from "./Tiers/ListeProjets";

import { CountTiersByTypeTiers } from "./Tiers/CountTiersByTypeTiers";
import { CountUtilisateursByTiers } from "./Utilisateurs/CountUtilisateursByTiers";
import { CountListProjetsByUserId } from "./Projets/CountListProjetsByUserId";
import { DescriptionByProjetId } from "./Projets/DescriptionByProjetId";
import { AdresseByProjetId } from "./Projets/AdresseByProjetId";

import { ReglesModeles } from "./Modeles/ReglesModeles";
import { SynthesesPreviEcheancier } from "./Synthèses/SynthesesPreviEcheancier";
import { CreerUtilisateur } from "./Utilisateurs/CreerUtilisateur";
import { FiChevronRight, FiChevronDown } from "react-icons/fi";

import { ListeRègles } from "./Règles/ListeRègles";
import { TableauPrevisionnelEtEcheancier } from "./Modeles/TableauPrevisionnelEtEcheancier";
import { GestionActions } from "./Actions/GestionActions";
import { Tooltip } from "react-tooltip";

import CheckPreviEcheancierProjets from "./Projets/CheckPreviEcheancierProjets";

export function Zone4() {
  const {
    tiersId,
    userId,
    selectedMenu,
    projetId,
    projetCible, // CLIENT, PARTENAIRE, AGENCE, PRESTATAIRE
    modeleId,
    utilisateurId,
    handleUtilisateurId,
    isDestinatairesOpen,
    handleToggleDestinataires,
    addNewUser,
  } = useContext(AuthContext);

  const [isDescriptionOpen, setIsDescriptionOpen] = useState(false);
  const [isAdresseOpen, setIsAdresseOpen] = useState(false);
  const [isPartagésOpen, setIsPartgésOpen] = useState(false);
  const [isCreateurOpen, setIsCreateurOpen] = useState(false);
  const [isCompétencesOpen, setIsCompétencesOpen] = useState(false);
  const [isContactsOpen, setIsContactsOpen] = useState(true);
  const [isProjetsOpen, setIsProjetsOpen] = useState(true);
  const [isPartageOpen, setIsPartageOpen] = useState(false);
  const [isPrestatairesOpen, setIsPrestatairesOpen] = useState(false);
  const [isPartenairesOpen, setIsPartenairesOpen] = useState(false);
  const [isClientsOpen, setIsClientsOpen] = useState(false);
  const [isFournisseursOpen, setIsFournisseursOpen] = useState(false);
  const [isProjetsOuverts, setIsProjetsEnCours] = useState(true);
  const [isProjetsArchivés, setIsProjetsArchivés] = useState(false);

  const [isPrévisionnel, setIsPrévisionnel] = useState(true);
  const [isEchéancier, setIsEchéancier] = useState(true);

  const [isRèglesOpen, setIsRèglesOpen] = useState(false);
  const [isListeRèglesOpen, setIsListeRèglesOpen] = useState(false);
  const [
    isRépartitionPréviEchéancierOpen,
    setIsRépartitionPréviEchéanciersOpen,
  ] = useState(false);

  const [isActionsOpen, setIsActionsOpen] = useState(true);

  const toggleActions = () => {
    setIsActionsOpen(!isActionsOpen);
  };

  const toggleRépartitionPréviEchéanciers = () => {
    setIsRépartitionPréviEchéanciersOpen(!isRépartitionPréviEchéancierOpen);
  };

  const toggleListeRègles = () => {
    setIsListeRèglesOpen(!isListeRèglesOpen);
  };

  const toggleRègles = () => {
    setIsRèglesOpen(!isRèglesOpen);
  };

  const togglePrévisionnel = () => {
    setIsPrévisionnel(!isPrévisionnel);
  };
  const toggleEchéancier = () => {
    setIsEchéancier(!isEchéancier);
  };
  const toggleProjetsOuverts = () => {
    setIsProjetsEnCours(!isProjetsOuverts);
  };

  const toggleProjetsArchivés = () => {
    setIsProjetsArchivés(!isProjetsArchivés);
  };

  // const toggleFournisseurs = () => {
  //   handleUtilisateurId(""); //On reset l'utilisateurId en cours
  //   setIsFournisseursOpen(!isFournisseursOpen);
  // };

  const toggleContacts = () => {
    handleUtilisateurId(""); //On reset l'utilisateurId en cours
    setIsContactsOpen(!isContactsOpen);
  };

  const togglePrestataires = () => {
    handleUtilisateurId(""); //On reset l'utilisateurId en cours
    setIsPrestatairesOpen(!isPrestatairesOpen);
  };

  const togglePartenaires = () => {
    handleUtilisateurId(""); //On reset l'utilisateurId en cours
    setIsPartenairesOpen(!isPartenairesOpen);
  };

  // const toggleModeles = () => {
  //   setIsModelesOpen(!isModelesOpen);
  // };

  const toggleClients = () => {
    handleUtilisateurId(""); //On reset l'utilisateurId en cours
    setIsClientsOpen(!isClientsOpen);
  };

  const togglePartages = () => {
    setIsPartageOpen(!isPartageOpen);
  };
  const toggleProjets = () => {
    setIsProjetsOpen(!isProjetsOpen);
  };

  const toggleDescription = () => {
    setIsDescriptionOpen(!isDescriptionOpen);
  };

  const toggleAdresse = () => {
    setIsAdresseOpen(!isAdresseOpen);
  };

  const toggleCreateur = () => {
    setIsCreateurOpen(!isCreateurOpen);
  };

  const togglePartagés = () => {
    setIsPartgésOpen(!isPartagésOpen);
  };

  // Liste des Destinatire TAG EDB asscoés à la Tache
  const toggleDestinataires = () => {
    // logger.log("zone4 toggleDestinataire isDestinatairesOpen", isDestinatairesOpen);
    handleToggleDestinataires(!isDestinatairesOpen);
    logger.log(
      "zone4 toggleDestinataire isDestinatairesOpen",
      isDestinatairesOpen
    );
  };

  const toggleCompétences = () => {
    setIsCompétencesOpen(!isCompétencesOpen);
  };

  useEffect(() => {
    logger.log("Zone 4 projetId:", projetId);
    logger.log("Zone 4 selectedMenu:", selectedMenu);
    logger.log("Zone 4 modeleId:", modeleId);
    logger.log("Zone 4 tiersId:", tiersId);
  }, [
    userId,
    projetId,
    selectedMenu,
    isDestinatairesOpen,
    modeleId,
    tiersId,
    utilisateurId,
  ]);

  return (    <div className={`zone-4 `}>
      {projetId &&
        (selectedMenu === "PROJETS CLIENTS" ||
          selectedMenu === "ALL PROJETS") && (
          <>
            <div
              className="d-flex justify-content-center align-items-center cursor-pointer"
              onClick={toggleContacts}
            >
              <div className="rules-text" onClick={toggleContacts}>
                CONTACTS
              </div>

              <div
                onClick={toggleContacts}
                className="icon-size"
                data-tooltip-id={`TooltipContacts`}
                data-tooltip-content={"Cliquez pour la liste des Contacts"}
              >
                {isContactsOpen ? <FiChevronDown /> : <FiChevronRight />}
              </div>
            </div>

            <div className="d-flex flex-column">
              {isContactsOpen && (
                <div className="cadre-zone4">
                  {/* Liste des contacts rattachés au Partenaire */}
                  <ListeContactsByTiers typeFormat="COURT" />
                  {utilisateurId && (
                    <>
                      <hr />
                      <UtilisateurById />
                    </>
                  )}
                </div>
              )}

              {addNewUser && (
                <div className="cadre-zone4">
                  <CreerUtilisateur />
                </div>
              )}

              {projetCible === "CLIENT" && (
                <>
                  <div
                    className="d-flex justify-content-center align-items-center cursor-pointer"
                    onClick={toggleDescription}
                  >
                    <div className="rules-text" onClick={toggleDescription}>
                      DESCRIPTION
                    </div>
                    <div
                      onClick={toggleDescription}
                      className="icon-size"
                      data-tooltip-id="TooltipDescription"
                      data-tooltip-content="Cliquez pour voir la description du projet"
                    >
                      {isDescriptionOpen ? (
                        <FiChevronDown />
                      ) : (
                        <FiChevronRight />
                      )}
                    </div>
                  </div>
                  {isDescriptionOpen && <DescriptionByProjetId />}

                  <div
                    className="d-flex justify-content-center align-items-center cursor-pointer"
                    onClick={toggleAdresse}
                  >
                    <div className="rules-text" onClick={toggleAdresse}>
                      ADRESSE
                    </div>
                    <div
                      onClick={toggleAdresse}
                      className="icon-size"
                      data-tooltip-id="TooltipAdresseChantier"
                      data-tooltip-content="Cliquez pour voir l'Adresse du projet"
                    >
                      {isAdresseOpen ? <FiChevronDown /> : <FiChevronRight />}
                    </div>
                  </div>
                  {isAdresseOpen && <AdresseByProjetId />}

                  <div
                    className="d-flex justify-content-center align-items-center cursor-pointer"
                    onClick={toggleCreateur}
                  >
                    <div className="rules-text" onClick={toggleCreateur}>
                      CRÉATEUR
                    </div>
                    <div
                      onClick={toggleCreateur}
                      className="icon-size"
                      data-tooltip-id="TooltipCreateur"
                      data-tooltip-content="Cliquez pour voir le créateur"
                    >
                      {isCreateurOpen ? <FiChevronDown /> : <FiChevronRight />}
                    </div>
                  </div>
                  {isCreateurOpen && <CreateurByProjetId />}

                  <div
                    className="d-flex justify-content-center align-items-center cursor-pointer"
                    onClick={togglePartagés}
                  >
                    <div className="rules-text" onClick={togglePartagés}>
                      PARTAGÉ
                    </div>
                    <div
                      onClick={togglePartagés}
                      className="icon-size"
                      data-tooltip-id="TooltipPersonnesPartage"
                      data-tooltip-content="Voir la liste des personnes ayant accès à ce projet"
                    />

                    {isPartagésOpen ? <FiChevronDown /> : <FiChevronRight />}
                  </div>
                  {isPartagésOpen && <UtilisateursByProjetId />}

                  <div
                    className="d-flex justify-content-center align-items-center cursor-pointer"
                    onClick={togglePrévisionnel}
                  >
                    <div className="rules-text" onClick={togglePrévisionnel}>
                      PRÉVISIONNELS
                    </div>
                  </div>
                  {isPrévisionnel && (
                    <ProjetPreviEcheancierById type="previsionnel" />
                  )}
                  <div
                    className="d-flex justify-content-center align-items-center cursor-pointer"
                    onClick={toggleEchéancier}
                  >
                    <div className="rules-text" onClick={toggleEchéancier}>
                      ÉCHÉANCIERS
                    </div>
                  </div>
                  {isEchéancier && (
                    <ProjetPreviEcheancierById type="echeancier" />
                  )}

                  <div
                    className="d-flex justify-content-center align-items-center cursor-pointer"
                    onClick={toggleDestinataires}
                  >
                    <div className="rules-text" onClick={toggleDestinataires}>
                      DESTINATAIRES
                    </div>
                    <div
                      onClick={toggleDestinataires}
                      className="icon-size"
                      data-tooltip-id="TooltipGestionnaires"
                      data-tooltip-content="Cliquez pour voir les Destinatairess"
                    >
                      {isDestinatairesOpen ? (
                        <FiChevronDown />
                      ) : (
                        <FiChevronRight />
                      )}
                    </div>
                  </div>
                  {isDestinatairesOpen && (
                    // On consièdère qu'une AGENCE et les PRESTATAIRES font partie des destinataires
                    <ListeDestinataires
                      typeTiers={[
                        "PARTENAIRE",
                        "AGENCE",
                        "PRESTATAIRE",
                        "FOURNISSEUR",
                      ]}
                    />
                  )}
                </>
              )}
            </div>
          </>
        )}

      {projetId &&
        (selectedMenu === "PROJETS PARTENAIRES" ||
          selectedMenu === "PROJETS PRESTATAIRES" ||
          selectedMenu === "PROJETS FOURNISSEURS" ||
          selectedMenu === "PROJET AGENCE") && (
          <>
            <div className="d-flex flex-column">
              <div
                className="d-flex justify-content-center align-items-center cursor-pointer"
                onClick={toggleCreateur}
              >
                <div className="rules-text" onClick={toggleCreateur}>
                  CRÉATEUR
                </div>
                <div
                  onClick={toggleCreateur}
                  className="icon-size"
                  data-tooltip-id="TooltipCreateur"
                  data-tooltip-content="Cliquez pour voir le créateur"
                >
                  {isCreateurOpen ? <FiChevronDown /> : <FiChevronRight />}
                </div>
              </div>
              {isCreateurOpen && <CreateurByProjetId />}

              <div
                className="d-flex justify-content-center align-items-center cursor-pointer"
                onClick={togglePartagés}
              >
                <div className="rules-text" onClick={togglePartagés}>
                  PARTAGÉ
                </div>
                <div
                  onClick={togglePartagés}
                  className="icon-size"
                  data-tooltip-id="TooltipPersonnesPartage"
                  data-tooltip-content="Voir la liste des personnes ayant accès à ce projet"
                >
                  {isPartagésOpen ? <FiChevronDown /> : <FiChevronRight />}
                </div>
              </div>
              {isPartagésOpen && <UtilisateursByProjetId />}
            </div>
          </>
        )}

      {tiersId &&
        (selectedMenu === "CLIENTS" || selectedMenu === "ALL CLIENTS") && (
          <>
            <div
              className="d-flex justify-content-center align-items-center cursor-pointer"
              onClick={toggleContacts}
            >
              <div className="rules-text" onClick={toggleContacts}>
                CONTACTS
              </div>

              <div
                onClick={toggleContacts}
                className="icon-size"
                data-tooltip-id={`TooltipContacts`}
                data-tooltip-content={"Cliquez pour la liste des Contacts"}
              >
                {isContactsOpen ? <FiChevronDown /> : <FiChevronRight />}
              </div>
            </div>

            <div className="d-flex flex-column">
              {isContactsOpen && (
                <div className="cadre-zone4">
                  {/* Liste des contacts rattachés au Partenaire */}
                  <ListeContactsByTiers typeFormat="LONG" />
                  {utilisateurId && (
                    <>
                      <hr />
                      <UtilisateurById />
                    </>
                  )}
                </div>
              )}

              {addNewUser && (
                <div className="cadre-zone4">
                  <CreerUtilisateur />
                </div>
              )}

              {/* Partagés avec qui? */}
              <div
                className="d-flex justify-content-center align-items-center cursor-pointer"
                onClick={togglePartages}
              >
                <div className="rules-text" onClick={togglePartages}>
                  PARTAGES
                </div>
                <div
                  onClick={togglePartages}
                  className="icon-size"
                  data-tooltip-id={`TooltipPartage`}
                  data-tooltip-content={"Partagé avec"}
                >
                  {isPartageOpen ? <FiChevronDown /> : <FiChevronRight />}
                </div>
              </div>

              {isPartageOpen && <UtilisateursByTiersId />}

              <div
                className="d-flex justify-content-center align-items-center cursor-pointer"
                onClick={toggleProjetsOuverts}
              >
                <div className="rules-text" onClick={toggleProjetsOuverts}>
                  PROJETS EN COURS
                </div>
                <CountListProjetsByUserId
                  etatProjet="ouvert"
                  cibleProjets="CLIENT"
                />
                <div
                  onClick={toggleProjetsOuverts}
                  className="icon-size"
                  data-tooltip-id={`TooltipProjets`}
                  data-tooltip-content={
                    "Cliquez pour la liste des Projets en Cours"
                  }
                >
                  {isProjetsOuverts ? <FiChevronDown /> : <FiChevronRight />}
                </div>
              </div>

              {isProjetsOuverts && (
                <ListeProjetsByTiersId
                  etatProjet="ouvert"
                  cibleProjets="CLIENT"
                />
              )}

              <div
                className="d-flex justify-content-center align-items-center cursor-pointer"
                onClick={toggleProjetsArchivés}
              >
                <div className="rules-text" onClick={toggleProjetsArchivés}>
                  PROJETS ARCHIVÉS
                </div>
                <CountListProjetsByUserId etatProjet="archivé"       cibleProjets="CLIENT"/>
                <div
                  onClick={toggleProjetsArchivés}
                  className="icon-size"
                  data-tooltip-id={`TooltipProjets`}
                  data-tooltip-content={
                    "Cliquez pour la liste des Projets Archivés"
                  }
                >
                  {isProjetsArchivés ? <FiChevronDown /> : <FiChevronRight />}
                </div>
              </div>

              {isProjetsArchivés && (
                <ListeProjetsByTiersId etatProjet="archivé" />
              )}
            </div>
          </>
        )}

      {selectedMenu === "SYNTHÈSES" && (
        <>
          <ListeProjetsSansAttentes AllAgences={false} />
          <CheckPreviEcheancierProjets AllAgences={false} />
          <SynthesesPreviEcheancier
            typeSynthese="PRÉVISIONNELS"
            AllAgences={false}
          />

          <SynthesesPreviEcheancier
            typeSynthese="ÉCHÉANCIERS"
            AllAgences={false}
          />
        </>
      )}

      {selectedMenu === "ALL SYNTHÈSES" && (
        <>
          {/* On affiche tous les projets qui sont sans attentes */}
          <ListeProjetsSansAttentes AllAgences={true} />
          <CheckPreviEcheancierProjets AllAgences={true} />
          <SynthesesPreviEcheancier
            typeSynthese="PRÉVISIONNELS"
            AllAgences={true}
          />
          <SynthesesPreviEcheancier
            typeSynthese="ÉCHÉANCIERS"
            AllAgences={true}
          />
        </>
      )}

      {tiersId &&
        (selectedMenu === "ADMIN AGENCES" ||
          selectedMenu === "ADMIN GÉNÉRAL") && (
          <div className="cadre-zone4 d-flex flex-column">
            <div
              className="d-flex justify-content-center align-items-center cursor-pointer"
              onClick={toggleContacts}
            >
              <div className="rules-text" onClick={toggleContacts}>
                UTILISATEURS <CountUtilisateursByTiers />
              </div>

              <div
                onClick={toggleContacts}
                className="icon-size"
                data-tooltip-id={`TooltipContacts`}
                data-tooltip-content={"Cliquez pour la liste des Contacts"}
              >
                {isContactsOpen ? <FiChevronDown /> : <FiChevronRight />}
              </div>
            </div>
            {isContactsOpen && (
              <>
                <ListeContactsByTiers typeFormat="COURT" />
                {utilisateurId && (
                  <>
                    <hr />
                    <UtilisateurById />
                  </>
                )}
              </>
            )}

            <div
              className="d-flex justify-content-center align-items-center cursor-pointer"
              onClick={togglePrestataires}
            >
              <div className="rules-text" onClick={toggleRègles}>
                PRESTATAIRES <CountTiersByTypeTiers typeTiers="PRESTATAIRE" />
              </div>

              <div
                onClick={togglePrestataires}
                className="icon-size"
                data-tooltip-id={`TooltipContacts`}
                data-tooltip-content={"Cliquez pour la liste des Contacts"}
              >
                {isPrestatairesOpen ? <FiChevronDown /> : <FiChevronRight />}
              </div>
            </div>

            {isPrestatairesOpen && (
              <ListeTiersByTypeTiersAndTiersId typeTiers="PRESTATAIRE" />
            )}

            <div
              className="d-flex justify-content-center align-items-center cursor-pointer"
              onClick={togglePartenaires}
            >
              <div className="rules-text" onClick={toggleRègles}>
                PARTENAIRES <CountTiersByTypeTiers typeTiers="PARTENAIRE" />
              </div>

              <div
                onClick={togglePartenaires}
                className="icon-size"
                data-tooltip-id={`TooltipContacts`}
                data-tooltip-content={"Cliquez pour la liste des Contacts"}
              >
                {isPartenairesOpen ? <FiChevronDown /> : <FiChevronRight />}
              </div>
            </div>

            {isPartenairesOpen && (
              <ListeTiersByTypeTiersAndTiersId typeTiers="PARTENAIRE" />
            )}

            <div
              className="d-flex justify-content-center align-items-center cursor-pointer"
              onClick={toggleClients}
            >
              <div className="rules-text" onClick={toggleRègles}>
                CLIENTS <CountTiersByTypeTiers typeTiers="CLIENT" />
              </div>
              <div
                onClick={toggleClients}
                className="icon-size"
                data-tooltip-id={`TooltipContacts`}
                data-tooltip-content={"Cliquez pour la liste des Contacts"}
              >
                {isClientsOpen ? <FiChevronDown /> : <FiChevronRight />}
              </div>
            </div>

            {isClientsOpen && (
              <ListeTiersByTypeTiersAndTiersId typeTiers="CLIENT" />
            )}

            <div
              className="d-flex justify-content-center align-items-center cursor-pointer"
              onClick={toggleProjetsOuverts}
            >
              <div className="rules-text" onClick={toggleProjetsOuverts}>
                PROCESS INTERNES
              </div>
              <div
                onClick={toggleProjetsOuverts}
                className="icon-size"
                data-tooltip-id={`TooltipContacts`}
                data-tooltip-content={
                  "Cliquez pour la liste des process internes"
                }
              >
                {isProjetsOuverts ? <FiChevronDown /> : <FiChevronRight />}
              </div>
            </div>
            {isProjetsOuverts && <ListeProjetsByTiersId etatProjet="ouvert" />}
          </div>
        )}

      {modeleId && selectedMenu === "ADMIN MODÈLES" && (
        <div className="d-flex flex-column">
          <div
            className="d-flex justify-content-center align-items-center cursor-pointer"
            onClick={toggleContacts}
          >
            <div className="rules-text" onClick={toggleRègles}>
              RÈGLES DU MODÈLE
            </div>
            <div
              onClick={toggleRègles}
              className="icon-size"
              data-tooltip-id={`TooltipContacts`}
              data-tooltip-content={
                "Cliquez pour la liste des règles de ce modèle"
              }
            >
              {isRèglesOpen ? <FiChevronDown /> : <FiChevronRight />}
            </div>
          </div>
{/* 
          {isRèglesOpen && (
            <div className="cadre-zone4">
              <ReglesModeles />
            </div>
          )} */}

          <div
            className="d-flex justify-content-center align-items-center cursor-pointer"
            onClick={toggleContacts}
          >
            <div className="rules-text" onClick={toggleListeRègles}>
              LISTE DES RÈGLES
            </div>
            <div
              onClick={toggleListeRègles}
              className="icon-size"
              data-tooltip-id={`TooltipContacts`}
              data-tooltip-content={
                "Cliquez pour la liste des règles de ce modèle"
              }
            >
              {isListeRèglesOpen ? <FiChevronDown /> : <FiChevronRight />}
            </div>
          </div>

          {isListeRèglesOpen && (
            <div className="cadre-zone4">
              <ListeRègles />
            </div>
          )}

          <div
            className="d-flex justify-content-center align-items-center cursor-pointer"
            onClick={toggleRépartitionPréviEchéanciers}
          >
            <div
              className="rules-text"
              onClick={toggleRépartitionPréviEchéanciers}
            >
              RÉPARTITION PRÉVISIONNELLE ÉCHÉANCIER
            </div>

            <div
              onClick={toggleRépartitionPréviEchéanciers}
              className="icon-size"
              data-tooltip-id={`TooltipContacts`}
              data-tooltip-content={
                "Cliquez pour la liste des règles de ce modèle"
              }
            >
              {isRépartitionPréviEchéancierOpen ? (
                <FiChevronDown />
              ) : (
                <FiChevronRight />
              )}
            </div>
          </div>

          {isRépartitionPréviEchéancierOpen && (
            <div className="cadre-zone4">
              <TableauPrevisionnelEtEcheancier />
            </div>
          )}

          <div
            className="d-flex justify-content-center align-items-center cursor-pointer"
            onClick={toggleActions}
          >
            <div className="rules-text" onClick={toggleActions}>
              LISTE DES ACTIONS
            </div>

            <div
              onClick={toggleActions}
              className="icon-size"
              data-tooltip-id={`TooltipContacts`}
              data-tooltip-content={
                "Cliquez pour la liste des règles de ce modèle"
              }
            >
              {isActionsOpen ? <FiChevronDown /> : <FiChevronRight />}
            </div>
          </div>

          {isActionsOpen && (
            <div className="cadre-zone4">
              {logger.log("GestionActions component is rendered")}
              <GestionActions type="ADMIN MODÉLES" />
            </div>
          )}
        </div>
      )}

      {tiersId &&
        (selectedMenu === "PARTENAIRES" ||
          selectedMenu === "ALL PARTENAIRES" ||
          selectedMenu === "ADMIN PARTENAIRES" ||
          selectedMenu === "ALL FOURNISSEURS" ||
          selectedMenu === "FOURNISSEURS") && (
          <>
            <div className="d-flex flex-column">
              <div
                className="d-flex justify-content-center align-items-center cursor-pointer"
                onClick={toggleContacts}
              >
                <div className="rules-text" onClick={toggleContacts}>
                  CORRESPONDANTS
                </div>

                <div
                  onClick={toggleContacts}
                  className="icon-size"
                  data-tooltip-id={`TooltipContacts`}
                  data-tooltip-content={
                    "Cliquez pour la liste des Correspondants"
                  }
                >
                  {isContactsOpen ? <FiChevronDown /> : <FiChevronRight />}
                </div>
              </div>

              {isContactsOpen && (
                <div className="cadre-zone4">
                  <ListeContactsByTiers typeFormat="COURT" />
                  {utilisateurId && (
                    <>
                      <hr />
                      <UtilisateurById />
                    </>
                  )}
                </div>
              )}

              <div
                className="d-flex justify-content-center align-items-center cursor-pointer"
                onClick={toggleCreateur}
              >
                <div className="rules-text" onClick={toggleCreateur}>
                  CRÉATEUR
                </div>
                <div
                  onClick={toggleCreateur}
                  className="icon-size"
                  data-tooltip-id="TooltipCreateur"
                  data-tooltip-content="Cliquez pour voir le créateur"
                >
                  {isCreateurOpen ? <FiChevronDown /> : <FiChevronRight />}
                </div>
              </div>
              {isCreateurOpen && <CreateurByTiersId />}

              {/* Partagés avec qui? */}
              <div
                className="d-flex justify-content-center align-items-center cursor-pointer"
                onClick={togglePartages}
              >
                <div className="rules-text" onClick={togglePartages}>
                  PARTAGES
                </div>
                <div
                  onClick={togglePartages}
                  className="icon-size"
                  data-tooltip-id={`TooltipPartage`}
                  data-tooltip-content={"Partagé avec"}
                >
                  {isPartageOpen ? <FiChevronDown /> : <FiChevronRight />}
                </div>
              </div>

              {isPartageOpen && <UtilisateursByTiersId />}

              <div
                className="d-flex justify-content-center align-items-center cursor-pointer"
                onClick={toggleCompétences}
              >
                <div className="rules-text" onClick={toggleCompétences}>
                  COMPÉTENCES
                </div>
                <div
                  onClick={toggleCompétences}
                  className="icon-size"
                  data-tooltip-id={`TooltipCompétences`}
                  data-tooltip-content={
                    "Cliquez pour sélectionner les Compétences"
                  }
                >
                  {isCompétencesOpen ? <FiChevronDown /> : <FiChevronRight />}
                </div>
              </div>
              {isCompétencesOpen && <ListeCompétences typeTiers="PARTENAIRE" />}

              <div
                className="d-flex justify-content-center align-items-center cursor-pointer"
                onClick={toggleProjetsOuverts}
              >
                <div className="rules-text" onClick={toggleProjetsOuverts}>
                  PROCESS INTERNES
                </div>
                <div
                  onClick={toggleProjetsOuverts}
                  className="icon-size"
                  data-tooltip-id={`TooltipContacts`}
                  data-tooltip-content={
                    "Cliquez pour la liste des process internes"
                  }
                >
                  {isProjetsOuverts ? <FiChevronDown /> : <FiChevronRight />}
                </div>
              </div>

              {isProjetsOuverts && (
                <ListeProjetsByTiersId etatProjet="ouvert" />
              )}

              <div
                className="d-flex justify-content-center align-items-center cursor-pointer"
                onClick={toggleProjets}
              >
                <div className="rules-text" onClick={toggleProjets}>
                  PROJETS EN COURS TIERS
                </div>
                <div
                  onClick={toggleProjets}
                  className="icon-size"
                  data-tooltip-id={`TooltipProjets`}
                  data-tooltip-content={
                    "Cliquez pour obtenir la liste des projets"
                  }
                >
                  {isProjetsOpen ? <FiChevronDown /> : <FiChevronRight />}
                </div>
              </div>

              {isProjetsOpen && <ListeProjets typeTiers="PARTENAIRE" />}
            </div>
          </>
        )}

      {tiersId && selectedMenu === "ALL PRESTATAIRES" && (
        <>
          <div className="d-flex flex-column">
            <div
              className="d-flex justify-content-center align-items-center cursor-pointer"
              onClick={toggleContacts}
            >
              <div className="rules-text" onClick={toggleContacts}>
                CONTACTS
              </div>

              <div
                onClick={toggleContacts}
                className="icon-size"
                data-tooltip-id={`TooltipContacts`}
                data-tooltip-content={"Cliquez pour la liste des Contacts"}
              >
                {isContactsOpen ? <FiChevronDown /> : <FiChevronRight />}
              </div>
            </div>
            {isContactsOpen && (
              <div className="cadre-zone4">
                {/* Liste des contacts rattachés au Partenaire */}
                <ListeContactsByTiers typeFormat="COURT" />

                {utilisateurId && (
                  <>
                    <hr />
                    <UtilisateurById />
                  </>
                )}
              </div>
            )}

            <div
              className="d-flex justify-content-center align-items-center cursor-pointer"
              onClick={toggleProjetsOuverts}
            >
              <div className="rules-text" onClick={toggleProjetsOuverts}>
                PROCESS INTERNES
              </div>
              <div
                onClick={toggleProjetsOuverts}
                className="icon-size"
                data-tooltip-id={`TooltipContacts`}
                data-tooltip-content={
                  "Cliquez pour la liste des process internes"
                }
              >
                {isProjetsOuverts ? <FiChevronDown /> : <FiChevronRight />}
              </div>
            </div>

            {isProjetsOuverts && <ListeProjetsByTiersId etatProjet="ouvert" />}
          </div>
        </>
      )}

      <Tooltip
        id="TooltipPartage"
        place="top"
        effect="solid"
        className="custom-tooltip"
      ></Tooltip>
      <Tooltip
        id="TooltipDescription"
        place="top"
        effect="solid"
        className="custom-tooltip"
      ></Tooltip>
      <Tooltip
        id="TooltipAdresseChantier"
        place="top"
        effect="solid"
        className="custom-tooltip"
      ></Tooltip>

      <Tooltip
        id="TooltipCreateur"
        place="top"
        effect="solid"
        className="custom-tooltip"
      ></Tooltip>
      <Tooltip
        id="TooltipPersonnesPartage"
        place="top"
        effect="solid"
        className="custom-tooltip"
      ></Tooltip>

      <Tooltip
        id="TooltipCompétences"
        place="top"
        effect="solid"
        className="custom-tooltip"
      ></Tooltip>
      <Tooltip
        id="TooltipContacts"
        place="top"
        effect="solid"
        className="custom-tooltip"
      ></Tooltip>
      <Tooltip
        id="TooltipProjets"
        place="top"
        effect="solid"
        className="custom-tooltip"
      ></Tooltip>
    </div>
  );

}
